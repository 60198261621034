import React, { useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { useTitle } from 'hooks/useTitle'
import { PAGES } from 'constants/pages'
import { AuthSource, sendAuthCodeHiring } from 'api/hiring'
import { SettingsSteps } from 'constants/settingsSteps'
import Tabs from 'components/settings/StepperControl'
import GeneralTab from 'components/settings/Tabs/General'
import HardwaresTab from 'components/settings/Tabs/HardwareTypes'
import HiringStepsTab from 'components/settings/Tabs/HiringSteps'
import DocTemplatesTab from 'components/settings/Tabs/DocTemplates'
import ContactsTab from 'components/settings/Tabs/Contacts'
import IntegrationsTab from 'components/settings/Tabs/Integrations'
import BenefitTab from 'components/settings/Tabs/BenefitTypes'
import Body from 'components/ui/layout/Body'
import { useSearchParams } from 'react-router-dom'
import { setToastWarning } from 'utils/handlerError'
import LetterTemplateTab from './Tabs/LetterTemplate'
import HiringTypesTab from 'components/settings/Tabs/HiringTypes'
import Departments from './Tabs/Department'
import Positions from './Tabs/Positions'
import Grades from './Tabs/Grade'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

const DEFAULT_TAB = SettingsSteps.GENERAL

const tabIsSettingStep = (tab: number): tab is SettingsSteps => {
  return tab in SettingsSteps
}

const Settings = () => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS)
  useTitle(t('settings:title'))
  const [searchParams, setSearchParams] = useSearchParams()

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const tabFromParams = useMemo<SettingsSteps>(() => {
    const tab = searchParams.get('tab')

    const isTab = tab ? tabIsSettingStep(+tab) : false

    return isTab ? +tab! : SettingsSteps.GENERAL
  }, [searchParams])

  const [step, setStep] = useState<SettingsSteps>(tabFromParams ?? DEFAULT_TAB)

  useEffect(() => {
    const code = searchParams.get('code')
    const scope = searchParams.get('scope')
    const state = searchParams.get('state')

    const referrer = document.referrer
    const authSource = referrer.includes('hh')
      ? AuthSource.HH
      : referrer.includes('avito')
      ? AuthSource.AVITO
      : referrer.includes('habr')
      ? AuthSource.HABR
      : scope?.includes('googleapis')
      ? AuthSource.GOOGLE
      : null

    if (code && authSource) {
      if (
        (authSource === AuthSource.AVITO || authSource === AuthSource.HH) &&
        state
      ) {
        void sendAuthCodeHiring({
          authorizationCode: code,
          service: authSource,
          state,
        })
      } else {
        void sendAuthCodeHiring({
          authorizationCode: code,
          service: authSource,
        })
      }
    } else if (code) {
      setToastWarning(t('source_error'))
    }
  }, [searchParams])

  const formContent = () => {
    const tabProps = {
      isEditing,
      setIsEditing,
      step,
    }

    switch (step) {
      case SettingsSteps.GENERAL:
      default:
        return <GeneralTab {...tabProps} />
      case SettingsSteps.CONTACTS:
        return <ContactsTab {...tabProps} />
      case SettingsSteps.DOCUMENT_PATTERNS:
        return <DocTemplatesTab {...tabProps} />
      case SettingsSteps.HIRING_STAGES:
        return <HiringStepsTab {...tabProps} />
      case SettingsSteps.HARDWARE_TYPES:
        return <HardwaresTab {...tabProps} />
      case SettingsSteps.INTEGRATIONS:
        return <IntegrationsTab {...tabProps} />
      case SettingsSteps.BENEFIT_TYPES:
        return <BenefitTab {...tabProps} />
      case SettingsSteps.LETTER_TEMPLATES:
        return <LetterTemplateTab />
      case SettingsSteps.HIRING_TYPES:
        return <HiringTypesTab {...tabProps} />
      case SettingsSteps.DEPARTMENT_TYPES:
        return <Departments />
      case SettingsSteps.POSITION_TYPES:
        return <Positions />
      case SettingsSteps.GRADES_TYPES:
        return <Grades />
    }
  }

  return (
    <Body>
      <ST.DepartmentsHead>
        <ST.DepartmentsTitle>{t('title')}</ST.DepartmentsTitle>
      </ST.DepartmentsHead>

      <ST.VacancyBody>
        <Tabs
          step={step}
          setStep={setStep}
          setIsEditing={setIsEditing}
          setSearchParams={setSearchParams}
        />
        <ST.TabInfo>{formContent()}</ST.TabInfo>
      </ST.VacancyBody>
    </Body>
  )
}

export default Settings
