import React, { useCallback, useMemo, useState } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ButtonTypes } from 'constants/buttonTypes'
import { handlerError } from 'utils/handlerError'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import {
  ISetting,
  ISettingSubsection,
  SettingsKeys,
  SettingsSections,
  SettingsSubsections,
} from 'types/model/settings'
import { createOrUpdateSettings, deleteSetting } from 'api/settings'
import { getSettingByKey } from 'utils/parseData'
import { IFileItem } from 'components/ui/inputs/AttachFile'
import BaseInput from 'components/ui/inputs/BaseInput'
import { Social } from 'components/settings/Tabs/Contacts/EditingContactsTabContent/Social'
import BackButton from 'components/ui/buttons/BackButton'
import { TPostFile } from 'types/employmentType'
import { postFile } from 'api/employment'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { MessagesNS, SettingsLocales } from 'i18n/types'

interface IEditingGeneralTabContent {
  handleIsEditing: () => void
  data: ISettingSubsection[]
  onEnd: SetState<boolean>
}

const generateEmptySettings = (
  section: SettingsSections,
  subsection: SettingsSubsections,
  index: number
): Omit<FormSetting, 'id' | 'user'> => ({
  name: `Name_${Date.now()}`,
  value: '',
  description: '',
  section: section,
  subsection: subsection,
  img: null,
  title: `Title_${Date.now()}`,
  isDeleted: false,
  isNew: true,
  file: null,
  index,
})

type CustomPartial<T> = {
  [Key in keyof T]-?: T[Key] | undefined
}

export type FormSetting = ISetting & {
  isDeleted: boolean
  isNew?: boolean
  index: number
  file?: File | null
}

export type ISettingsContactsForm = CustomPartial<{
  address: string
  site_url: string
  phone: string
  email: string
  socials: FormSetting[]
  links: FormSetting[]
}>

export enum EditingGeneralTabContentStrings {
  email = 'email',
  dateBorn = 'dateBorn',
  radio = 'radio',
  sex = 'sex',
  none = 'none',
  dateFormat = '##.##.####',
  phoneNumber = 'phoneNumber',
  surname = 'surname',
  name = 'name',
  patronymic = 'patronymic',
  date = 'date',
  department = 'department',
  post = 'post',
  grade = 'grade',
  file = 'file',
  stack = 'stack',
  host = 'host',
  calendar_host = 'calendar_host',
  calendar_mail_sender = 'calendar_mail_sender',
  mail_sender = 'mail_sender',
  port = 'port',
  calendar_port = 'calendar_port',
  calendar_login = 'calendar_login',
  calendar_password = 'calendar_password',
  login = 'login',
  password = 'password',
  pattern_tech_survey = 'pattern_tech_survey',
  pattern_common_survey = 'pattern_common_survey',
  pattern_survey_360 = 'pattern_survey_360',
  review_chat_id = 'review_chat_id',
  admin_chat_id = 'admin_chat_id',
  access_chat_id = 'access_chat_id',
  bot_token = 'bot_token',
  hh_apikey = 'hh_apikey',
  nickname = 'nickname',
  calendar_name = 'calendar_name',
  review_name = 'review_name',
  api_hh_name = 'api_hh_name',
  mail_name = 'mail_name',
  bot_vk_name = 'bot_vk_name',
  client_id = 'client_id',
  redirect_uri = 'redirect_uri',
  hh_secret = 'hh_secret',
  hh_client_id = 'hh_client_id',

  //Avito
  avito_name = 'avito_name',
  avito_client_id = 'avito_client_id',
  avito_redirect_uri = 'avito_redirect_uri',
  avito_secret = 'avito_secret',
  avito_auth_link = 'avito_auth_link',
  avito_status = 'avito_status',

  // Telegram
  telegram_name = 'bot_telegram_name',
  bot_telegram_token = 'bot_telegram_token',
  telegram_review_chat_id = 'telegram_review_chat_id',
  telegram_admin_chat_id = 'telegram_admin_chat_id',
  telegram_bot_nick = 'telegram_bot_nick',
  telegram_access_chat_id = 'telegram_access_chat_id',
}

const EditingContactsTabContent = ({
  handleIsEditing,
  data,
  onEnd,
}: IEditingGeneralTabContent) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_CONTACTS,
    I18nNamespaces.TRANSLATION,
    I18nNamespaces.MESSAGES
  )
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const formTranslate = t<SettingsLocales.Contacts['form']>('form')
  const RequiredFields = t<MessagesNS['required_fields']>(
    'info_messages:required_fields'
  )

  const ISettingsValidationSchema = useMemo(
    () =>
      Yup.array().of(
        Yup.object().shape({
          id: Yup.number(),
          name: Yup.string().required(RequiredFields.base),
          value: Yup.string().required(RequiredFields.base),
          section: Yup.number().required(RequiredFields.base),
          subsection: Yup.number().required(RequiredFields.base),
          title: Yup.string().required(RequiredFields.base),
        })
      ),
    [RequiredFields.base]
  )

  const getSettingsFromData = useCallback(
    (subsection: SettingsSubsections) =>
      data.find((s) => s.subsection === subsection)?.settings,
    [data]
  )

  const generalSettings = getSettingsFromData(SettingsSubsections.GENERAL)

  const socialsSettings = getSettingsFromData(
    SettingsSubsections.SOCIAL_NETWORKS_AND_MESSENGERS
  )

  const linksSettings = getSettingsFromData(
    SettingsSubsections.PORTFOLIO_AND_OTHER
  )

  const onSubmit = async (values: ISettingsContactsForm) => {
    const filteredSettings =
      generalSettings?.filter((setting) =>
        [
          SettingsKeys.ADDRESS,
          SettingsKeys.SITE_URL,
          SettingsKeys.PHONE,
          SettingsKeys.EMAIL,
        ].includes(setting.name as SettingsKeys)
      ) ?? []

    const settingsPromises = filteredSettings.map((setting) => {
      switch (setting.name) {
        case SettingsKeys.ADDRESS:
          return { ...setting, value: values.address! }
        case SettingsKeys.SITE_URL:
          return { ...setting, value: values.site_url! }
        case SettingsKeys.PHONE:
          return { ...setting, value: values.phone! }
        case SettingsKeys.EMAIL:
          return { ...setting, value: values.email! }
        default:
          return setting
      }
    })

    const prepareSettingsArray = (
      key: 'socials' | 'links',
      deleted = false
    ): ISetting[] | undefined =>
      values[key]
        ?.filter((s) => (deleted ? s.isDeleted : !s.isDeleted))
        ?.map(({ isNew, isDeleted, ...s }) => s)

    const linksToDelete = [
      ...(prepareSettingsArray('socials', true) ?? []),
      ...(prepareSettingsArray('links', true) ?? []),
    ]

    const settingsArray: ISetting[] = [
      ...(prepareSettingsArray('socials') ?? []),
      ...(prepareSettingsArray('links') ?? []),
      ...(settingsPromises ?? []),
    ]

    const filesPromises = [
      ...(values.socials ?? []),
      ...(values.links ?? []),
    ].map((f) =>
      f.file
        ? postFile(f.file, f.file.name)
        : new Promise<null>((resolve) => {
            resolve(null)
          })
    )

    if (settingsArray.length) {
      Promise.allSettled<TPostFile | null>(filesPromises).then((files) => {
        const settingsArrayDto: ISetting[] = settingsArray.map((s, i) => {
          const f: PromiseSettledResult<TPostFile | null> = files[i]
          const resultFileValue = f?.status === 'fulfilled' ? f?.value : null

          return resultFileValue?.payload
            ? {
                ...s,
                img: resultFileValue.payload,
              }
            : s
        })

        createOrUpdateSettings(settingsArrayDto)
          .then(() => {
            Promise.allSettled(
              linksToDelete.map((link) => deleteSetting(link.id))
            )
              .then((res) => {
                const errs = res.filter(
                  (r) => r.status === 'rejected'
                ) as PromiseRejectedResult[]

                if (errs.length) {
                  errs.forEach((err) => handlerError(err.reason))
                } else {
                  onEnd?.(true)
                  handleIsEditing()
                }
              })
              .catch(handlerError)
          })
          .catch(handlerError)
      })
    }
  }

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    setFieldValue,
    isValid,
    handleBlur,
  } = useFormik<ISettingsContactsForm>({
    initialValues: {
      address: getSettingByKey(generalSettings, SettingsKeys.ADDRESS)?.value,
      site_url: getSettingByKey(generalSettings, SettingsKeys.SITE_URL)?.value,
      phone: getSettingByKey(generalSettings, SettingsKeys.PHONE)?.value,
      email: getSettingByKey(generalSettings, SettingsKeys.EMAIL)?.value,
      socials:
        socialsSettings?.map((s, index) => ({
          ...s,
          isDeleted: false,
          isNew: false,
          file: null,
          index,
        })) ?? [],
      links:
        linksSettings?.map((s, index) => ({
          ...s,
          isDeleted: false,
          isNew: false,
          file: null,
          index,
        })) ?? [],
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      address: Yup.string().trim().required(RequiredFields.base),
      site_url: Yup.string().trim().required(RequiredFields.base),
      phone: Yup.string().trim().required(RequiredFields.base),
      email: Yup.string().trim().required(RequiredFields.base),
      links: ISettingsValidationSchema,
      socials: ISettingsValidationSchema,
    }),
  })

  const onDocChange = (
    details: IFileItem | null,
    field: 'socials' | 'links',
    file?: File
  ) => {
    if (!!details?.id) {
      let vals = values[field]

      if (!vals) vals = []

      const st = [...vals]
      st[currentIndex].img = {
        id: details?.id,
        path: details.name ?? '',
        url: '',
      }

      setFieldValue(field, st)
    }

    if (file) {
      setFieldValue(
        field,
        values[field]?.length
          ? values[field]?.map((v, i) =>
              i === currentIndex ? { ...v, file } : v
            )
          : [file]
      )
    }
  }

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const handleDelete = (
    key: 'socials' | 'links',
    value: FormSetting,
    index: number
  ) => {
    const v = values[key]
    if (!v) return

    if (value.isNew) {
      setFieldValue(key, [...v.filter((e, i) => i !== index)])
    } else {
      setFieldValue(
        key,
        v.map((s) =>
          s.id === value.id
            ? {
                ...s,
                isDeleted: true,
              }
            : s
        )
      )
    }
  }

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton
            onClick={() => {
              handleIsEditing()
            }}
          />

          <ST.EditingHeader>{t('translation:editing')}</ST.EditingHeader>
        </ST.BackBlock>
        <ST.SaveButton
          type={ButtonTypes.submit}
          onClick={checkForm}
          disabled={!isValid}
        >
          {t('translation:save')}
        </ST.SaveButton>
        {!isValid && <ErrorMessage message={RequiredFields.base2} />}
      </ST.ManageBlock>
      <ST.FullName>
        <ST.HeaderBlock>{formTranslate.general}</ST.HeaderBlock>
        <ST.BottomWrapper>
          <BaseInput
            value={values.address ?? ''}
            label={t('address')}
            placeholder={formTranslate.address.placeholder}
            id="address"
            name="address"
            onChange={handleChange}
          />
          <BaseInput
            value={values.site_url ?? ''}
            label={t('site')}
            placeholder={formTranslate.website.placeholder}
            id="site_url"
            name="site_url"
            onChange={handleChange}
          />
        </ST.BottomWrapper>
        <ST.BottomWrapper>
          <ST.InputWrapper>
            <BaseInput
              type="number"
              value={values.phone ?? ''}
              label={t('phone')}
              format="+7 (###) ###-##-##"
              customInput={ST.Input}
              placeholder="+7 (___) ___-__-__"
              id="phone"
              name="phone"
              onChange={handleChange}
            />
          </ST.InputWrapper>
          <BaseInput
            value={values.email ?? ''}
            label={formTranslate.email.label}
            placeholder={formTranslate.email.placeholder}
            id="email"
            name="email"
            onChange={handleChange}
          />
        </ST.BottomWrapper>
      </ST.FullName>
      <ST.FullName>
        <ST.SocBlock>{t('socials')}</ST.SocBlock>
        <ST.InputWrapper>
          {values.socials?.map(
            (social, index, array) =>
              !social.isDeleted && (
                <Social
                  key={social.id}
                  value={social}
                  index={index}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  onDocChange={onDocChange}
                  setCurrentIndex={setCurrentIndex}
                  type="socials"
                  handleDelete={handleDelete}
                  deleteDisabled={
                    array.filter((v) => !v.isDeleted).length === 1
                  }
                />
              )
          )}
          <ST.Button
            onClick={() => {
              const newSetting = generateEmptySettings(
                SettingsSections.CONTACTS,
                SettingsSubsections.SOCIAL_NETWORKS_AND_MESSENGERS,
                values.socials?.length ?? 0
              )

              setFieldValue(
                'socials',
                values.socials ? [...values.socials, newSetting] : [newSetting]
              )
            }}
          >
            <ST.Plus />
            <ST.LinkButton>{formTranslate.add_link}</ST.LinkButton>
          </ST.Button>
        </ST.InputWrapper>
      </ST.FullName>
      <ST.FullName>
        <ST.SocBlock>{t('portfolio')}</ST.SocBlock>
        <ST.InputWrapper>
          {values.links?.map(
            (link, index, array) =>
              !link.isDeleted && (
                <Social
                  key={link.id}
                  value={link}
                  index={index}
                  handleBlur={handleBlur}
                  values={values}
                  setFieldValue={setFieldValue}
                  onDocChange={onDocChange}
                  setCurrentIndex={setCurrentIndex}
                  type="links"
                  handleDelete={handleDelete}
                  deleteDisabled={array.filter((v) => !v.isDeleted).length <= 1}
                />
              )
          )}
          <ST.Button
            onClick={() => {
              const newSetting = generateEmptySettings(
                SettingsSections.CONTACTS,
                SettingsSubsections.PORTFOLIO_AND_OTHER,
                values.links?.length ?? 0
              )
              setFieldValue(
                'links',
                values.links ? [...values.links, newSetting] : [newSetting]
              )
            }}
          >
            <ST.Plus />
            <ST.LinkButton>{formTranslate.add_link}</ST.LinkButton>
          </ST.Button>
        </ST.InputWrapper>
      </ST.FullName>
    </>
  )
}

export default EditingContactsTabContent
