import { baseApi } from 'store/api/baseApi'
import { SystemMode } from 'types/SystemType'
import { SupportedLocales } from 'i18n/config'

interface ServiceMode {
  logo: string | null
  systemMode: SystemMode | null
  systemName: string | null
  systemLocale: SupportedLocales | null
  userLocale: SupportedLocales | null
}

type SystemModeResponse = {
  success: string | null
}

export const serviceModeApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Получение статуса сервисного режима
    getServiceModeStatus: builder.query<ServiceMode, void>({
      query: () => 'setting/service',
      transformResponse: (response: ServiceMode) => {
        if (response.systemMode)
          localStorage.setItem('service', response.systemMode)

        return response
      },
      providesTags: ['Setting', 'ServiceMode'],
    }),

    // Установка статуса сервисного режима
    setServiceModeStatus: builder.mutation<ServiceMode, boolean>({
      query: (isServiceMode) => {
        const params = new URLSearchParams()
        params.append('isServiceMode', String(isServiceMode))

        return {
          url: `setting/setServiceMode?${params.toString()}`,
          method: 'PATCH',
        }
      },
      invalidatesTags: ['Setting', 'ServiceMode'],
    }),

    setSystemMode: builder.mutation<SystemModeResponse, SystemMode>({
      query: (systemMode) => {
        const params = new URLSearchParams()
        params.append('systemMode', systemMode)

        return {
          url: 'setting/setSystemMode',
          method: 'PATCH',
          params,
        }
      },
      invalidatesTags: ['Setting', 'ServiceMode'],
    }),
  }),
})

export const {
  useGetServiceModeStatusQuery,
  useSetSystemModeMutation,
  /**
   * @deprecated
   * @see {useSetSystemModeMutation}
   * */
  useSetServiceModeStatusMutation,
} = serviceModeApi
