import React, { memo, MouseEventHandler } from 'react'
import { IconButton as ST } from './styled'
import { ReactComponent as CheckedIcon } from 'assets/icons/Check.svg'

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  id?: string
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string }
  >
  disabled?: boolean
  noFill?: boolean
  onClick?: MouseEventHandler
  typeButton?: 'primary' | 'secondary'
  checked?: boolean
}

const IconButton: FC<IconButtonProps> = ({
  icon: Icon,
  typeButton = 'primary',
  checked,
  ...props
}) => (
  <ST.Button {...props} type="button" typeButton={typeButton}>
    <Icon />
    {checked && <CheckedIcon />}
  </ST.Button>
)

export const IconButtonMemoized = memo(IconButton)

export default IconButton
