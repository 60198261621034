import { I18nNamespaces } from 'i18n/config'
import { ErrorMessagesNS } from 'i18n/types'
import { useGetResourceBundle } from 'i18n/hooks/useGetResourceBundle'

type Response = {
  ErrorMessages: ErrorMessagesNS
}

export const useErrorMessages = (): Response => {
  const ErrorMessages = useGetResourceBundle(I18nNamespaces.ERROR_MESSAGES)

  return {
    ErrorMessages,
  }
}
