import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { handlerError, setToastSuccess } from 'utils/handlerError'
import * as Yup from 'yup'
import { FormikFields } from 'constants/formikFields'
import { RequiredFields } from 'constants/requiredFields'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { rejectBenefitApplication } from 'api/benefitsApplications'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import IconButton from 'components/ui/buttons/IconButton'
import BaseButton from 'components/ui/buttons/BaseButton'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { BenefitsNS } from 'i18n/types'
import BaseTextarea from 'components/ui/inputs/BaseTextarea'

interface IDeniedBenefitApplication {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
  benefitApplication: BenefitApplicationTypes.Model
}

const DeniedBenefitApplication = ({
  show,
  onClose,
  benefitApplication,
}: IDeniedBenefitApplication) => {
  const { t } = useTypedTranslation(I18nNamespaces.BENEFITS)
  const handleClose = (): void => {
    onClose(!show)
  }

  const translate = t<BenefitsNS['reject_modal']>('reject_modal')
  const operationTypes = t<BenefitsNS['operationTypes']>('operationTypes')

  const { handleChange, values, resetForm, handleSubmit, errors, isValid } =
    useFormik({
      initialValues: {
        commentary: '',
      },
      onSubmit: () => {
        rejectBenefitApplication(benefitApplication.id, values)
          .then(() => {
            resetForm()
            handleClose()
          })
          .catch(() => handlerError(errors))
      },
      validationSchema: Yup.object().shape({
        commentary: Yup.string().required(RequiredFields.base2),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>
            {benefitApplication.benefit.operationType &&
              t<BenefitsNS['reject_modal']>('reject_modal', {
                operationType:
                  operationTypes[benefitApplication.benefit.operationType],
              }).title}
          </ST.ModalTitle>
          <ST.Container>
            <BaseTextarea
              placeholder={translate.commentary.placeholder}
              id={FormikFields.commentary}
              name={FormikFields.commentary}
              value={values.commentary}
              onChange={handleChange}
              label={translate.commentary.label}
              rows={4}
              required
            />

            {benefitApplication.links && !!benefitApplication.links.length && (
              <ST.LinksWrapper>
                <ST.LabelLink>{translate.links}</ST.LabelLink>
                {benefitApplication.links.map((link, index) => (
                  <ST.ContainerLink key={index}>
                    <ST.Links maxWidth={500} href={link} target={'_blank'}>
                      {link}
                    </ST.Links>
                    <ST.ContainerIcon>
                      <IconButton
                        style={{ width: '30px', height: '30px' }}
                        onClick={() => {
                          navigator.clipboard.writeText(link).then(() => {
                            setToastSuccess(translate.link_copied)
                          })
                        }}
                        icon={CopyIcon}
                        noFill
                      />
                    </ST.ContainerIcon>
                  </ST.ContainerLink>
                ))}
              </ST.LinksWrapper>
            )}
          </ST.Container>

          <BaseButton
            text={translate.submit}
            onClick={checkForm}
            disabled={!isValid || !values.commentary.length}
          />
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeniedBenefitApplication
