import React from 'react'
import * as ST from './styled'

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
  fitContent?: boolean
}

const DashedButton: FC<Props> = ({
  onClick,
  children,
  fitContent = false,
  ...props
}) => {
  return (
    <ST.Button fitContent={fitContent} onClick={onClick} {...props}>
      {children}
    </ST.Button>
  )
}

export default DashedButton
