import React, { memo, useMemo } from 'react'
import * as ST from 'components/settings/Tabs/Contacts/EditingContactsTabContent/styled'
import BaseInput from 'components/ui/inputs/BaseInput'
import { SocialNetworksUrlPlaceholders } from 'constants/socialNetworks'
import AttachFile, { IFileItem } from 'components/ui/inputs/AttachFile'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete.svg'
import { Placeholders } from 'constants/placeholders'
import { FileTypes } from 'constants/fileTypes'
import IconButton from 'components/ui/buttons/IconButton'
import {
  FormSetting,
  ISettingsContactsForm,
} from 'components/settings/Tabs/Contacts/EditingContactsTabContent/index'
import { FormikProps } from 'formik'
import getFileData from 'utils/file/getFileData'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { SettingsLocales } from 'i18n/types'

type Formik = FormikProps<ISettingsContactsForm>

type Props = {
  value: FormSetting
  deleteDisabled?: boolean
  index: number
  handleBlur: Formik['handleBlur']
  values: Formik['values']
  setFieldValue: Formik['setFieldValue']
  onDocChange: (
    details: IFileItem | null,
    field: 'socials' | 'links',
    file?: File
  ) => void
  setCurrentIndex: SetState<number>
  type: 'socials' | 'links'
  handleDelete: (
    key: 'socials' | 'links',
    value: FormSetting,
    index: number
  ) => void
}

export const Social: FC<Props> = ({
  value,
  handleBlur,
  index,
  values,
  setFieldValue,
  onDocChange,
  setCurrentIndex,
  type,
  handleDelete,
  deleteDisabled,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_CONTACTS)

  const formTranslate = t<SettingsLocales.Contacts['form']>('form')

  const imagePreview = useMemo(
    () => value?.file ?? getFileData(value.img) ?? null,
    [value?.file, value.img]
  )

  return (
    <ST.Social key={value.id}>
      <ST.SocWrapper>
        <ST.SocInputs>
          <BaseInput
            onBlur={handleBlur}
            containerStyle={{ maxWidth: '300px', width: 'auto' }}
            value={value.value}
            label={formTranslate.service_url}
            placeholder={SocialNetworksUrlPlaceholders.FACEBOOK2}
            id={`${type}[${index}].systemName`}
            name={`${type}[${index}].systemName`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const socials = [...values[type]!]

              socials[index].value = e.target.value
              setFieldValue('type', socials)
            }}
          />
          <ST.FileLabel onClick={() => setCurrentIndex(index)}>
            <AttachFile
              hideOnFileLoaded={!!value.img}
              label={formTranslate.load_icon}
              accept={FileTypes.images}
              files={
                value.img
                  ? [{ id: value.img.id, name: getFileData(value.img) }]
                  : imagePreview
                  ? [
                      {
                        id: 0,
                        name:
                          typeof imagePreview === 'string'
                            ? imagePreview
                            : imagePreview.name,
                      },
                    ]
                  : []
              }
              imagePreview={imagePreview ?? undefined}
              onChange={(val, f) => onDocChange(val, type, f)}
              onRemove={() => {
                setFieldValue(`${type}[${index}]`, {
                  ...value,
                  img: null,
                })
              }}
            />
          </ST.FileLabel>
        </ST.SocInputs>
        <IconButton
          icon={DeleteIcon}
          onClick={() => handleDelete(type, value, index)}
          disabled={deleteDisabled}
        />
      </ST.SocWrapper>
    </ST.Social>
  )
}

export const SocialMemoized = memo(Social)
