import Modal from 'components/ui/overlay/Modal'
import { useState } from 'react'
import { Tags } from 'styles/Tags'
import { COLORS } from 'constants/colors'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => Promise<void>
  description: string
  title: string
  isArchived: boolean
}

const ArchiveModal: FC<Props> = ({
  open,
  onClose,
  isArchived,
  onSubmit,
  description,
  title,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_BENEFIT)
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false)

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      title={t('archive_modal_title', {
        entityName: title,
      })}
      submitButtonText={t(isArchived ? 'submit_unarchive' : 'submit_archive')}
      onConfirm={() => {
        setSubmitDisabled(true)

        onSubmit()
          .then(onClose)
          .finally(() => {
            setSubmitDisabled(false)
          })
      }}
      disableSubmit={submitDisabled}
    >
      <Tags.P2 style={{ color: COLORS.black70 }}>
        {t(isArchived ? 'description_unarchive' : 'description_archive', {
          description,
        })}
      </Tags.P2>
    </Modal>
  )
}

export default ArchiveModal
