import { apiRequest, apiRequestForFile } from './request'

export const apiInstance = apiRequest()

export const apiInstanceKeepAlive = apiRequest({ keepAlive: true })

export const apiInstanceForFile = apiRequestForFile()

export const apiInstanceForFileJSON = apiRequestForFile('json')

export const apiInstances = [
  apiInstance,
  apiInstanceKeepAlive,
  apiInstanceForFile,
  apiInstanceForFileJSON,
]
