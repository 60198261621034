import React, {
  ElementRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { ReactComponent as Plus } from 'assets/icons/plusAddButton.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError, setToastSuccess } from 'utils/handlerError'
import { RequiredFields } from 'constants/requiredFields'
import { FormikFields } from 'constants/formikFields'
import { InputSizes } from 'constants/inputSizes'
import { Placeholders } from 'constants/placeholders'
import { ButtonTypes } from 'constants/buttonTypes'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { EditingGeneralTabContentStrings } from 'components/profile/Tabs/General/EditingGeneralTabContent'
import { PositionTypes } from 'types/model/position'
import { ReviewStatuses, ReviewTypes } from 'types/model/review'
import { requestFinishReview } from 'api/review'
import CalendarInput from 'components/ui/inputs/Calendar'
import moment from 'moment/moment'
import {
  TaskStatusEnum,
  TaskTypeEnum,
} from 'components/dialogs/Reviews/AddReview'
import { UserTypes } from 'types/model/user'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete.svg'
import BaseCheckbox from 'components/ui/checkboxes/BaseCheckbox'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { DATE_FORMAT_DTO } from 'constants/Date'
import { useGetGradesByPositionIdQuery } from 'store/api/grade'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { ProfileNS, ReviewsNS } from 'i18n/types'

enum SalaryEnum {
  checkbox = 'checkbox',
}

interface IRequestAccess {
  show: boolean
  onClose: SetState<boolean>
  updateReviews?: (userId: number) => void
  userId: number
  roles?: string[]
  usersInfo?: Nullable<UserTypes.UserInfo | ReviewTypes.User>
  review: ReviewTypes.Model
  positions?: PositionTypes.Grade[]
}

const DEFAULT_TASK_VALUE = {
  id: 0,
  choice: true,
}

const isUserInfo = (
  data: Nullable<UserTypes.UserInfo | ReviewTypes.User>
): data is UserTypes.UserInfo => (data ? 'grade' in data : false)

const FinishReview = ({
  show,
  onClose,
  usersInfo,
  review,
  updateReviews,
  userId,
}: IRequestAccess) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.REVIEWS,
    I18nNamespaces.PROFILE
  )
  const [activeSelect, setActiveSelect] = useState<string | null>(null)
  const [activeSalaryType, setActiveSalaryType] = useState<boolean>(
    review?.user?.salaryType === 2
  )
  const [activeReviewType, setActiveReviewType] = useState<string>('')
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tasks, setTasks] = useState(
    review.tasks.map((task) => ({ id: task.id, choice: false }))
  )
  const [selectedGrade, setSelectedGrade] = useState<number>(
    isUserInfo(usersInfo ?? null)
      ? (usersInfo as UserTypes.UserInfo).grade?.id ?? 0
      : review.grade?.id ?? 0
  )
  const [date, setDate] = useState<Date>(
    review ? new Date(review.date) : new Date(Date.now())
  )

  const modalRef = useRef<ElementRef<'div'>>(null)

  const handleCheckboxChange = (taskId: number) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, choice: !task.choice } : task
      )
    )
  }

  const { data: positionGrades } = useGetGradesByPositionIdQuery(
    usersInfo?.position?.id!,
    { skip: !usersInfo?.position?.id || !show, refetchOnMountOrArgChange: true }
  )

  const gradesItems: IItem[] = useMemo(
    () =>
      positionGrades?.map((grade) => ({
        value: grade.id,
        item: grade.name,
      })) ?? [],
    [positionGrades]
  )

  useEffect(() => {
    const types = t<ProfileNS['reviews']>('profile:reviews')?.types

    setActiveReviewType(types[review.type])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [review.id])

  const translateCompleteModal = t<ReviewsNS['finish_modal']>(
    'reviews:finish_modal'
  )
  const translateModal = t<ReviewsNS['modal']>('reviews:modal')

  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    isValid,
    setFieldValue,
    ...formik
  } = useFormik({
    validateOnMount: false,
    initialValues: {
      date: review.date,
      task: [] as string[],
      tasks: [DEFAULT_TASK_VALUE],
      grade: 0,
      salary: review.salary ?? 0,
      salaryHand: review.salaryHand ?? null,
      comment: '',
      salaryType: 1,
    },
    onSubmit: (vals) => {
      setBtnDisabled(true)
      setIsLoading(true)

      vals.salary !== null &&
        requestFinishReview(review.id, {
          date: moment(date).format(DATE_FORMAT_DTO),
          tasks: tasks.map((task) => ({ id: task.id, choice: task.choice })),
          nextReviewTasks: vals.task.map((el) => ({
            type: TaskTypeEnum.NEXT_REVIEW,
            text: el,
            status: TaskStatusEnum.NOT_SUCCESS,
          })),
          grade: vals.grade,
          salary: +vals.salary,
          comment: vals.comment,
          salaryHand: vals.salaryHand,
          salaryType: activeSalaryType ? 2 : 1,
        })
          .then(() => {
            setToastSuccess('Успешно')
            updateReviews?.(userId)
            onClose(!show)
          })
          .catch(() => {
            handlerError(errors)
          })
          .finally(() => {
            setBtnDisabled(false)
            setIsLoading(false)
          })
    },
    validationSchema: Yup.object().shape({
      task: Yup.array()
        .of(Yup.string())
        .test(
          'min',
          RequiredFields.base,
          (arr) => arr?.every((v) => v?.length) ?? false
        ),
      salaryType: Yup.number().required(RequiredFields.base),
      salary: Yup.number().nullable(),
    }),
  })

  const handleClose = (): void => {
    onClose(!show)
    formik.resetForm()
  }

  const updateTaskList = useCallback((): void => {
    values.task.every((task) => task.length) &&
      setFieldValue(`task`, ['', ...values.task])
  }, [values.task])

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const translate = t<ProfileNS['reviews']>('profile:reviews')

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent btnDisabled={btnDisabled}>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>
            {review.status === ReviewStatuses.wait
              ? translateCompleteModal.title
              : translateCompleteModal.edit_title}
          </ST.ModalTitle>
          <ST.Label>
            <ST.LabelName>{translateModal.employee.label}:</ST.LabelName>
            <ST.LabelValue>
              {review.user.surname} {review.user.name}
            </ST.LabelValue>
          </ST.Label>
          <ST.ReviewInfo>
            <ST.ReviewType>{activeReviewType}</ST.ReviewType>
          </ST.ReviewInfo>
          <ST.InputWrapper>
            <ST.LabelWrapper>
              <ST.Label>
                {translateModal.date}
                <ST.Star>*</ST.Star>
              </ST.Label>
            </ST.LabelWrapper>
            <CalendarInput setStartDate={setDate} startDate={date} />
          </ST.InputWrapper>
          {!!review.tasks?.length && (
            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>{translate.completed_tasks}</ST.Label>
                <ST.ErrorText>{errors.tasks as string}</ST.ErrorText>
              </ST.LabelWrapper>
              <ST.WrapperTasks>
                {review.tasks.map((task) => (
                  <ST.Task key={task.id}>
                    <BaseCheckbox
                      label={task.text}
                      checked={tasks.find((_t) => _t.id === task.id)?.choice}
                      onChange={() =>
                        task.id !== undefined && handleCheckboxChange(task.id)
                      }
                    />
                  </ST.Task>
                ))}
              </ST.WrapperTasks>
            </ST.InputWrapper>
          )}
          <ST.InputWrapper>
            <ST.LabelWrapper>
              <ST.Label>{translate.next_tasks}</ST.Label>
              {formik.touched.task && (
                <ST.ErrorText>{errors.task}</ST.ErrorText>
              )}
            </ST.LabelWrapper>
            <ST.ListWrapper>
              {values.task.map((taskValue, index) => (
                <ST.InputWrapperWithDelete key={`task-${index}`}>
                  <ST.Input
                    key={index}
                    type="text"
                    placeholder={Placeholders.taskForReview}
                    sizeInput={InputSizes.big}
                    name={`task[${index}]`}
                    value={taskValue}
                    onChange={handleChange}
                  />
                  <IconButton
                    icon={DeleteIcon}
                    onClick={() =>
                      setFieldValue(
                        'task',
                        values.task.filter((_, i) => i !== index)
                      )
                    }
                  />
                </ST.InputWrapperWithDelete>
              ))}
            </ST.ListWrapper>
            <ST.AddButton
              onClick={updateTaskList}
              disabled={values.task.some((_t) => !_t.length)}
            >
              <Plus />
              {translateCompleteModal.add_task}
            </ST.AddButton>
          </ST.InputWrapper>
          <ST.InputsBlock>
            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>{translate.grade}</ST.Label>
                <ST.ErrorText>{errors.grade}</ST.ErrorText>
              </ST.LabelWrapper>
              <BaseSelect
                defaultSelect={false}
                placeHolder={EditingGeneralTabContentStrings.gradePlaceholder}
                listItems={gradesItems}
                name={EditingGeneralTabContentStrings.grade}
                value={
                  gradesItems.find((grade) => grade.value === selectedGrade)
                    ?.item ?? ''
                }
                onChange={(el) => {
                  setFieldValue('grade', el?.value)
                }}
                typeSelect={EditingGeneralTabContentStrings.grade}
                passValue={(_, currentValue) => {
                  setSelectedGrade(currentValue)
                }}
                setActive={setActiveSelect}
                activeSelect={activeSelect}
              />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>
                  {translateCompleteModal.salary_type.label}
                  <ST.Star>*</ST.Star>
                </ST.Label>
              </ST.LabelWrapper>
              <ST.InputSalaryType>
                <ST.SalaryType>
                  <ST.Checkbox
                    checked={!activeSalaryType}
                    type={SalaryEnum.checkbox}
                    onClick={() => {
                      activeSalaryType &&
                        setActiveSalaryType((prevState) => !prevState)
                    }}
                  />
                  <label>{translateCompleteModal.salary_type.salary}</label>
                </ST.SalaryType>
                <ST.SalaryType>
                  <ST.Checkbox
                    checked={activeSalaryType}
                    type={SalaryEnum.checkbox}
                    onClick={() => {
                      !activeSalaryType &&
                        setActiveSalaryType((prevState) => !prevState)
                    }}
                  />
                  <label>{translateCompleteModal.salary_type.hour}</label>
                </ST.SalaryType>
              </ST.InputSalaryType>
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.LabelWrapper>
                <ST.Label>{translateCompleteModal.salary}</ST.Label>
                <ST.ErrorText>{errors.salary}</ST.ErrorText>
              </ST.LabelWrapper>
              <ST.Input
                type="number"
                min={0}
                onKeyPressCapture={(e) => {
                  if (!/[0-9]/i.test(e.key)) e.preventDefault()

                  return /[0-9]/i.test(e.key)
                }}
                placeholder={Placeholders.sum}
                sizeInput={InputSizes.big}
                id={FormikFields.salary}
                name={FormikFields.salary}
                value={values.salary ?? ''}
                onChange={handleChange}
              />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.Label>{translateCompleteModal.comment.label}</ST.Label>
              <ST.InputTextArea
                sizeInput={InputSizes.big}
                id={FormikFields.comment}
                name={FormikFields.comment}
                value={values.comment}
                onChange={handleChange}
                placeholder={translateCompleteModal.comment.placeholder}
              />
            </ST.InputWrapper>
          </ST.InputsBlock>
          <ST.Button type={ButtonTypes.submit} onClick={checkForm}>
            {translateCompleteModal.submit}
          </ST.Button>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default FinishReview
