import React, { ElementRef, useEffect, useRef, useState } from 'react'
import { Popup as ST } from './styled'
import { CSSProperties } from 'styled-components'

export interface PopupProps extends React.HTMLAttributes<HTMLPreElement> {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: React.ReactNode
  centered?: boolean
}

export interface PopupWrapperProps {
  text: string
  padding?: CSSProperties['padding']
  fontColor?: CSSProperties['color']
  fontSize?: CSSProperties['fontSize']
  maxWidth?: CSSProperties['maxWidth']
  textAlign?: CSSProperties['textAlign']
  centered?: boolean
}

const Popup: FC<PopupProps> = ({
  isOpen,
  setIsOpen,
  children,
  centered,
  ...props
}) => {
  const popupRef = useRef<ElementRef<'pre'>>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setIsOpen])

  return (
    <>
      {isOpen && (
        <ST.Container {...props} centered={centered} ref={popupRef}>
          {children}
        </ST.Container>
      )}
    </>
  )
}

export const PopupWrapper: FC<PopupWrapperProps> = ({
  children,
  text,
  fontColor,
  centered = false,
  ...style
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const onMouseEnter = () => {
    setIsOpen(true)
  }

  const onMouseLeave = () => {
    setIsOpen(false)
  }

  return (
    <ST.Wrapper
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      centered={centered}
    >
      {children}
      <Popup
        centered={centered}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        style={{ ...style, color: fontColor }}
      >
        {text}
      </Popup>
    </ST.Wrapper>
  )
}

export default Popup
