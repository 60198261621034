import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import { getEmployees } from 'api/employees'
import { handlerError } from 'utils/handlerError'
import { ReactComponent as Back } from 'assets/icons/arrow-left.svg'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { parseDataToEmployees, parseHardwareTypesData } from 'utils/parseData'
import { PATHS } from 'constants/paths'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import { editHardware, getHardware } from 'api/hardwares'
import { HardwareTypes } from 'types/model/hardware'
import debounce from 'lodash/debounce'
import { getHardwareTypes } from 'api/hardwareTypes'
import Body from 'components/ui/layout/Body'
import Loader from 'components/ui/Loader'
import IconButton from 'components/ui/buttons/IconButton'
import * as Yup from 'yup'
import BaseInput from 'components/ui/inputs/BaseInput'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { HardwareNS } from 'i18n/types'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'

const inputName = 'name'
const hardwareType = 'hardwareType'
const serialNumber = 'serialNumber'
const cost = 'cost'

const EditHardware = () => {
  const { t } = useTypedTranslation(
    I18nNamespaces.HARDWARE,
    I18nNamespaces.REQUIRED_FIELDS
  )
  useTitle(t('edit_title'))

  const navigate = useNavigate()
  const { hardwareId } = useParams()
  const [hardware, setHardware] = useState<Nullable<HardwareTypes.Model>>(null)
  const [filterUserList, setFilterUserList] = useState<Array<IItem>>([])
  const [hardwareTypesList, setHardwareTypesList] = useState<
    HardwareTypes.HardwareType[]
  >([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const updateHardware = (id: number) => {
    getHardware(id)
      .then((data) => {
        setHardware(data)
      })
      .catch((err) => handlerError(err))
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getHardwareTypes().then((resp) => {
      setHardwareTypesList(resp.data)
    })
  }, [])

  const fetchLists = () => {
    getEmployees()
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((err) => handlerError(err))
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, {}, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const { handleChange, values, handleSubmit, setFieldValue, errors } =
    useFormik({
      enableReinitialize: true,
      initialValues: {
        name: hardware?.name ?? '',
        [hardwareType]: hardware?.hardwareType?.id ?? NaN,
        hardwareTypeData: hardwareTypesList?.length
          ? parseHardwareTypesData(hardwareTypesList).find(
              (type) => type?.value === hardware?.hardwareType?.id
            )
          : { item: '', value: 0 },
        serialNumber: hardware?.serialNumber ?? '',
        cost: hardware?.cost ?? '',
        responsibleUser: {
          value: hardware?.responsibleUser?.id ?? '',
          item: hardware?.responsibleUser
            ? `${hardware.responsibleUser.name} ${hardware.responsibleUser.surname}`
            : '',
        },
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().required(t('required_fields:base')),
        [hardwareType]: Yup.number().required(t('required_fields:base')),
      }),
      onSubmit: async (data) => {
        await editHardware(+hardwareId!, {
          ...data,
          responsibleUserId: values.responsibleUser.value
            ? +values.responsibleUser.value
            : null,
          hardwareTypeId: +values[hardwareType],
          cost: +data.cost,
        })
        hardwareId && updateHardware(+hardwareId)
        navigate(PATHS.hardwares)
        fetchLists()
      },
    })

  useEffect(() => {
    hardwareId && updateHardware(+hardwareId)
    fetchLists()
    window.scrollTo({
      top: 0,
    })
  }, [hardwareId])

  return (
    <Body>
      <ST.EditDepartmentHead>
        <ST.HeadDepartment>
          <IconButton icon={Back} onClick={() => navigate(PATHS.hardwares)} />
          <ST.TitleDepartment>{t('edit_heading')}</ST.TitleDepartment>
        </ST.HeadDepartment>
        <BaseButton
          width={200}
          height={48}
          type={'submit'}
          text={t('edit_submit')}
          typeButton={ButtonTypes.primary}
          onClick={handleSubmit}
        />
      </ST.EditDepartmentHead>

      <ST.EditDepartmentBody>
        {isLoading ? (
          <Loader marginTop="40px" />
        ) : (
          <>
            <ST.InputsRow>
              <BaseInput
                containerStyle={{ width: '100%' }}
                placeholder={t('name')}
                id={inputName}
                name={inputName}
                value={values.name}
                onChange={handleChange}
                label={t('name')}
                required
              />
            </ST.InputsRow>

            <ST.InputsRow>
              <BaseSelect
                label={t<HardwareNS['create_modal']>('create_modal').type}
                isSmallSelect={false}
                placeHolder={t<HardwareNS['create_modal']>('create_modal').type}
                listItems={hardwareTypesList.map((type) => ({
                  item: type.name,
                  value: type.id,
                }))}
                name={hardwareType}
                error={errors[hardwareType]}
                value={values?.hardwareTypeData?.item || ''}
                typeSelect={hardwareType}
                onChange={(newValue) => {
                  setFieldValue(
                    'hardwareTypeData',
                    newValue ?? { item: '', value: 0 }
                  )
                  setFieldValue(hardwareType, newValue?.value ?? null)
                }}
                required
              />
              <AutocompleteInput
                options={filterUserList}
                value={values.responsibleUser}
                onInputChange={debounce(handleInputChange, 1000)}
                getOptionLabel={(option) => option.item}
                onChange={(value) => {
                  setFieldValue(
                    'responsibleUser',
                    value ?? { item: '', value: 0 }
                  )
                }}
                label={t('responsible')}
                placeholder={t('responsible')}
              />
              <BaseInput
                id={serialNumber}
                name={serialNumber}
                value={values.serialNumber}
                onChange={handleChange}
                label={
                  t<HardwareNS['create_modal']>('create_modal').serial_number
                }
                placeholder={
                  t<HardwareNS['create_modal']>('create_modal').serial_number
                }
              />
              <BaseInput
                placeholder={t('cost')}
                id={cost}
                name={cost}
                value={values.cost}
                onChange={handleChange}
                label={t('edit_cost')}
              />
            </ST.InputsRow>
          </>
        )}
      </ST.EditDepartmentBody>
    </Body>
  )
}

export default EditHardware
