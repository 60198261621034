import * as ST from './styled'
import React from 'react'
import { DepartmentTypes } from 'types/model/department'

interface Props {
  positions: DepartmentTypes.Position[]
}

const PositionsLine: FC<Props> = ({ positions }) => (
  <ST.Container>
    <ST.Label>Должности:</ST.Label>
    <ST.Tags>
      {positions.map((position) => (
        <ST.Tag key={position.id}>{position.name}</ST.Tag>
      ))}
    </ST.Tags>
  </ST.Container>
)

export default PositionsLine
