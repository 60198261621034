/* eslint-disable react/jsx-pascal-case */
import { memo, useCallback, useMemo, useState } from 'react'
import { Tags } from 'styles/Tags'
import { HiringTypeBlockStyles as ST } from './styled'
import { HiringTypeBlockTypes as Types } from './types'
import {
  HiringTypeExpense,
  HiringTypeExpenseGroup,
  HiringTypeExpenseTypeOptions,
  HiringTypeStatus,
} from 'types/model/hiringType'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as ArchiveIcon } from 'assets/icons/Archive.svg'
import { archiveHiringType } from 'api/hiringType'
import { PopupWrapper } from 'components/ui/overlay/Popup'
import { COLORS } from 'constants/colors'
import { useModalWindowState } from 'hooks/useModalWindowState'
import ArchiveModal from 'components/settings/Tabs/shared/ArchiveModal'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { SettingsLocales } from 'i18n/types'
import { IItem } from 'components/ui/BaseSelect'
import { jsonObjectToItemsArray } from 'i18n/utils'

type TableRowTextProps = {
  value: string | null | undefined
}

type ExpenseProps = {
  group: HiringTypeExpenseGroup
  expenses: HiringTypeExpense[]
  expenseGroups: IItem<HiringTypeExpenseGroup>[]
}

const TableRowText: FC<TableRowTextProps> = ({ value }) => (
  <Tags.p1>{value}</Tags.p1>
)

const ExpenseGroup: FC<ExpenseProps> = memo<ExpenseProps>(
  ({ group, expenses, expenseGroups }) => {
    const groupData = expenseGroups.find((v) => v.value === group)!

    return expenses.length ? (
      <ST.TableRow key={group}>
        <Tags.p2Bold>
          {groupData.value}. {groupData.item}
        </Tags.p2Bold>

        <ST.TableRowDataContainer>
          {expenses.map((item) => (
            <ST.TableRowData key={item.id}>
              <TableRowText value={item.name} />
              <TableRowText
                value={`${item.value}${
                  HiringTypeExpenseTypeOptions.find(
                    (opt) => opt.value === item.type
                  )?.item
                }`}
              />
              <TableRowText value={String(item.number)} />
            </ST.TableRowData>
          ))}
        </ST.TableRowDataContainer>
      </ST.TableRow>
    ) : null
  },
  (prevProps, nextProps) => {
    return JSON.stringify(prevProps) === JSON.stringify(nextProps)
  }
)
const expenseGroups: HiringTypeExpenseGroup[] = [
  HiringTypeExpenseGroup.GENERAL,
  HiringTypeExpenseGroup.OVER_SALARY,
  HiringTypeExpenseGroup.FROM_SALARY,
]

const HiringTypeBlock: FC<Types.Props> = ({ data, toggleEditModal }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_HIRING_TYPES)
  const { modalOpen, toggleModal } = useModalWindowState()
  const [isArchived, setIsArchived] = useState<boolean>(
    data.status === HiringTypeStatus.ARCHIVED
  )

  const handleArchive = async () =>
    archiveHiringType(data.id).then(() => setIsArchived((p) => !p))

  const getExpensesByGroup = useCallback(
    (group: HiringTypeExpenseGroup) =>
      data.expenses?.filter((v) => v.expenseGroup === group) ?? [],
    [data.expenses]
  )

  const expenseGroupsOptions = useMemo<IItem<HiringTypeExpenseGroup>[]>(() => {
    const groups =
      t<SettingsLocales.HiringTypes['expense_groups']>('expense_groups')

    return jsonObjectToItemsArray(groups)
  }, [t])

  return (
    <ST.Container>
      <ST.Table>
        <ST.Header>
          <Tags.p1Bold>{data.name}</Tags.p1Bold>
          <Tags.p2Bold>{t('name')}</Tags.p2Bold>
          <Tags.p2Bold>{t('value')}</Tags.p2Bold>
          <Tags.p2Bold>{t('withdraw_number')}</Tags.p2Bold>
        </ST.Header>

        {expenseGroups.map((group) => (
          <ExpenseGroup
            key={`expense_group-${group}`}
            expenseGroups={expenseGroupsOptions}
            group={group}
            expenses={getExpensesByGroup(group)}
          />
        ))}
      </ST.Table>

      <ST.Buttons>
        <IconButton icon={EditIcon} onClick={toggleEditModal} />
        <PopupWrapper
          padding={10}
          fontColor={COLORS.black70}
          fontSize={14}
          text={t(isArchived ? 'unarchive' : 'archive')}
        >
          <IconButton
            icon={ArchiveIcon}
            onClick={toggleModal}
            typeButton={isArchived ? 'secondary' : 'primary'}
          />
        </PopupWrapper>
      </ST.Buttons>

      <ArchiveModal
        open={modalOpen}
        onClose={toggleModal}
        onSubmit={handleArchive}
        isArchived={isArchived}
        title={t('archive_title')}
        description={t('archive_description', { name: data.name })}
      />
    </ST.Container>
  )
}

export default HiringTypeBlock
