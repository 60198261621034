export enum ErrorMessages {
  incorrectEmail = 'Некорректный email',
  incorrectURL = 'Некорректная ссылка',
  fourSymbolMin = 'Минимальное кол-во символов – 4',
  sameEmail = 'Пользователь с таким email уже существует',
  forbidden = 'Для продолжения необходимо авторизоваться',
  httpsDomain = 'URL должен начинаться с https:// или http:// и содержать доменное имя',
  incorrectPhoneFormat = 'Некорректный формат номера телефона',
  incorrectYear = 'Некорректный год',
  maxSymbolsLimit = 'Превышено максимальное количество символов',
  endLoading = 'Все данные загружены',
  requiredField = 'Обязательное поле',
  positiveValue = 'Значение должно быть положительным',
}

export const DefaultErrorMessage = 'Произошла непредвиденная ошибка'
