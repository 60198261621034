// TODO: move auth logic to api/auth
// TODO: remove api/hiring

import { apiInstance } from 'api'

export enum AuthSource {
  HH = 1,
  HABR = 2,
  GOOGLE = 3,
  AVITO = 4,
}

export type AuthCodeHiringType = {
  authorizationCode: string
  service: AuthSource
  state?: string
}

export const sendAuthCodeHiring = async (
  data: AuthCodeHiringType
): Promise<void> => {
  apiInstance.post('authenticate/accesses', data).then((resp) => resp)
}
