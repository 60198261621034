import React, { useMemo } from 'react'
import * as ST from 'components/newCandidate/styled'
import { FormikProps } from 'formik'
import { ReactComponent as Plus } from 'assets/icons/plusAddButton.svg'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { FormikFields } from 'constants/formikFields'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { NewCandidateTypes as Types } from 'components/newCandidate/types'
import { IGeneralInfoEditingFormik } from 'types/userProfileTypes'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { ProfileNS } from 'i18n/types'
import { jsonObjectToItemsArray } from 'i18n/utils'
import { useGetResource } from 'i18n/hooks/useGetResource'

interface LanguagesProps {
  values: Types.Form | IGeneralInfoEditingFormik
  setFieldValue: FormikProps<Types.Form>['setFieldValue']
  selectedLanguage: IItem<number> | null
  updateForeignLanguageList: () => void
  handleLanguageChange: (newValue: IItem<number> | null) => void
  isForUser: boolean
}

const Languages: FC<LanguagesProps> = ({
  values,
  setFieldValue,
  selectedLanguage,
  updateForeignLanguageList,
  handleLanguageChange,
  isForUser = false,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.PROFILE)
  const languages = useGetResource(I18nNamespaces.LANGUAGES, 'names')
  const levels = useGetResource(I18nNamespaces.LANGUAGES, 'levels')
  const { languages: translate } = useGetResource(
    I18nNamespaces.PROFILE,
    'form'
  ).inputs

  const languageOptions = useMemo<IItem<number>[]>(
    () => jsonObjectToItemsArray(languages),
    [languages]
  )

  const levelOptions = useMemo<IItem<number>[]>(
    () => jsonObjectToItemsArray(levels),
    [levels]
  )

  return (
    <ST.Languages>
      <ST.LineContent>
        {!isForUser && (
          <ST.LeftContent>
            <ST.LeftTitle>{translate.title}</ST.LeftTitle>
          </ST.LeftContent>
        )}
        <ST.RightContent>
          {isForUser && <ST.LeftTitle>{translate.title}</ST.LeftTitle>}
          <ST.LanguageLine>
            <ST.InputSmallWrapper>
              <ST.LabelWrapper>
                <ST.Label>{translate.native}</ST.Label>
              </ST.LabelWrapper>
              <ST.SelectWrapper>
                <AutocompleteInput
                  options={languageOptions}
                  value={selectedLanguage || { item: '', value: 0 }}
                  isOptionEqualToValue={(option, val) =>
                    option.value === val?.value
                  }
                  getOptionLabel={(option) => option.item}
                  placeholder={translate.placeholder}
                  onChange={(val) => {
                    if (val) {
                      setFieldValue('nativeLanguage', val.item)
                      handleLanguageChange(val)
                    } else {
                      setFieldValue('nativeLanguage', null)
                      handleLanguageChange(null)
                    }
                  }}
                />
              </ST.SelectWrapper>
            </ST.InputSmallWrapper>
            <ST.InputSmallWrapper></ST.InputSmallWrapper>
            <ST.InputSmallWrapper></ST.InputSmallWrapper>
          </ST.LanguageLine>

          <div>
            <ST.LabelWrapper>
              <ST.SubTitle>
                {t<ProfileNS['user_data']>('user_data').language.foreign}
              </ST.SubTitle>
            </ST.LabelWrapper>

            {values.foreignLanguage
              ?.map((lang) => ({ item: lang.name, value: lang.level }))
              ?.map((value, index, array) => (
                <ST.CandidateInfo key={`foreignLanguage-${index}`}>
                  <ST.ExpInfo>
                    <AutocompleteInput
                      options={languageOptions.filter(
                        (opt) =>
                          values.foreignLanguage.findIndex(
                            (f) => f.nameType?.value === opt.value
                          ) === -1
                      )}
                      label={`${translate.lang} ${index + 1}`}
                      value={value}
                      isOptionEqualToValue={(option, val) =>
                        option.value === val?.value
                      }
                      onChange={(val) => {
                        setFieldValue(
                          'foreignLanguage',
                          values.foreignLanguage.map((lang, i) =>
                            i === index
                              ? {
                                  ...lang,
                                  name: val?.item ?? '',
                                  nameType: val,
                                }
                              : lang
                          )
                        )
                      }}
                      placeholder={translate.placeholder}
                      getOptionLabel={(option) => option.item}
                      disableClearable
                    />
                    <ST.InputWithDeleteWrapper>
                      <ST.SocialNetworkInputWrapper style={{ minWidth: 250 }}>
                        <BaseSelect
                          label={translate.level}
                          value={
                            levelOptions.find(
                              (level) => level.value === value.value
                            )?.item ?? ''
                          }
                          isSmallSelect={false}
                          placeHolder={translate.level_placeholder}
                          listItems={levelOptions}
                          name={`foreignLanguage[${index}].level`}
                          typeSelect={FormikFields.reviewType}
                          passValue={(_, b) => {
                            setFieldValue(
                              'foreignLanguage',
                              values.foreignLanguage?.map((item) =>
                                item.name === value.item
                                  ? {
                                      ...item,
                                      level: b,
                                      levelType: {
                                        item:
                                          levelOptions.find(
                                            (e) => e.value === b
                                          )?.item ?? '',
                                        value: b,
                                      },
                                    }
                                  : item
                              )
                            )
                          }}
                          style={{ minWidth: 250 }}
                        />
                      </ST.SocialNetworkInputWrapper>
                      <ST.WrapperDelete isBlur={array.length > 1}>
                        <Close
                          onClick={() => {
                            if (array.length > 1) {
                              const newValue = values.foreignLanguage?.filter(
                                (item) => item.name !== value.item
                              )
                              setFieldValue('foreignLanguage', newValue)
                            }
                          }}
                        />
                      </ST.WrapperDelete>
                    </ST.InputWithDeleteWrapper>
                  </ST.ExpInfo>
                </ST.CandidateInfo>
              ))}
          </div>

          <ST.WrapperButton>
            <ST.AddButton
              onClick={updateForeignLanguageList}
              disabled={values.foreignLanguage?.some(
                (lang) => !lang.name || !lang.level
              )}
            >
              <Plus />
              {translate.add}
            </ST.AddButton>
          </ST.WrapperButton>
        </ST.RightContent>
      </ST.LineContent>
    </ST.Languages>
  )
}

export default Languages
