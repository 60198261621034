import {
  SystemAction,
  SystemActionTypes,
  SystemMode,
  SystemState,
} from 'types/SystemType'
import i18n from 'i18next'

const initialState: SystemState = {
  mode: SystemMode.BLOCKED,
  systemLocale: null,
  userLocale: null,
}

export const SystemReducer = (
  state = initialState,
  action: SystemAction
): SystemState => {
  switch (action.type) {
    case SystemActionTypes.SET_MODE:
      return { ...state, mode: action.payload }
    case SystemActionTypes.SET_USER_LOCALE:
      i18n.changeLanguage(action.payload)
      return { ...state, userLocale: action.payload }
    case SystemActionTypes.SET_SYSTEM_LOCALE:
      return { ...state, systemLocale: action.payload }
    default:
      return state
  }
}
