import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import * as ST from './styled'
import { ReactComponent as Burger } from 'assets/icons/burger.svg'
import { ReactComponent as NotificationIcon } from 'assets/icons/notificationIcon.svg'
import { ReactComponent as NotificationHas } from 'assets/icons/notificationHasIcon.svg'
import Menu from 'components/Header/Menu'
import Notifications from 'components/Header/Notifications'
import { getNotifications, removeNotification } from 'api/userPage'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { ReactComponent as Logo } from 'assets/icons/logoHeader.svg'
import Exit from 'assets/icons/Header/exit.svg'
import ExitDialog from 'components/dialogs/exitDialog'
import { RootState, useAppSelector } from 'store/store'
import { ButtonLabels } from 'constants/buttonLabels'
import BaseButton from 'components/ui/buttons/BaseButton'
import { ButtonTypes } from 'constants/buttonTypes'
import { PATHS } from 'constants/paths'
import { useGetServiceModeStatusQuery } from 'store/api/setting'
import { UserTypes } from 'types/model/user'
import { useAuth } from 'hooks/useAuth'
import { ExtendedResponseType } from 'types/utils'
import { useNavigate } from 'react-router-dom'
import { SystemMode } from 'types/SystemType'
import { useDispatch } from 'react-redux'
import { SystemActionCreators } from 'store/actions/system'
import i18n from 'i18next'
import { I18N_LOCALSTORAGE_KEY, SupportedLocales } from 'i18n/config'
import updateAxiosBaseUrl from 'utils/url/updateAxiosBaseUrl'

interface Props {
  title?: string
  titleAttribute?: string
}

const Header: FC<Props> = ({ titleAttribute, title }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userId } = useAuth()
  const isMobileDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_PORTRAIT})`
  )

  // useRef ???
  const header = document
    ? document.querySelector('[data-testid="header"]')
    : null

  const [showMenu, setShowMenu] = useState<boolean>(false)
  const [showNotifications, setShowNotifications] = useState<boolean>(false)
  const [exitDialogIsShow, setExitDialogIsShow] = useState(false)
  const [isScrolledPersonal, setIsScrolledPersonal] = useState(false)
  const userInfo = useAppSelector((state: RootState) => state.userInfo)
  const [notifications, setNotifications] =
    useState<ExtendedResponseType<UserTypes.Notification> | null>(null)
  const [logoError, setLogoError] = useState<boolean>(false)

  // service mode logic
  const { data: serviceMode, isLoading: isServiceModeLoading } =
    useGetServiceModeStatusQuery(undefined, { skip: !i18n.resolvedLanguage })

  useEffect(() => {
    if (serviceMode && !isServiceModeLoading) {
      if (serviceMode.userLocale)
        dispatch(SystemActionCreators.setUserLocale(serviceMode.userLocale))
      if (serviceMode.systemLocale)
        dispatch(SystemActionCreators.setSystemLocale(serviceMode.systemLocale))
    }
  }, [dispatch, isServiceModeLoading, serviceMode])

  const handleMenu = () => {
    setShowMenu((p) => !p)
  }

  const handleNotifications = () => {
    setShowNotifications((p) => !p)
  }

  const handleCloseExitDialogIsShow = () => {
    setExitDialogIsShow((prevState) => !prevState)
  }

  const handleScroll = () => {
    const userInfoBlock = document.querySelector(
      `[data-testid="${titleAttribute || 'personal'}"]`
    )
    const userInfoTop = (userInfoBlock as any)?.offsetTop
    const scrollY = window.scrollY

    setIsScrolledPersonal(scrollY >= userInfoTop)
  }

  useLayoutEffect(() => {
    const lang = (i18n.resolvedLanguage ??
      localStorage.getItem(I18N_LOCALSTORAGE_KEY)) as SupportedLocales

    if (lang) updateAxiosBaseUrl(lang)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.resolvedLanguage])

  useEffect(() => {
    if (userId) {
      getNotifications(userId, { pageNumber: 1 }).then((resp) => {
        setNotifications(resp)
      })
    }
  }, [userId])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [header, titleAttribute])

  const loadNextPage = useCallback(() => {
    if (userId) {
      getNotifications(userId, {
        pageNumber: (notifications?.currentPageNumber ?? 0) + 1,
      }).then((res) => {
        setNotifications((prevState) => ({
          ...res,
          data: [...(prevState?.data ?? []), ...res.data],
        }))
      })
    } else {
      setNotifications(null)
    }
  }, [notifications?.currentPageNumber, userId])

  const hasMoreNotifications = useMemo(
    () =>
      notifications
        ? notifications.currentPageNumber < notifications.pageCount
        : false,
    [notifications]
  )

  const removeNotificationById = (id: number) => {
    if (userId) {
      removeNotification(userId, id)
      setNotifications((prevState) => {
        return {
          ...prevState,
          data: prevState?.data.filter(
            (notification) => notification.id !== id
          ),
        } as ExtendedResponseType<UserTypes.Notification>
      })
      if (notifications?.data && notifications?.data.length < 5) {
        loadNextPage()
      }
    }
  }

  return (
    <>
      {isMobileDevice ? (
        <ST.HeaderMobileContainer>
          <ST.LogoWrapper isVisible={!isScrolledPersonal}>
            {serviceMode?.logo && !logoError ? (
              <ST.LogoImg
                onError={() => {
                  setLogoError(true)
                }}
                size={28}
                src={serviceMode.logo}
              />
            ) : (
              <ST.Logo />
            )}
            <ST.LogoTitle>{process.env.REACT_APP_TITLE}</ST.LogoTitle>
          </ST.LogoWrapper>
          <ST.LogoWrapper isVisible={isScrolledPersonal} isCenter={!!title}>
            {!title && userInfo?.photo && <ST.UserPhoto src={userInfo.photo} />}
            <ST.LogoUserName>{title || userInfo?.fullName}</ST.LogoUserName>
          </ST.LogoWrapper>

          <ST.LinkIcon imageSrc={Exit} onClick={handleCloseExitDialogIsShow} />
        </ST.HeaderMobileContainer>
      ) : (
        <ST.HeaderContainer>
          <ST.HeaderContent>
            {serviceMode?.logo && !logoError ? (
              <ST.LogoImg
                onError={() => {
                  setLogoError(true)
                }}
                src={serviceMode.logo}
              />
            ) : (
              <Logo />
            )}
            <ST.ButtonsBlock>
              {!isServiceModeLoading &&
                serviceMode?.systemMode === SystemMode.SERVICE && (
                  <BaseButton
                    type={'submit'}
                    text={ButtonLabels.serviceModeIsOn}
                    typeButton={ButtonTypes.primary}
                    width="300px"
                    height="48px"
                    onClick={() => navigate(PATHS.settingsFirstTab)}
                  />
                )}
              <ST.NotificationButton onClick={handleNotifications}>
                <NotificationIcon />
                {!!notifications?.data.length && (
                  <ST.HasNotification>
                    <NotificationHas />
                  </ST.HasNotification>
                )}
              </ST.NotificationButton>
              <ST.BurgerButton onClick={handleMenu}>
                <Burger />
              </ST.BurgerButton>
            </ST.ButtonsBlock>
          </ST.HeaderContent>
        </ST.HeaderContainer>
      )}

      <Menu show={showMenu} onClose={handleMenu} />

      <Notifications
        show={showNotifications}
        onClose={handleNotifications}
        notifications={notifications}
        next={loadNextPage}
        removeNotificationById={removeNotificationById}
        hasMore={hasMoreNotifications}
      />
      <ExitDialog
        show={exitDialogIsShow}
        onClose={handleCloseExitDialogIsShow}
      />
    </>
  )
}

export default Header
