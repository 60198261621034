import React, { useCallback, useEffect, useState } from 'react'
import * as ST from './styled'
import { getDocumentTemplateSettings } from 'api/settings'
import StaticDocTemplatesTabContent from './StaticDocTemplatesTabContent'
import { IDocumentTemplate } from 'types/model/documentTemplateType'
import CreateDocTemplate from 'components/dialogs/settings/CreateDocTemplate'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseButton from 'components/ui/buttons/BaseButton'
import Loader from 'components/ui/Loader'

interface IUserProps {
  isEditing: boolean
}

const DocTemplatesTab: FC<IUserProps> = ({ isEditing }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_DOCUMENTS)
  const [docTemplates, setDocTemplates] = useState<IDocumentTemplate[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showModalCreateDocTemplate, setShowModalCreateDocTemplate] =
    useState<boolean>(false)

  const handleShowCreateDocTemplate = useCallback((): void => {
    setShowModalCreateDocTemplate((prev) => !prev)
  }, [])

  const updateDocuments = () => {
    setIsLoading(true)
    getDocumentTemplateSettings()
      .then((resp) => setDocTemplates(resp))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    updateDocuments()
  }, [])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>{t('title')}</ST.EditingHeader>
          </ST.BackBlock>
          <BaseButton
            width={240}
            text={t('add')}
            onClick={handleShowCreateDocTemplate}
          />
        </ST.ManageBlock>
      )}
      {isLoading ? (
        <Loader marginTop={100} />
      ) : (
        <StaticDocTemplatesTabContent docTemplates={docTemplates} />
      )}
      {showModalCreateDocTemplate && (
        <CreateDocTemplate
          show={showModalCreateDocTemplate}
          onClose={handleShowCreateDocTemplate}
          showEndModal={updateDocuments}
        />
      )}
    </ST.MainContainer>
  )
}

export default DocTemplatesTab
