import React, { createContext, memo, useContext, useMemo } from 'react'
import * as ST from './styled'
import TitleLine from './TitleLine'
import TargetLine from './TargetLine'
import { UserTypes } from 'types/model/user'
import Employee from './Employee'
import { ReviewStatuses, ReviewType, ReviewTypes } from 'types/model/review'
import { ReviewTypesLabels } from 'constants/reviewStatusesDescription'
import moment from 'moment'
import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar-Review.svg'
import StatusBlock from 'components/reviews/ReviewCard/TitleLine/StatusBlock'
import { DATE_FORMAT_DISPLAY } from 'constants/Date'
import getFileData from 'utils/file/getFileData'
import { ProfilePageTypes as Types } from 'components/profile/types'
import { ProfileNS } from 'i18n/types'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

type Props = {
  review: ReviewTypes.Model
  updateReviewList?: (userId: number) => void
  usersList: UserTypes.Model[]
  userInformation?: UserTypes.UserInfo | ReviewTypes.User | null
  hideEmployeeBlock?: boolean
  isCurrentUser?: boolean
  isProfile?: boolean
  userId?: number
}

type HeaderProps = {
  type: ReviewType
  date: string
  status: ReviewStatuses
}

export const ReviewCardTranslateContext = createContext<
  ProfileNS['reviews'] | null
>(null)

const ReviewCardHeader = memo<HeaderProps>(({ type, date, status }) => {
  const { types } = useContext<ProfileNS['reviews'] | null>(
    ReviewCardTranslateContext
  )!

  return (
    <ST.ReviewCardHeader>
      <ST.Title>{types?.[String(type) as keyof typeof types]}</ST.Title>
      <ST.Info>
        <ST.Date>
          <CalendarIcon />
          <ST.DateText>{date}</ST.DateText>
        </ST.Date>

        <StatusBlock status={status} />
      </ST.Info>
    </ST.ReviewCardHeader>
  )
})

const ReviewCard: FC<Props> = ({
  review,
  userInformation,
  usersList,
  updateReviewList,
  hideEmployeeBlock,
  isProfile,
}) => {
  const { t: pt } = useTypedTranslation(I18nNamespaces.PROFILE)
  const { isCurrentUser, userId, t } = Types.useProfilePageContext({ t: pt })

  const translate = t<ProfileNS['reviews']>('profile:reviews')

  const reviewDate = useMemo(
    () => moment(review.date).format(DATE_FORMAT_DISPLAY),
    [review.date]
  )

  const translatedStatuses = useMemo(
    () => pt<ProfileNS['reviews']>('reviews').user_statuses,
    [pt]
  )

  return (
    <ReviewCardTranslateContext.Provider value={translate}>
      <ST.ApplicationCard employeeBlockHidden={hideEmployeeBlock}>
        {!hideEmployeeBlock && (
          <Employee
            translatedStatuses={translatedStatuses}
            user={review.user}
            photo={getFileData(review.user.userImg)}
          />
        )}

        <ST.ReviewCardContent>
          <ReviewCardHeader
            type={review.type}
            date={reviewDate}
            status={review.status}
          />

          <TargetLine
            review={review}
            isCurrentUser={isCurrentUser}
            userId={userId}
            isProfile={isProfile}
          />

          {!!usersList?.length && (
            <TitleLine
              review={review}
              status={review.status}
              userId={review.user.id}
              userInformation={userInformation}
              userList={usersList}
              updateReviewList={updateReviewList}
              isCurrentUser={isCurrentUser}
            />
          )}
        </ST.ReviewCardContent>
      </ST.ApplicationCard>
    </ReviewCardTranslateContext.Provider>
  )
}

export default ReviewCard
