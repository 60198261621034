import { Resources } from 'react-i18next'
import {
  I18nNamespaces,
  SupportedLocales,
  SupportedLocalesValues,
} from 'i18n/config'
import i18n, { InitOptions } from 'i18next'

type Options = Pick<
  InitOptions<object>,
  'keySeparator' | 'ignoreJSONStructure'
> & {
  locale?: SupportedLocales
}

type R<N extends I18nNamespaces> = Resources[N]

/**
 * Хук для получения ресурса локализации
 * Аналог {@link useTypedTranslation}, но возвращает типизированные значения
 * <br>
 * Применение:
 *
 * ```ts
 * // В отличие от useTypedTranslation, здесь нет необходимости указывать вовзвращаемый тип в generic
 * const statuses = useGetResource(I18namespaces.VACANCIES, 'statuses')
 * ```
 *
 * @param {N} ns - namespace локализации
 * @param {K} key - ключ ресурса
 * @param {Options} options - дополнительные параметры
 *
 * @template {I18nNamespaces} N
 * @template {Resources[N]} T
 * @template {keyof T} K
 *
 * @returns {T[K]}
 * */
export const useGetResource = <
  N extends I18nNamespaces,
  T extends R<N> = R<N>,
  K extends Extract<keyof T, string> = Extract<keyof T, string>
>(
  ns: N,
  key: K,
  options?: Options
): T[K] => {
  const lang: SupportedLocales =
    options?.locale ??
    (i18n.resolvedLanguage as SupportedLocales) ??
    SupportedLocalesValues.ru

  return i18n.getResource(lang, ns, key, options) as T[K]
}
