import Layout from 'components/Layouts/layout'
import Body from 'components/ui/layout/Body'
import EmptyResult from 'components/views/feedback/EmptyResult'
import { BaseContainer } from 'components/ui/layout/BaseContainer'
import { ReactComponent as ServiceIcon } from 'assets/images/serviceIcon.svg'
import { CSSProperties } from 'styled-components'
import { PATHS } from 'constants/paths'
import { useGetResource } from 'i18n/hooks/useGetResource'
import { I18nNamespaces } from 'i18n/config'

const bodyStyle: CSSProperties = {
  padding: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 'calc(100vh - 100px)',
}

const baseContainerStyle: CSSProperties = { padding: '11px 0 35px' }

const UnexpectedError = () => {
  const translate = useGetResource(
    I18nNamespaces.ERROR_MESSAGES,
    'unexpected_error'
  )

  return (
    <Body style={bodyStyle}>
      <BaseContainer style={baseContainerStyle}>
        <EmptyResult
          icon={<ServiceIcon height="190px" style={{ marginLeft: '30px' }} />}
          title={translate.title}
          description={translate.description}
          style={{ gap: '22px' }}
          primaryButton={{
            text: translate.reload_button,
            action: () => window.location.reload(),
          }}
          secondaryButton={{
            text: translate.home_button,
            href: PATHS.home,
          }}
        />
      </BaseContainer>
    </Body>
  )
}

export const UnexpectedErrorPage = () => (
  <Layout>
    <UnexpectedError />
  </Layout>
)
