import React from 'react'
import { ReactComponent as Back } from 'assets/icons/arrow-left.svg'
import IconButton from 'components/ui/buttons/IconButton'

export interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void
}

const BackButton: FC<Props> = ({ onClick, ...props }) => (
  <IconButton icon={Back} onClick={onClick} {...props} />
)

export default BackButton
