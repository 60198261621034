import {
  INewSocialNetwork,
  IOldSocialNetwork,
  ISocialNetwork,
  SocialNetworks,
  SocialNetworksNames,
  socialNetworksStrings,
} from 'constants/socialNetworks'
import React, { Dispatch, FormEvent, useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { deleteUserSocialNetwork, updateUserSocialNetwork } from 'api/userPage'
import { formatSocialNetworkUrl } from 'utils/socialNetworks'
import { parseSocialNetworkUrl } from 'utils/socialNetworks/parseSocialNetworkUrl'
import { ProfilePageTypes as Types } from 'components/profile/types'
import { ProfileNS } from 'i18n/types'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import { useGetResource } from 'i18n/hooks/useGetResource'
import { I18nNamespaces } from 'i18n/config'

interface IItem {
  str: string
  id: number
}

const errorValue = 'Неверный формат'

interface ISocialNetworkBlock {
  data?: { id: number; name: SocialNetworks; link: string }
  dataNew?: { userId: number; name: SocialNetworks; link: string }
  isNew: boolean
  index: number
  deleteFromArr: (index: number, isNew: boolean) => void
  userId: number
  isSaved: boolean
  updateObjArr?: (
    obj: { userId: number; name: SocialNetworks; link: string },
    index: number
  ) => void
  edited?: {
    id: number
    name: SocialNetworks
    link: string
  }[]
  setEdited?: Dispatch<
    React.SetStateAction<
      {
        id: number
        name: SocialNetworks
        link: string
      }[]
    >
  >
}

export const SocialNetworkBlock = ({
  data,
  dataNew,
  isNew,
  index,
  deleteFromArr,
  userId,
  isSaved,
  updateObjArr,
  edited,
  setEdited,
}: ISocialNetworkBlock) => {
  const { RequiredFields } = useRequiredFields()
  const { t } = Types.useProfilePageContext()
  const list = useGetResource(I18nNamespaces.PROFILE, 'socials')
  const [selectedSocialNetwork, setSelectedSocialNetwork] = useState<number>(
    isNew
      ? SocialNetworks.NOT_SET
      : data?.name
      ? data?.name
      : SocialNetworks.NOT_SET
  )

  const socialNetworkList = useMemo<ISocialNetwork[]>(
    () =>
      Object.entries(list).map(([key, value], i) => ({
        ...socialNetworksStrings[i],
        id: +key,
        name: value as SocialNetworksNames,
      })),
    [list]
  )

  const listItems: IItem[] = useMemo(
    () =>
      [...socialNetworkList].splice(1).map((sn) => ({
        str: sn.name,
        id: sn.id,
      })),
    [socialNetworkList]
  )

  const [currentType, setCurrentType] = useState<ISocialNetwork>(
    socialNetworkList[data?.name ?? 0]
  )

  const currentLink = useMemo(
    () => parseSocialNetworkUrl(data?.link ?? '', currentType),
    [currentType, data?.link]
  )

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(RequiredFields.base),
    link: Yup.string()
      .required(RequiredFields.base)
      .matches(new RegExp(currentType.pattern)),
  })

  const { handleSubmit, handleChange, values, errors } = isNew
    ? // eslint-disable-next-line react-hooks/rules-of-hooks
      useFormik<INewSocialNetwork>({
        initialValues: {
          userId: userId,
          name: dataNew?.name ? dataNew.name : SocialNetworks.NOT_SET,
          link: dataNew?.link ? dataNew.link : '',
        },
        onSubmit: async () => {
          if (!isValid) return
        },
        validationSchema,
      })
    : // eslint-disable-next-line react-hooks/rules-of-hooks
      useFormik<IOldSocialNetwork>({
        initialValues: {
          id: data?.id ? data.id : 0,
          name: data?.name ? data.name : SocialNetworks.NOT_SET,
          link: currentLink ?? '',
        },
        onSubmit: async () => {
          if (!isValid) return
          if (data?.id) {
            updateUserSocialNetwork(
              data.id,
              selectedSocialNetwork,
              formatSocialNetworkUrl(values.link, currentType)
            )
          }
        },
        validationSchema,
      })

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  const deleteSocialNetwork = () => {
    if (data?.id) deleteUserSocialNetwork(data.id)
  }

  const updateSocialNetwork = (link: string) => {
    setEdited &&
      data &&
      setEdited((prevState) => {
        const isAlreadyEdited = prevState.some((el) => el.id === data.id)
        return isAlreadyEdited
          ? prevState.map((el) => (el.id === data.id ? { ...el, link } : el))
          : [
              ...prevState,
              {
                id: data.id,
                link: formatSocialNetworkUrl(link, currentType),
                name: selectedSocialNetwork,
              },
            ]
      })
  }

  useEffect(() => {
    if (isSaved) {
      handleSubmit()
    }
  }, [isSaved])

  useEffect(() => {
    if (!!updateObjArr) {
      updateObjArr(
        {
          userId: userId,
          link: formatSocialNetworkUrl(values.link, currentType),
          name: selectedSocialNetwork,
        },
        index
      )
    }
  }, [values.name, values.link, selectedSocialNetwork])

  const [open, setOpen] = useState<boolean>(false)

  const handleIsOpen = () => {
    setOpen((prevState) => !prevState)
  }

  const [visibleValues, setVisibleValues] = useState<boolean>(!isNew)

  const [visibleItem, setVisibleItem] = useState<number>(-1)

  const handleClick = (
    item: { str: string; id: number },
    index3: number
  ): void => {
    setVisibleItem(index3)
    setSelectedSocialNetwork(item.id)
    setCurrentType(
      (prevState) =>
        socialNetworkList.find((sn) => sn.id === item.id) ?? prevState
    )
    setVisibleValues(true)
    setTimeout(() => setOpen(!open), 150)
  }

  const translate = t<ProfileNS['form']>('form').inputs.socials

  return (
    <>
      <ST.Wrapper>
        <ST.InputWrapper>
          <ST.Label>
            {translate.name}
            <ST.Star>*</ST.Star>
          </ST.Label>
          <ST.SelectBlock>
            <ST.Select
              onClick={() => handleIsOpen()}
              isOpen={open}
              readOnly={true}
              placeholder={currentType?.name ?? ''}
              value={visibleValues ? currentType?.name : ''}
            />
            <ST.DropDownList isOpen={open}>
              {open
                ? listItems.map((item, index2) => (
                    <ST.ListItem
                      key={index2}
                      onClick={() => handleClick(item, index2)}
                      className={visibleItem === index2 ? 'active' : ''}
                    >
                      {item.str}
                    </ST.ListItem>
                  ))
                : null}
            </ST.DropDownList>
          </ST.SelectBlock>
        </ST.InputWrapper>
        <ST.InputWrapper>
          <ST.Label>
            {translate.link}
            <ST.Star>*</ST.Star>{' '}
            {isValid ? '' : <ST.Error>{errorValue}</ST.Error>}
          </ST.Label>
          <ST.InputWithButton>
            <ST.Input
              id={'link'}
              name={'link'}
              value={values.link}
              onChange={(e: FormEvent<HTMLInputElement>) => {
                updateSocialNetwork(e.currentTarget.value)
                handleChange(e)
              }}
              placeholder={
                currentType?.urlPlaceholder ?? t('socials_placeholder')
              }
              disabled={currentType?.id === 0}
            />
            <ST.DeleteBlock
              onClick={() => {
                if (isNew) {
                  deleteFromArr(index, isNew)
                } else {
                  deleteSocialNetwork()
                  deleteFromArr(index, isNew)
                }
              }}
            >
              <ST.Delete />
            </ST.DeleteBlock>
          </ST.InputWithButton>
        </ST.InputWrapper>
      </ST.Wrapper>
    </>
  )
}
