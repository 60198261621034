import { Action } from 'redux'
import { SupportedLocales } from 'i18n/config'

export enum SystemActionTypes {
  SET_MODE = 'SET_MODE',
  SET_SYSTEM_LOCALE = 'SET_SYSTEM_LOCALE',
  SET_USER_LOCALE = 'SET_USER_LOCALE',
}

export enum SystemMode {
  ACTIVE = 'active',
  SERVICE = 'service',
  BLOCKED = 'blocked',
}

export type SystemState = {
  mode: SystemMode
  userLocale: SupportedLocales | null
  systemLocale: SupportedLocales | null
}

interface SetMode extends Action {
  type: SystemActionTypes.SET_MODE
  payload: SystemMode
}

interface SetUserLocale extends Action {
  type: SystemActionTypes.SET_USER_LOCALE
  payload: SupportedLocales
}

interface SetSystemLocale extends Action {
  type: SystemActionTypes.SET_SYSTEM_LOCALE
  payload: SupportedLocales
}

export type SystemAction = SetMode | SetSystemLocale | SetUserLocale
