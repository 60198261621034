import styled from 'styled-components'
import { COLORS } from 'constants/colors'
import { IInput } from 'components/employment/main/form/Steps/education/styled'
import { InputSizes } from 'constants/inputSizes'
import { BreakPoints } from 'constants/breakPoints'

export const EditDepartmentHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`

export const EditDepartmentBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background: ${COLORS.white};
  box-shadow: 0 4px 15px rgba(51, 51, 51, 0.06);
  border-radius: 15px;
  padding: 30px;
  gap: 20px;
`

export const HeadDepartment = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    width: 200px;
  }
`

export const TitleDepartment = styled.p`
  font-weight: 900;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${COLORS.black};

  @media (max-width: ${BreakPoints.TABLETS_MIDDLE}) {
    font-size: 30px;
  }
  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    font-size: 24px;
  }
`

export const Button = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    width: 100%;
  }
`

export const Label = styled.p`
  font-weight: normal;
  padding-bottom: 6px;
  display: flex;
  align-items: start;
  font-size: 14px;
  line-height: 140%;
  color: ${COLORS.black70};

  span {
    margin-bottom: -15px;
  }
`

export const Input = styled.input<IInput>`
  border-radius: 10px;
  border: none;
  width: 100%;
  ${(props) =>
    props.sizeInput === InputSizes.big
      ? 'max-width: 500px'
      : 'max-width: 240px'};
  background: ${COLORS.inputBG} 99% 50% no-repeat;
  padding: 10px 20px;
  box-sizing: border-box;
  height: 40px;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;

  ::placeholder {
    opacity: 0.5;
  }

  :disabled {
    color: ${COLORS.black70};
  }

  @media (max-width: ${BreakPoints.TABLETS_PORTRAIT}) {
    height: 48px;
    max-width: initial;
    padding: 14px 16px 12px;
  }
`

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const InputsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;

  &:first-of-type {
    max-width: calc(50% + 15px);
  }

  @media (max-width: ${BreakPoints.DESKTOPS}) {
    &:first-of-type {
      max-width: calc(50% - 60px);
    }
    &:last-of-type {
      flex-wrap: wrap;
      max-width: calc(50% - 60px);
    }
  }
`

export const PositionsBlock = styled.div`
  display: flex;
  row-gap: 10px;
  column-gap: 30px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

export const PositionWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`
