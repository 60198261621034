import {
  IExperience,
  IPostUserDocInfo,
  IPostUserInfo,
  IUserApplication,
  IUserDocsInfo,
  IUserInfo,
  TApplication,
  TDeleteFileId,
  TEducation,
  TExperience,
  TPostFile,
} from 'types/employmentType'
import { handlerError } from 'utils/handlerError'
import { IBlockEducation, IEducationModel } from 'types/employment/education'
import { apiInstance } from 'api'
import { apiRequest } from 'api/request'

const isSuccess = {
  true: true,
  false: false,
}

export const postFile = async (
  fileUrl: string | File,
  fileName?: string
): Promise<TPostFile> => {
  try {
    const formData = new FormData()
    formData.append('file', fileUrl)
    fileName && formData.append('name', fileName)
    const resp = await apiInstance.post('upload/file', formData)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const deleteFileId = async (id: number): Promise<TDeleteFileId> => {
  try {
    const resp = await apiInstance.delete(`upload/file/${id}`)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const addScansToUser = async (
  data: { type: number; scans: number[] },
  userId?: number
): Promise<TDeleteFileId> => {
  try {
    const axiosInstance = apiInstance
    const params = new URLSearchParams()
    if (userId) {
      params.append('userId', String(userId))
    }
    const url = `document?${params.toString()}`
    const resp = await axiosInstance.post(url, data)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const deleteScanToEmployment = async (
  documentId?: number
): Promise<TDeleteFileId> => {
  try {
    const axiosInstance = apiInstance
    const url = `document/${documentId}`
    const resp = await axiosInstance.delete(url)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

/**
 * TODO использовать {@link postUserPersonalEditing}
 * @deprecated
 * */
export const postUserInfo = async (
  id: number,
  data: IUserInfo
): Promise<IPostUserInfo> => {
  try {
    const resp = await apiInstance.post(`user/personal?user=${id}`, data)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const postUserDocInfo = async (
  id: number,
  data: IUserDocsInfo
): Promise<IPostUserDocInfo> => {
  try {
    const resp = await apiInstance.post(`user/documents?user=${id}`, data)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const getStatementTypes = async (): Promise<Object> => {
  return apiInstance.get('api/statement').then((resp) => resp.data)
}

export const getDocumentsTypes = async (): Promise<Object> => {
  return apiInstance.get('api/document').then((resp) => resp.data)
}

export const uploadUserApplication = async (
  id: number,
  data: IUserApplication,
  statementId?: number
): Promise<TApplication> => {
  try {
    const query = new URLSearchParams({ user: String(id) })

    const resp = await apiInstance[statementId ? 'patch' : 'post'](
      `statement${statementId ? `${statementId}` : ''}?${query}`,
      data
    )
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const getUserEducation = async (
  userId: number
): Promise<IEducationModel[]> => {
  return apiInstance.get(`institute/${userId}`).then((res) => res.data)
}

export const postUserEducation = async (
  id: number,
  data: IBlockEducation
): Promise<TEducation> => {
  try {
    const resp = await apiInstance.post(`institute?user=${id}`, data)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const patchUserEducation = async (
  instituteId: number,
  data: IBlockEducation,
  token?: string
) => {
  try {
    const payload = await apiRequest({ token }).patch(
      `institute/${instituteId}`,
      data
    )

    return {
      payload,
      isSuccess: true,
    }
  } catch (e) {
    handlerError(e)
    return {
      isSuccess: false,
      error: e,
    }
  }
}

export const deleteInstitution = async (id: number, token?: string) =>
  apiRequest({ token }).delete(`institute/${id}`)

export const postExperienceInfo = async (
  id: number,
  data: IExperience,
  token?: string
): Promise<TExperience> => {
  try {
    const resp = await apiRequest({ token }).post(`workplace?user=${id}`, data)
    return {
      isSuccess: isSuccess.true,
      payload: resp.data,
    }
  } catch (err) {
    handlerError(err)
    return { isSuccess: isSuccess.false, error: err }
  }
}

export const patchUserWorkplace = (
  workplaceId: number,
  data: IExperience,
  token?: string
) => apiRequest({ token }).patch(`workplace/${workplaceId}`, data)

export const deleteUserWorkplace = async (id: number, token?: string) =>
  apiRequest({ token }).delete('workplace')

export const confirmFulfillEmployment = async (
  userId: number,
  token?: string
): Promise<void> => {
  apiRequest({ token })
    .get(`user/accept-questionnaire/${userId}`)
    .catch(handlerError)
}

export const deleteStatement = (id: number, token?: string): Promise<void> =>
  apiRequest({ token }).delete(`statement/${id}`)
