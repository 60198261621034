import useId from '@mui/material/utils/useId'
import React from 'react'
import * as ST from './styled'
import { CSSProperties } from 'styled-components'

export interface Props {
  label?: string
  id?: string
  name?: string
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeValue?: (value: boolean) => void
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  style?: CSSProperties
}

const BaseCheckbox: FC<Props> = ({
  label,
  id,
  name,
  checked,
  onChange,
  onChangeValue,
  onBlur,
  style,
}) => {
  const idGenerated = useId()

  return (
    <ST.Container>
      <ST.Input
        style={style}
        id={id ?? idGenerated}
        name={name}
        checked={!!checked}
        type={'checkbox'}
        onChange={(e) => {
          onChange?.(e)
          onChangeValue?.(e.target.checked)
        }}
        onBlur={onBlur}
      />
      {label && <ST.Label htmlFor={id ?? idGenerated}>{label}</ST.Label>}
    </ST.Container>
  )
}

export default BaseCheckbox
