import * as ST from 'components/settings/Tabs/Integrations/EditingIntegrationsTabContent/EditingTabs/HHTab/styled'
import BackButton from 'components/ui/buttons/BackButton'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import { RequiredFields } from 'constants/requiredFields'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseInput from 'components/ui/inputs/BaseInput'
import { EditingGeneralTabContentStrings } from 'components/settings/Tabs/Contacts/EditingContactsTabContent'
import { IntegrationsSettingsTypes as T } from 'components/settings/Tabs/Integrations/EditingIntegrationsTabContent/EditingTabs/types'
import React, { useMemo } from 'react'
import { useFormik } from 'formik'
import { ISetting, SettingsKeys } from 'types/model/settings'
import { getSettingByKey } from 'utils/parseData'
import { putSetting } from 'api/settings'
import { setToastError } from 'utils/handlerError'
import { useGetResource } from 'i18n/hooks/useGetResource'
import { I18nNamespaces } from 'i18n/config'

type AvitoKeys =
  | SettingsKeys.AVITO_NAME
  | SettingsKeys.AVITO_CLIENT_ID
  | SettingsKeys.AVITO_SECRET
  | SettingsKeys.AVITO_REDIRECT_URI
  | SettingsKeys.AVITO_AUTH_LINK

const keys: readonly AvitoKeys[] = [
  SettingsKeys.AVITO_NAME,
  SettingsKeys.AVITO_CLIENT_ID,
  SettingsKeys.AVITO_SECRET,
  SettingsKeys.AVITO_REDIRECT_URI,
  SettingsKeys.AVITO_AUTH_LINK,
] as const

type IForm = Record<AvitoKeys, string | undefined>

type Props = {
  handleIsEditing: () => void
  data: ISetting[]
}

export const AvitoTab: FC<Props> = ({ handleIsEditing, data }) => {
  const translate = useGetResource(I18nNamespaces.SETTINGS_INTEGRATIONS, 'edit')
  const { isValid, values, handleChange, handleSubmit } = useFormik<IForm>({
    initialValues: keys.reduce(
      (prev, curr) => ({ ...prev, [curr]: getSettingByKey(data, curr)?.value }),
      {}
    ) as IForm,
    onSubmit: (vals) => {
      const filteredSettings = data.filter((setting) =>
        keys.includes(setting.name as AvitoKeys)
      )

      const promises = filteredSettings.map((setting) =>
        putSetting(setting.id, {
          ...setting,
          value: vals[setting.name as AvitoKeys],
        })
      )

      Promise.all(promises).then(handleIsEditing).catch(setToastError)
    },
  })

  const inputsData = useMemo<T.InputData[]>(
    () =>
      keys.map((key) => {
        const currentSetting = getSettingByKey(data, key)
        const label =
          currentSetting?.name === SettingsKeys.AVITO_NAME
            ? translate.name.label
            : currentSetting?.title

        return {
          field: key,
          label: label ?? '',
          placeholder: label ?? '',
        }
      }),
    [data]
  )

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton onClick={handleIsEditing} />
          <ST.EditingHeader>{translate.title}</ST.EditingHeader>
          {!isValid && <ErrorMessage message={RequiredFields.base2} />}
        </ST.BackBlock>

        <BaseButton
          typeButton="primary"
          text={translate.save}
          onClick={handleSubmit}
          disabled={!isValid}
          width={220}
          height={40}
        />
      </ST.ManageBlock>
      <ST.BlockInfo>
        {inputsData.map(({ label, placeholder, field }) => (
          <ST.BottomWrapper key={field}>
            <BaseInput
              label={label}
              placeholder={placeholder}
              id={EditingGeneralTabContentStrings[field]}
              name={EditingGeneralTabContentStrings[field]}
              value={values[field as keyof IForm]}
              onChange={handleChange}
            />
          </ST.BottomWrapper>
        ))}
      </ST.BlockInfo>
    </>
  )
}
