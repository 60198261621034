import React, { useEffect, useState } from 'react'
import * as ST from './styled'
import DepartmentBlock from './DepartmentBlock'
import { getDepartments } from 'api/department'
import { handlerError } from 'utils/handlerError'
import CreateDepartment from 'components/dialogs/CreateDepartment'
import { DepartmentTypes } from 'types/model/department'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { useModalWindowState } from 'hooks/useModalWindowState'
import BaseButton from 'components/ui/buttons/BaseButton'
import Loader from 'components/ui/Loader'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

const Departments = () => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_DEPARTMENTS)
  const isTabletDevice = useMediaQuery(`(max-width: ${BreakPoints.TABLETS})`)

  const [departments, setDepartments] = useState<DepartmentTypes.Model[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { modalOpen, toggleModal } = useModalWindowState()

  const updateDepartments = () => {
    getDepartments()
      .then((data) => setDepartments(data))
      .catch((err) => handlerError(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    updateDepartments()
  }, [])

  return (
    <>
      <ST.DepartmentsHead>
        <ST.DepartmentsTitle>{t('title')}</ST.DepartmentsTitle>
        <BaseButton
          text={t('create')}
          onClick={toggleModal}
          width={200}
          height={40}
          disabled={isLoading}
        />
      </ST.DepartmentsHead>
      <ST.DepartmentsBody>
        {isLoading ? (
          <Loader marginTop="100px" />
        ) : (
          <ST.DepartmentsBlock>
            {!isTabletDevice && (
              <ST.DepartmentBlock>
                <ST.Title>{t('dept')}</ST.Title>
                <ST.Boss>{t('boss')}</ST.Boss>
                <ST.Title>
                  <ST.Employee>{t('employees')}</ST.Employee>
                </ST.Title>
                <ST.ButtonsBlock></ST.ButtonsBlock>
              </ST.DepartmentBlock>
            )}
            {departments.map((item, index) => (
              <DepartmentBlock
                key={index}
                departmentInfo={item}
                updateDepartments={updateDepartments}
              />
            ))}
          </ST.DepartmentsBlock>
        )}
      </ST.DepartmentsBody>
      <CreateDepartment
        show={modalOpen}
        onClose={toggleModal}
        updateDepartments={updateDepartments}
      />
    </>
  )
}

export default Departments
