import { AutocompleteMultipleTypes as Types } from './types'
import { AutocompleteMultipleStyles as ST } from './styled'
import { Autocomplete, SxProps, TextField } from '@mui/material'
import { useCallback, useState } from 'react'
import { CSSProperties } from 'styled-components'
import { COLORS } from 'constants/colors'
import useId from '@mui/material/utils/useId'
import { ReactComponent as DeleteIcon } from 'assets/icons/cancelGrey.svg'

export const AutocompleteMultipleChip: FC<Types.ChipProps> = ({
  value,
  ...props
}) => (
  <ST.Chip {...props}>
    {value} <DeleteIcon onClick={props.onDelete} />
  </ST.Chip>
)

const inputStyles: CSSProperties = {
  borderRadius: 10,
  border: 'none',
  background: ` ${COLORS.inputBG} 99% 50% no-repeat`,
  padding: '4px 16px',
  height: 'auto',
  fontWeight: 400,
  fontSize: 16,
}

const AutocompleteSx: SxProps = {
  '& .MuiOutlinedInput-root': {
    borderRadius: '0',
    padding: '0',
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}

const AutocompleteMultiple: FC<Types.Props> = ({
  options,
  value,
  onChange,
  placeholder,
  freeSolo = false,
  preserveOptions = false,
}) => {
  const id = useId()
  const [optionsList, setOptionsList] = useState<string[]>(options)

  const handleChange = useCallback(
    (val: string[], newOption?: string) => {
      onChange?.(val)

      if (newOption && preserveOptions && val.includes(newOption)) {
        setOptionsList((prevState) =>
          prevState.includes(newOption) ? prevState : [...prevState, newOption]
        )
      }
    },
    [onChange, preserveOptions]
  )

  return (
    <Autocomplete
      id={id}
      disablePortal
      sx={AutocompleteSx}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, style: inputStyles }}
          placeholder={placeholder}
        />
      )}
      renderTags={(v, getTagProps) =>
        v.map((opt, index) => {
          const { key, ...tagProps } = getTagProps({ index })

          return (
            <AutocompleteMultipleChip key={key} value={opt} {...tagProps} />
          )
        })
      }
      onChange={(_, v, __, d) => {
        handleChange(
          v.map((val) => val.trim()).filter((val) => val.length),
          d?.option
        )
      }}
      options={preserveOptions ? optionsList : options}
      renderOption={(props, option) => <li {...props}>{option}</li>}
      value={value}
      isOptionEqualToValue={(option, v) => option === v}
      freeSolo={freeSolo}
      clearText="Очистить"
      multiple
    />
  )
}

export default AutocompleteMultiple
