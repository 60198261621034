import React, { Dispatch, SetStateAction, useMemo } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import { handlerError } from 'utils/handlerError'
import { ButtonTypes } from 'constants/buttonTypes'
import { EditingGeneralTabContentStrings } from 'components/settings/Tabs/Contacts/EditingContactsTabContent'
import { ISetting, SettingsKeys } from 'types/model/settings'
import { getSettingByKey } from 'utils/parseData'
import { putSetting } from 'api/settings'
import BaseInput from 'components/ui/inputs/BaseInput'
import PasswordInput from 'components/ui/inputs/PasswordInput'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import * as Yup from 'yup'
import BackButton from 'components/ui/buttons/BackButton'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import { I18nNamespaces } from 'i18n/config'
import { useGetResource } from 'i18n/hooks/useGetResource'

interface IEditingMailTabContent {
  handleIsEditing: () => void
  setIsEditing: Dispatch<SetStateAction<boolean>>
  isEditingTabs: boolean
  data: ISetting[]
}

const CalendarTab: FC<IEditingMailTabContent> = ({ handleIsEditing, data }) => {
  const translate = useGetResource(I18nNamespaces.SETTINGS_INTEGRATIONS, 'edit')
  const { RequiredFields } = useRequiredFields()

  const onSubmit = async () => {
    const filteredSettings = data.filter((setting) =>
      [
        SettingsKeys.CALENDAR_MAIL_SENDER,
        SettingsKeys.CALENDAR_PORT,
        SettingsKeys.CALENDAR_HOST,
        SettingsKeys.CALENDAR_LOGIN,
        SettingsKeys.CALENDAR_PASSWORD,
        SettingsKeys.CALENDAR_NAME,
      ].includes(setting.name as SettingsKeys)
    )

    const settingsPromises = filteredSettings.map((setting) => {
      switch (setting.name) {
        case SettingsKeys.CALENDAR_MAIL_SENDER:
          return putSetting(setting.id, {
            ...setting,
            value: values.calendar_mail_sender,
          })
        case SettingsKeys.CALENDAR_PORT:
          return putSetting(setting.id, {
            ...setting,
            value: values.calendar_port,
          })
        case SettingsKeys.CALENDAR_HOST:
          return putSetting(setting.id, {
            ...setting,
            value: values.calendar_host,
          })
        case SettingsKeys.CALENDAR_LOGIN:
          return putSetting(setting.id, {
            ...setting,
            value: values.calendar_login,
          })
        case SettingsKeys.CALENDAR_PASSWORD:
          return putSetting(setting.id, {
            ...setting,
            value: values.calendar_password,
          })
        case SettingsKeys.CALENDAR_NAME:
          return putSetting(setting.id, {
            ...setting,
            value: values.calendar_name,
          })
      }
    })

    Promise.all(settingsPromises)
      .then(() => {
        handleIsEditing()
      })
      .catch((error) => console.error(error))
  }

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      calendar_mail_sender: getSettingByKey(
        data,
        SettingsKeys.CALENDAR_MAIL_SENDER
      )?.value,
      calendar_port: getSettingByKey(data, SettingsKeys.CALENDAR_PORT)?.value,
      calendar_host: getSettingByKey(data, SettingsKeys.CALENDAR_HOST)?.value,
      calendar_login: getSettingByKey(data, SettingsKeys.CALENDAR_LOGIN)?.value,
      calendar_password: getSettingByKey(data, SettingsKeys.CALENDAR_PASSWORD)
        ?.value,
      calendar_name: getSettingByKey(data, SettingsKeys.CALENDAR_NAME)?.value,
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      calendar_mail_sender: Yup.string().trim().required(RequiredFields.base),
      calendar_port: Yup.string().trim().required(RequiredFields.base),
      calendar_host: Yup.string().trim().required(RequiredFields.base),
      calendar_login: Yup.string().trim().required(RequiredFields.base),
      calendar_password: Yup.string().trim().required(RequiredFields.base),
      calendar_name: Yup.string().trim().required(RequiredFields.base),
    }),
  })

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton
            onClick={() => {
              handleIsEditing()
            }}
          />
          <ST.EditingHeader>{translate.title}</ST.EditingHeader>
          {!isValid && <ErrorMessage message={RequiredFields.base2} />}
        </ST.BackBlock>
        <ST.SaveButton
          type={ButtonTypes.submit}
          onClick={checkForm}
          disabled={!isValid}
          isDisabled={!isValid}
        >
          {translate.save}
        </ST.SaveButton>
      </ST.ManageBlock>
      <ST.FullName>
        <ST.BottomWrapper>
          <ST.InputWrapper>
            <BaseInput
              label={translate.name.label}
              placeholder={translate.name.placeholder}
              id={EditingGeneralTabContentStrings.calendar_name}
              name={EditingGeneralTabContentStrings.calendar_name}
              value={values.calendar_name || ''}
              onChange={handleChange}
            />
          </ST.InputWrapper>
          <ST.InputWrapper>
            <BaseInput
              label={translate.email.label}
              placeholder={translate.email.placeholder}
              id={EditingGeneralTabContentStrings.calendar_mail_sender}
              name={EditingGeneralTabContentStrings.calendar_mail_sender}
              value={values.calendar_mail_sender || ''}
              onChange={handleChange}
            />
          </ST.InputWrapper>
        </ST.BottomWrapper>
        <ST.HeaderBlock>{translate.smtp}</ST.HeaderBlock>
        <ST.BottomWrapper>
          <ST.InputWrapper>
            <BaseInput
              label={translate.host}
              placeholder={translate.host}
              id={EditingGeneralTabContentStrings.calendar_host}
              name={EditingGeneralTabContentStrings.calendar_host}
              value={values.calendar_host || ''}
              onChange={handleChange}
            />
          </ST.InputWrapper>
          <ST.InputWrapper>
            <BaseInput
              label={translate.port}
              placeholder={translate.port}
              id={EditingGeneralTabContentStrings.calendar_port}
              name={EditingGeneralTabContentStrings.calendar_port}
              value={values.calendar_port || ''}
              onChange={handleChange}
            />
          </ST.InputWrapper>
        </ST.BottomWrapper>
        <ST.BottomWrapper>
          <ST.InputWrapper>
            <BaseInput
              label={translate.login}
              placeholder={translate.login}
              id={EditingGeneralTabContentStrings.calendar_login}
              name={EditingGeneralTabContentStrings.calendar_login}
              value={values.calendar_login || ''}
              onChange={handleChange}
            />
          </ST.InputWrapper>
          <ST.InputWrapper>
            <PasswordInput
              label={translate.password}
              placeholder={translate.password}
              id={EditingGeneralTabContentStrings.calendar_password}
              name={EditingGeneralTabContentStrings.calendar_password}
              value={values.calendar_password || ''}
              onChange={handleChange}
            />
          </ST.InputWrapper>
        </ST.BottomWrapper>
      </ST.FullName>
    </>
  )
}

export default CalendarTab
