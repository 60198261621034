// noinspection HttpUrlsUsage

import { Endpoint } from 'constants/endpoint'

const isValidUrl = (url: string) =>
  url.startsWith('https://') || url.startsWith('http://')

export const formatUrl = (url: string): string =>
  isValidUrl(url) ? url : `https://${url}`

export const formatProjectUrl = (url: string) => {
  if (!url) return ''

  if (url.startsWith('https') || url.startsWith('http')) return url

  const urlHasSlash = url.startsWith('/')
  const endpointHasSlash = Endpoint.slice(-1) === '/'

  const formattedEndpoint = Endpoint.substring(0, Endpoint.length - 1)
  const formattedBoth = `${Endpoint}${url}`

  return urlHasSlash && endpointHasSlash
    ? `${formattedEndpoint}${url}`
    : urlHasSlash
    ? formattedBoth
    : endpointHasSlash
    ? formattedBoth
    : `${Endpoint}/${url}`
}

export const formatEndpoint = (value: string) =>
  value.slice(-1) === '/' ? value : `${value}/`
