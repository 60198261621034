import { AutocompleteInputStyles as ST } from './styled'
import { AutocompleteInputTypes as Types } from './types'
import React, {
  ChangeEvent,
  ElementRef,
  FocusEventHandler,
  HTMLAttributes,
  KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { BaseInputForwardedRefComponentMemoized } from 'components/ui/inputs/BaseInput'
import Loader from 'components/ui/Loader'
import useId from '@mui/material/utils/useId'
import { ReactComponent as ClearIcon } from 'assets/icons/cancelGrey.svg'
import { ReactComponent as ChevronDown } from 'assets/icons/employmentPage/arrow_down.svg'
import IconButton, {
  IconButtonMemoized,
} from 'components/ui/buttons/IconButton'
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { SelectDropdownBottomSpaceLimit } from 'components/ui/BaseSelect'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { ComponentsNS } from 'i18n/types'

enum KeyboardKeys {
  Escape = 'Escape',
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  Enter = 'Enter',
}

/**
 * Кастомная реализация компонента {@link Autocomplete} из MUI <br/>
 * Если используется асинхронная загрузка списка, следует передавать параметр [disableDefaultFiltering]{@link Types.Props#disableDefaultFiltering}<br/>
 * Если в списке могут быть неуникальные значения, следует передавать параметр [getOptionKey]{@link Types.Props#getOptionKey}
 *
 * */
const AutocompleteInput = <T,>({
  id,
  options,
  value,
  defaultValue,
  onInputChange,
  getOptionLabel,
  getOptionKey,
  getOptionDisabled,
  onChange,
  placeholder,
  optionsLoadingText,
  isOptionEqualToValue,
  onOpen,
  onClose,
  onFirstOpen,
  inputValue,
  containerStyle,
  inputContainerStyle,
  noOptionsText,
  autoComplete = 'off',
  filterSelectedOptions = false,
  disableDefaultFiltering = false,
  isOptionsLoading = false,
  matchCase = false,
  disabled = false,
  keyboardControl = false,
  doNotCloseOnSelect = false,
  matchFromStart = false,
  required = false,
  fullWidth = false,
  disableClearable = false,
  open,
  groupBy,
  inputStyle,
  ...props
}: Types.Props<T>) => {
  const { t } = useTypedTranslation(I18nNamespaces.UI_COMPONENTS)
  const dropdownId = useId()
  const clearButtonId = useId()
  const expandButtonId = useId()

  const containerRef = useRef<ElementRef<'div'>>(null)
  const inputRef = useRef<ElementRef<'input'>>(null)
  const dropdownRef = useRef<ElementRef<'div'>>(null)
  const hasOpenedOnce = useRef<boolean>(false)
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1)
  const [dropdownDistanceToBottom, setDropdownDistanceToBottom] =
    useState<number>(0)

  const hasEnoughBottomSpace = containerRef.current
    ? dropdownDistanceToBottom >= SelectDropdownBottomSpaceLimit
    : false

  const getLabel = useCallback(
    (option: Types.Value<T>): string =>
      getOptionLabel?.(option) ??
      (typeof option !== 'string' ? String(option) : option),
    [getOptionLabel]
  )

  const [isOpen, setIsOpen] = useState<boolean>(open ?? false)
  const [searchValue, setSearchValue] = useState<string>(
    inputValue ??
      (value ? getLabel(value) : defaultValue ? getLabel(defaultValue) : '')
  )

  const [selectedValue, setSelectedValue] = useState<Types.Value<T> | null>(
    value ?? defaultValue ?? null
  )

  const compareOptions = useCallback(
    (opt: Types.Option<T>) =>
      isOptionEqualToValue?.(opt.value, selectedValue) ??
      opt.value === selectedValue,
    [isOptionEqualToValue, selectedValue]
  )

  const prepareOptions = useCallback<
    () => ReadonlyArray<Types.Option<T>>
  >(() => {
    let filterByInput = !disableDefaultFiltering

    let resultOptions: Types.Option<T>[] = options.map((opt) => {
      const label = getLabel(opt)
      const key = getOptionKey?.(opt)

      if (label === searchValue) filterByInput = false

      return {
        id: key ? String(key) : `option-${label}`,
        value: opt,
        label,
      }
    })

    const filterMethod = matchFromStart ? 'startsWith' : 'includes'

    resultOptions = filterByInput
      ? resultOptions.filter((opt) =>
          matchCase
            ? opt.label[filterMethod](searchValue)
            : opt.label.toLowerCase()[filterMethod](searchValue.toLowerCase())
        )
      : resultOptions

    return filterSelectedOptions
      ? resultOptions.filter((opt) => !compareOptions(opt))
      : resultOptions
  }, [
    disableDefaultFiltering,
    options,
    filterSelectedOptions,
    getLabel,
    getOptionKey,
    searchValue,
    matchCase,
    matchFromStart,
    compareOptions,
  ])

  const availableOptions = useMemo<ReadonlyArray<Types.Option<T>>>(
    () => prepareOptions(),
    [prepareOptions]
  )

  // TODO доработать логику использования функции groupBy
  const groupedOptions = useMemo<Types.OptionGroup<T>>(
    () =>
      groupBy
        ? [...availableOptions]
            .sort((a, b) => {
              const aGroup = groupBy(a.value),
                bGroup = groupBy(b.value)

              return aGroup === bGroup ? 0 : aGroup > bGroup ? 1 : -1
            })
            .reduce((prev, curr) => {
              const groupValue = groupBy(curr.value)

              const optionsArray: Types.Option<T>[] =
                groupValue in prev ? prev[groupValue as keyof typeof prev] : []

              if (curr.label.startsWith(groupValue)) optionsArray.push(curr)

              return curr.label.startsWith(groupValue)
                ? { ...prev, [groupValue]: optionsArray }
                : prev
            }, {})
        : {},
    [availableOptions, groupBy]
  )

  const dropdownListHasOverflow = useMemo<boolean>(
    () => availableOptions.length >= ST.maxDropdownHeight / ST.listItemHeight,
    [availableOptions.length]
  )

  const renderOptions = (opts: readonly Types.Option<T>[]) =>
    opts.map((option, i) => (
      <ListItem
        id={option.id}
        key={option.id}
        title={option.label}
        tabIndex={0}
        focused={focusedOptionIndex === i}
        onClick={() => handleSelect(option)}
        active={!filterSelectedOptions ? compareOptions(option) : false}
        hasOverflow={isOptionsLoading ? false : dropdownListHasOverflow}
        disabled={getOptionDisabled?.(option.value)}
        hasGroupBy={!!groupBy}
      >
        {option.label}
      </ListItem>
    ))

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onInputChange?.(e.target.value, 'input')
      setSearchValue(e.target.value)
    },
    [onInputChange]
  )

  const handleFocus = useCallback(() => {
    setIsOpen(true)
    onOpen?.()

    if (!hasOpenedOnce.current) {
      onFirstOpen?.()
      hasOpenedOnce.current = true
    }
  }, [onFirstOpen, onOpen])

  const handleBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      let isSearchValuePreserved = false

      const closeCallback = (preserveValue: boolean) => {
        setIsOpen(false)
        onClose?.()
        preserveValue &&
          selectedValue &&
          setSearchValue(getLabel(selectedValue))
        setFocusedOptionIndex(-1)
      }

      const relatedTargetId = (e.relatedTarget as HTMLElement)?.id

      const excludedIds = [
        dropdownId,
        expandButtonId,
        clearButtonId,
        ...availableOptions.map((opt) => {
          if (opt.label !== searchValue) isSearchValuePreserved = true

          return opt.id
        }),
      ]

      if (!excludedIds.includes(relatedTargetId)) {
        closeCallback(isSearchValuePreserved && !!selectedValue)
      }
    },
    [
      availableOptions,
      clearButtonId,
      expandButtonId,
      getLabel,
      dropdownId,
      onClose,
      searchValue,
      selectedValue,
    ]
  )

  const handleSelect = useCallback(
    (option: Types.Option<T>) => {
      setSearchValue(option.label)
      setSelectedValue(option.value)
      setFocusedOptionIndex(-1)
      onChange?.(option.value)

      if (doNotCloseOnSelect) {
        inputRef.current?.focus()
      } else {
        setIsOpen(false)
      }
    },
    [onChange, doNotCloseOnSelect]
  )

  // FIXME не закрывается дропдаун при выделении с клавиатуры и нажатии вне него, обработать blur
  const handleKeyboardInput = useCallback<KeyboardEventHandler<HTMLDivElement>>(
    (e) => {
      switch (e.key) {
        case KeyboardKeys.Escape:
          inputRef.current?.blur()
          setIsOpen(false)
          setFocusedOptionIndex(-1)
          break
        case KeyboardKeys.ArrowDown:
          e.preventDefault()
          e.stopPropagation()

          setFocusedOptionIndex((p) =>
            p >= availableOptions.length - 1
              ? p - availableOptions.length + 1
              : Math.min(p + 1, availableOptions.length - 1)
          )
          break
        case KeyboardKeys.ArrowUp:
          e.preventDefault()
          e.stopPropagation()

          setFocusedOptionIndex((p) =>
            p <= 0 ? availableOptions.length - 1 : p - 1
          )
          break
        case KeyboardKeys.Enter:
          e.preventDefault()
          if (focusedOptionIndex >= 0) {
            handleSelect(availableOptions[focusedOptionIndex])
          }
          break
      }
    },
    [availableOptions, focusedOptionIndex, handleSelect]
  )

  const handleClear = useCallback(() => {
    setSearchValue('')
    setSelectedValue(null)
    onChange?.(null)
    onInputChange?.('', 'clear')

    inputRef.current?.focus()
  }, [onChange, onInputChange])

  const handleExpand = useCallback(() => {
    setIsOpen((prevState) => {
      if (!prevState) inputRef.current?.focus()

      return !prevState
    })
  }, [])

  useEffect(() => {
    const v = value ?? defaultValue

    if (v) {
      setSelectedValue(v)
      setSearchValue(getLabel(v))
    } else {
      setSelectedValue(null)
      setSearchValue('')
    }
  }, [value, defaultValue])

  useEffect(() => {
    if (open !== undefined) {
      setIsOpen(open)
      onFirstOpen?.()
      hasOpenedOnce.current = true
    }
  }, [onFirstOpen, open])

  useEffect(() => {
    const cb = () => {
      if (containerRef.current && dropdownRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        const dropdownRect = dropdownRef.current.getBoundingClientRect()

        setDropdownDistanceToBottom(
          window.innerHeight -
            rect.bottom -
            rect.height -
            dropdownRect.height +
            SelectDropdownBottomSpaceLimit
        )
      }
    }

    cb()

    document.addEventListener('scroll', cb)

    return () => {
      document.removeEventListener('scroll', cb)
    }
  }, [isOpen, availableOptions.length])

  return (
    <ST.Container
      ref={containerRef}
      style={containerStyle}
      fullWidth={fullWidth}
      onKeyDown={keyboardControl ? handleKeyboardInput : undefined}
      disableClearable={disableClearable}
    >
      <BaseInputForwardedRefComponentMemoized
        id={id}
        containerStyle={{
          paddingRight: fullWidth ? 0 : 50,
          ...inputContainerStyle,
        }}
        inputStyle={inputStyle}
        ref={inputRef}
        value={searchValue}
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        label={props.label}
        error={props.error}
        required={required}
        autoComplete={autoComplete ?? 'off'}
        clipPlaceholder
      />

      {isOpen && !disabled && (
        <ST.DropdownList
          alignToTop={hasEnoughBottomSpace}
          ref={dropdownRef}
          id={dropdownId}
          tabIndex={0}
          isLoading={isOptionsLoading}
          hasLabel={props.label !== undefined}
          hasOverflow={isOptionsLoading ? false : dropdownListHasOverflow}
        >
          {isOptionsLoading ? (
            optionsLoadingText ? (
              <ListItem disabled hasGroupBy={false}>
                {optionsLoadingText}
              </ListItem>
            ) : (
              <Loader width={18} height={18} marginTop={10} />
            )
          ) : availableOptions?.length ? (
            !!groupBy && !!Object.entries(groupedOptions).length ? (
              Object.entries(groupedOptions).map(([label, opts]) => (
                <OptionGroup key={label} label={label}>
                  {renderOptions(opts)}
                </OptionGroup>
              ))
            ) : (
              renderOptions(availableOptions)
            )
          ) : (
            <ListItem hasGroupBy={false} disabled centered>
              {noOptionsText ??
                t<ComponentsNS['AutocompleteInput']>('AutocompleteInput')
                  .no_options}
            </ListItem>
          )}
        </ST.DropdownList>
      )}

      <RightAdornmentButtons
        clearDisabled={!searchValue.length && !selectedValue}
        handleClear={handleClear}
        handleExpand={handleExpand}
        clearButtonId={clearButtonId}
        expandButtonId={expandButtonId}
        isOpen={isOpen}
        hasLabel={props.label !== undefined}
        disableClearable={disableClearable}
        disabled={disabled}
      />
    </ST.Container>
  )
}

const OptionGroup = memo<Types.OptionGroupProps>(({ children, label }) => (
  <ST.OptionGroup label={label}>{children}</ST.OptionGroup>
))

const ListItem = memo<HTMLAttributes<HTMLOptionElement> & Types.ListItemProps>(
  ({ children, focused, ...props }) => {
    const liRef = useRef<ElementRef<'option'>>(null)

    useEffect(() => {
      if (focused) liRef.current?.focus()
      else liRef.current?.blur()
    }, [focused])

    return (
      <ST.ListItem ref={liRef} {...props}>
        {children}
      </ST.ListItem>
    )
  }
)

const RightAdornmentButtons = memo<Types.RightAdornmentButtonsProps>(
  ({
    clearDisabled,
    expandButtonId,
    isOpen,
    handleClear,
    clearButtonId,
    hasLabel,
    handleExpand,
    disableClearable,
    disabled,
  }) => (
    <ST.RightAdornmentButtons hasLabel={hasLabel}>
      {!disableClearable && (
        <IconButtonMemoized
          id={clearButtonId}
          icon={ClearIcon}
          disabled={clearDisabled || disabled}
          onClick={handleClear}
          noFill
        />
      )}
      <IconButton
        id={expandButtonId}
        icon={ChevronDown}
        onClick={handleExpand}
        disabled={disabled}
        style={{ transform: `rotate(${isOpen ? '-180deg' : 0})` }}
        noFill
      />
    </ST.RightAdornmentButtons>
  )
)

export const AutocompleteInputMemoized = memo(
  AutocompleteInput
) as typeof AutocompleteInput

export default AutocompleteInput
