import React, { useState } from 'react'
import { PasswordInput as ST } from './styled'
import { ReactComponent as EyeIcon } from 'assets/icons/password-eye.svg'
import { ReactComponent as EyeClosedIcon } from 'assets/icons/password-eye-closed.svg'
import { BaseInputTypes as T } from 'components/ui/inputs/BaseInput/types'

const PasswordInput: FC<T.Props> = ({
  value,
  id,
  name,
  placeholder,
  required,
  label,
  isValid,
  error,
  containerStyle,
  onChange,
  onBlur,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible)
  }

  return (
    <ST.Container style={containerStyle}>
      {label && (
        <ST.LabelContainer>
          <ST.Label htmlFor={id}>
            {label}
            {required && <ST.RequiredIndicator>*</ST.RequiredIndicator>}
          </ST.Label>
        </ST.LabelContainer>
      )}
      <ST.InputContainer>
        <ST.Input
          {...props}
          size={undefined}
          value={value}
          id={id}
          name={name}
          type={isPasswordVisible ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
        />
        <ST.IconButton
          onClick={togglePasswordVisibility}
          aria-label="Показать/скрыть пароль"
        >
          {isPasswordVisible ? <EyeClosedIcon /> : <EyeIcon />}
        </ST.IconButton>
      </ST.InputContainer>
      {error && <ST.Error>{error}</ST.Error>}
    </ST.Container>
  )
}

export default PasswordInput
