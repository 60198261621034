import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as ST from './styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { getEmployees } from 'api/employees'
import { editDepartment, getCurrentDepartment } from 'api/department'
import { handlerError } from 'utils/handlerError'
import { DepartmentTypes } from 'types/model/department'
import BackButton from 'components/ui/buttons/BackButton'
import BaseButton from 'components/ui/buttons/BaseButton'
import { IItem } from 'components/ui/BaseSelect'
import PositionInput from './PositionInput'
import AddPositionButton from './AddPositionButton'
import { getPositions } from 'api/position'
import {
  parseDataToDynamicPositions,
  parseDataToEmployees,
} from 'utils/parseData'
import { PATHS } from 'constants/paths'
import { RequiredFields } from 'constants/requiredFields'
import { ButtonTypes } from 'constants/buttonTypes'
import { useTitle } from 'hooks/useTitle'
import debounce from 'lodash/debounce'
import Body from 'components/ui/layout/Body'
import Loader from 'components/ui/Loader'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { SettingsLocales } from 'i18n/types'
import BaseInput from 'components/ui/inputs/BaseInput'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'

export interface IDynamicPosition extends IItem {
  new?: boolean
}

const inputName = 'name'
const directorInputName = 'director'

const EditDepartment = () => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_DEPARTMENTS,
    I18nNamespaces.TRANSLATION
  )

  const formTranslate =
    t<SettingsLocales.Departments['create_modal']>('create_modal')

  useTitle(t('edit_title'))
  const navigate = useNavigate()
  const { departmentId } = useParams()

  const [department, setDepartment] = useState<DepartmentTypes.Model | null>(
    null
  )
  const [allPositions, setAllPositions] = useState<IDynamicPosition[]>([])
  const [positions, setPositions] = useState<IDynamicPosition[]>([])
  const [newPositionCount, setNewPositionCount] = useState<number>(0)
  const [filterUserList, setFilterUserList] = useState<Array<IItem>>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)

  const updateDepartment = (id: number) => {
    getCurrentDepartment(id)
      .then((data) => {
        setDepartment(data)
        setPositions(parseDataToDynamicPositions(data.positions))
        setBtnDisabled(true)
      })
      .catch((err) => handlerError(err))
      .finally(() => {
        setIsLoading(false)
        setBtnDisabled(false)
      })
  }

  const fetchLists = async () => {
    await getPositions()
      .then((data) => setAllPositions(parseDataToDynamicPositions(data)))
      .catch((err) => handlerError(err))
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, {}, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const { handleChange, values, handleSubmit, setFieldValue } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: department?.name || '',
      director: {
        value: department?.director?.id ?? 0,
        item: department?.director
          ? `${department?.director.name} ${department?.director.surname}`
          : '',
      },
    },
    onSubmit: async (data) => {
      const newPositions = positions
        .map((item) => String(item.value))
        .filter((item) => item && !item.includes('new'))

      await editDepartment(+departmentId!, {
        name: data.name,
        director: data.director.value,
        positions: newPositions,
      })
      departmentId && updateDepartment(+departmentId)
      await fetchLists()
      navigate(PATHS.settings_departments)
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required(RequiredFields.base),
      director: Yup.object().required(RequiredFields.base),
    }),
  })

  useEffect(() => {
    void fetchLists()
  }, [])

  useEffect(() => {
    departmentId && updateDepartment(+departmentId)
  }, [departmentId])

  const isValid = useMemo(
    () =>
      values.name &&
      values.director?.value &&
      values.director?.value !== -1 &&
      positions.length,
    [values.director?.value, values.name, positions]
  )

  useEffect(() => {
    setBtnDisabled(!isValid)
  }, [isValid])

  const removePosition = (indexToRemove: number) => {
    if (department) {
      setPositions(positions.filter((_, index) => index !== indexToRemove))
    }
  }

  const createPosition = () => {
    setPositions([
      ...positions,
      {
        value: `new-${newPositionCount}`,
        item: '',
        new: true,
      },
    ])
    setNewPositionCount((prevState) => prevState + 1)
  }

  return (
    <Body>
      <ST.EditDepartmentHead>
        <ST.HeadDepartment>
          <BackButton onClick={() => navigate(PATHS.settings_departments)} />
          <ST.TitleDepartment>{t('translation:editing')}</ST.TitleDepartment>
        </ST.HeadDepartment>
        <ST.Button>
          <BaseButton
            type={'submit'}
            text={t('translation:save')}
            typeButton={ButtonTypes.primary}
            onClick={handleSubmit}
            fakeDisabled={btnDisabled}
          />
        </ST.Button>
      </ST.EditDepartmentHead>
      <ST.EditDepartmentBody>
        {isLoading ? (
          <Loader marginTop="40px" />
        ) : (
          <>
            <ST.DepartmentHead>
              <BaseInput
                placeholder={formTranslate.name_placeholder}
                id={inputName}
                name={inputName}
                value={values.name}
                onChange={handleChange}
                label={formTranslate.name}
                required
              />
              <AutocompleteInput
                options={filterUserList}
                value={values.director}
                onInputChange={debounce(
                  (e: any) => handleInputChange(e.target.value),
                  1000
                )}
                getOptionLabel={(option) => option.item}
                onChange={(value) => {
                  if (value) {
                    setFieldValue(directorInputName, value)
                  } else {
                    setFieldValue(directorInputName, { item: '', value: -1 })
                  }
                }}
                label={formTranslate.boss}
                placeholder={formTranslate.boss}
                required
              />
            </ST.DepartmentHead>
            <ST.Label>
              {formTranslate.position}
              <ST.Star>*</ST.Star>
            </ST.Label>
            <ST.PositionsBlock>
              {positions.map((item, index) => (
                <ST.PositionWrapper key={String(item.value)}>
                  <PositionInput
                    value={item}
                    listItems={allPositions}
                    selectedItems={positions}
                    isSmallSelect
                    placeholder={formTranslate.position_placeholder}
                    onChange={(newValue) => {
                      if (newValue) {
                        const newPositions = positions
                        newPositions[index] = newValue
                        setPositions(newPositions)
                      }
                    }}
                    onRemove={() => removePosition(index)}
                  />
                </ST.PositionWrapper>
              ))}
            </ST.PositionsBlock>
            <AddPositionButton
              text={formTranslate.add_position}
              onClick={createPosition}
            />
          </>
        )}
      </ST.EditDepartmentBody>
    </Body>
  )
}

export default EditDepartment
