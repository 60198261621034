import { apiInstance } from 'api'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'

import { ExtendedResponseType } from 'types/utils'

export const getBenefitApplications = async (
  page?: number,
  parameters?: BenefitApplicationTypes.Filter
): Promise<ExtendedResponseType<BenefitApplicationTypes.Model>> => {
  const pageSize: number = 50
  let pageNumber: number
  if (page) {
    pageNumber = page
  } else {
    pageNumber = 1
  }
  const params = new URLSearchParams()
  params.append('pageSize', String(pageSize))
  params.append('pageNumber', String(pageNumber))

  if (parameters) {
    for (let [key, value] of Object.entries(parameters)) {
      value && params.append(key, value)
    }
  }

  return apiInstance
    .get(`benefit-application?${params.toString()}`)
    .then((resp) => resp.data)
}

export const getBenefitApplication = async (
  benefitApplicationId: number
): Promise<BenefitApplicationTypes.Model> => {
  return apiInstance
    .get(`benefit-application/${benefitApplicationId}`)
    .then((resp) => resp.data)
}

export const createBenefitApplication = async (
  benefitApplication: BenefitApplicationTypes.Request
): Promise<BenefitApplicationTypes.Model> => {
  return apiInstance
    .post<BenefitApplicationTypes.Model>('benefit-application', {
      ...benefitApplication,
    })
    .then((resp) => resp.data)
}

export const editBenefitApplication = async (
  benefitApplicationId: number,
  benefit: BenefitApplicationTypes.Request
): Promise<BenefitApplicationTypes.Model> => {
  return apiInstance
    .patch<BenefitApplicationTypes.Model>(
      `benefit-application/${benefitApplicationId}`,
      benefit
    )
    .then((resp) => resp.data)
}

export const approveBenefitApplication = async (
  benefitApplicationId: number,
  benefit: BenefitApplicationTypes.Approve
): Promise<BenefitApplicationTypes.Model> => {
  return apiInstance
    .patch<BenefitApplicationTypes.Model>(
      `benefit-application/approval/${benefitApplicationId}`,
      benefit
    )
    .then((resp) => resp.data)
}

export const rejectBenefitApplication = async (
  benefitApplicationId: number,
  benefit: BenefitApplicationTypes.Reject
): Promise<BenefitApplicationTypes.Model> => {
  return apiInstance
    .patch<BenefitApplicationTypes.Model>(
      `benefit-application/rejection/${benefitApplicationId}`,
      benefit
    )
    .then((resp) => resp.data)
}

export const cancelBenefitApplication = async (
  benefitApplicationId: number
): Promise<BenefitApplicationTypes.Model> => {
  return apiInstance
    .patch<BenefitApplicationTypes.Model>(
      `benefit-application/cancellation/${benefitApplicationId}`
    )
    .then((resp) => resp.data)
}

export const removeBenefitApplication = async (
  benefitApplicationId: number
) => {
  return apiInstance.delete<BenefitApplicationTypes.Model>(
    `benefit-application/${benefitApplicationId}`
  )
}
