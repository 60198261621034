import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { getEmployees } from 'api/employees'
import { handlerError } from 'utils/handlerError'
import { parseDataToBenefits, parseDataToEmployees } from 'utils/parseData'
import { ButtonTypes } from 'constants/buttonTypes'
import * as ST from './styled'
import { Placeholders } from 'constants/placeholders'
import { createBenefitApplication } from 'api/benefitsApplications'
import { BenefitTypes } from 'types/model/benefit'
import { BenefitType } from 'constants/benefitTypes'
import { BenefitOperationTypes } from 'constants/benefitOperationTypes'
import CalendarInput from 'components/ui/inputs/Calendar'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import debounce from 'lodash/debounce'
import * as Yup from 'yup'
import { UserTypes } from 'types/model/user'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { UserStatuses } from 'constants/userStatuses'
import { urlArrayNullable } from 'utils/validation/tests'
import { useAuth } from 'hooks/useAuth'
import { DATE_FORMAT_DTO } from 'constants/Date'
import BaseButton from 'components/ui/buttons/BaseButton'
import { Plus } from '../CreateGrade/styled'
import { BenefitsNS, MessagesNS, ProfileNS } from 'i18n/types'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import checkRole from 'utils/profile/checkRole'
import { ROLES } from 'constants/roles'

export interface Props {
  show: boolean
  onClose: SetState<boolean>
  onSuccess?: () => void
  userId?: number
  userData: UserTypes.Model[]
  benefitData: BenefitTypes.Model[]
  benefitWallet?: number
}

const CreateEmployeeEnum = {
  emptyString: '',
  firstIndexInArray: 0,
}

const selectsBenefitApplicationEnum = {
  targetUser: 'targetUser',
  commentary: 'commentary',
  value: 'value',
  benefit: 'benefit',
  benefitId: 'benefitId',
  cancellationDate: 'cancellationDate',
}

const operationType = 'operationType'

const enum CreateBenefitApplicationStrings {
  sizeInputBig = 'big',
  surname = 'surname',
  name = 'name',
  email = 'email',
  salary = 'salary',
  patronymic = 'patronymic',
  date = 'date',
  dateFormat = '##.##.####',
  department = 'department',
  post = 'post',
  grade = 'grade',
  none = 'none',
  file = 'file',
  hardwareType = 'hardwareType',
  cost = 'cost',
  responsibleUser = 'responsibleUser',
  serialNumber = 'serialNumber',
  type = 'type',
}

const errorText = 'Не заполнены обязательные поля'

type Form = {
  targetUserId: number | null
  targetUser: IItem<number> | null
  commentary: string
  value: number
  benefitId: number | null
  benefit: IItem<number> | null
  cancellationDate: string
  type: BenefitType
  typeData: IItem<BenefitType>
  operationTypeData?: IItem<BenefitOperationTypes>
  operationType: BenefitOperationTypes
  links: string[]
}

// TODO refactor; BaseInput
const CreateBenefitApplication = ({
  show,
  onClose,
  onSuccess,
  userData,
  benefitData,
  userId,
}: Props) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.BENEFITS,
    I18nNamespaces.PROFILE,
    I18nNamespaces.MESSAGES
  )
  const translate = t<ProfileNS['benefits']>('profile:benefits')
  const RequiredFields = t<MessagesNS['required_fields']>(
    'info_messages:required_fields'
  )
  const modalRef = useRef<ElementRef<'div'>>(null)

  const { roles } = useAuth()

  const [userList, setUserList] = useState<Array<IItem>>([])
  const [benefitList, setBenefitList] = useState<BenefitTypes.Model[]>([])
  const [filteredBenefitList, setFilteredBenefitList] = useState<
    Array<IItem<number>>
  >([])
  const [cancellationDate, setCancellationDate] = useState<Date>(
    new Date(Date.now())
  )
  const [selectedBenefit, setSelectedBenefit] = useState<IItem<number> | null>(
    null
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isAdminOrHR =
    checkRole(roles, [ROLES.admin]) || checkRole(roles, [ROLES.hr])

  const listOperationTypes = useMemo<IItem<BenefitOperationTypes>[]>(() => {
    const types = Object.entries(
      t<BenefitsNS['operationTypes']>('operationTypes')
    ).map(([key, value]) => ({
      item: value,
      value: +key as BenefitOperationTypes,
    }))

    return isAdminOrHR
      ? types
      : types.filter((v) => v.value !== BenefitOperationTypes.refill)
  }, [isAdminOrHR, t])

  const {
    handleChange,
    values,
    resetForm,
    handleSubmit,
    errors,
    isValid,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormik<Form>({
    enableReinitialize: false,
    validateOnMount: true,
    initialValues: {
      targetUserId: userId ?? 0,
      targetUser: { value: 0, item: '' },
      commentary: '',
      value: 0,
      benefitId: null,
      benefit: null,
      cancellationDate: moment().format(DATE_FORMAT_DTO),
      type: BenefitType.DEVICES,
      typeData: {
        item: benefitData[0].type,
        value: benefitData[0].id,
      },
      operationTypeData: undefined,
      operationType: BenefitOperationTypes.refill,
      links: [],
    },
    onSubmit: () => {
      const newObj = {
        targetUserId: values.targetUserId!,
        commentary: values.commentary,
        value: +values.value,
        benefitId: selectedBenefit?.value!,
        cancellationDate: moment(cancellationDate).format(DATE_FORMAT_DTO),
        links: values.links,
      }
      setIsLoading(true)
      createBenefitApplication(newObj)
        .then(() => {
          handleClose()
          resetForm()
          onSuccess?.()
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    validationSchema: Yup.object().shape({
      value: Yup.number().required(RequiredFields.base),
      benefitId: Yup.number().nullable().required(RequiredFields.base),
      type: Yup.number().required(RequiredFields.base),
      operationType: Yup.number().required(RequiredFields.base),
      cancellationDate: Yup.string().required(RequiredFields.base),
      links: Yup.array().nullable(),
    }),
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modal-root')

  const handleClose = (): void => {
    onClose(!show)
  }

  const removeLink = (index: number) => {
    setFieldValue(
      'links',
      values.links.filter((link, linkIndex) => index !== linkIndex)
    )
    setFieldTouched(`links[${index}]`, false)
  }

  const addLink = () => {
    setFieldValue('links', [...values.links, ''])
    setFieldTouched(`links[${values.links.length}]`, false)
  }

  const changeLink = (link: string, index: number) => {
    setFieldValue(
      'links',
      values.links.map((linkItem, linkIndex) => {
        if (index === linkIndex) {
          return link
        }
        return linkItem
      })
    )
    setFieldTouched(`links[${index}]`, true)
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, { status: [UserStatuses.active] }, value)
      .then((data) => {
        setUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const linksIsRequired = useMemo(
    () => values?.operationType === BenefitOperationTypes.withdrawal,
    [values?.operationType]
  )

  const isFullValid = useMemo(
    () =>
      isValid &&
      (linksIsRequired
        ? values.links.length > 0 && urlArrayNullable(values.links)
        : true),
    [isValid, linksIsRequired, values.links]
  )

  useEffect(() => {
    setUserList(parseDataToEmployees(userData))
  }, [userData])

  useEffect(() => {
    setBenefitList(benefitData)
  }, [benefitData])

  useEffect(() => {
    setFilteredBenefitList(
      parseDataToBenefits(
        benefitList.filter(
          (benefit) => benefit.operationType === values.operationTypeData?.value
        )
      )
    )
  }, [benefitList])

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{translate.modals.create.title}</ST.ModalTitle>
          <ST.InputsBlock>
            {isAdminOrHR && (
              <ST.SelectWrapper>
                <AutocompleteInput
                  options={userList}
                  value={values.targetUser}
                  getOptionLabel={(opt) => opt.item}
                  label={translate.modals.create.employee.label}
                  placeholder={translate.modals.create.employee.placeholder}
                  onInputChange={debounce(handleInputChange, 1000)}
                  onChange={(value) => {
                    if (value) {
                      setFieldValue('targetUserId', value?.value)
                      setFieldValue('targetUser', value)
                    } else {
                      setFieldValue('targetUserId', null)
                      setFieldValue('targetUser', null)
                    }
                  }}
                  fullWidth
                  required
                />
              </ST.SelectWrapper>
            )}
            <ST.SelectWrapper>
              <BaseSelect
                isSmallSelect={false}
                placeHolder={translate.modals.create.type.placeholder}
                listItems={listOperationTypes}
                label={translate.modals.create.type.label}
                name={operationType}
                value={values?.operationTypeData?.item || ''}
                typeSelect={operationType}
                onChange={(newValue) => {
                  setFilteredBenefitList(
                    parseDataToBenefits(
                      benefitList.filter(
                        (benefit) => benefit.operationType === newValue?.value
                      )
                    )
                  )
                  setFieldValue(operationType, newValue?.value)
                  setFieldValue(selectsBenefitApplicationEnum.benefit, {
                    item: '',
                    value: 0,
                  })
                  setSelectedBenefit(null)
                  setFieldValue('benefitId', null)
                }}
                clickableElements={[modalRef.current]}
                required
              />
            </ST.SelectWrapper>
            <ST.SelectWrapper>
              <BaseSelect
                label={translate.modals.create.operationType.label}
                isSmallSelect={false}
                placeHolder={translate.modals.create.operationType.placeholder}
                listItems={filteredBenefitList}
                name={selectsBenefitApplicationEnum.benefit}
                value={selectedBenefit?.item || ''}
                typeSelect={selectsBenefitApplicationEnum.benefit}
                onChange={(newValue) => {
                  setSelectedBenefit(newValue)
                  setFieldValue('benefitId', newValue?.value ?? null)
                }}
                dropdownStyle={{ maxHeight: 250 }}
                clickableElements={[modalRef.current]}
                scrollTarget={modalRef.current}
                required
              />
            </ST.SelectWrapper>
            <ST.InputWrapper>
              <ST.Label>
                {translate.modals.create.cost}
                <ST.Star>*</ST.Star>
              </ST.Label>
              <NumberFormat
                customInput={ST.Input}
                thousandsGroupStyle="thousand"
                thousandSeparator=" "
                decimalScale={2}
                placeholder={CreateEmployeeEnum.emptyString}
                sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
                id={selectsBenefitApplicationEnum.value}
                name={selectsBenefitApplicationEnum.value}
                value={values.value === 0 ? '' : values.value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value.replaceAll(' ', '')
                  setFieldValue(selectsBenefitApplicationEnum.value, value)
                }}
              />
            </ST.InputWrapper>
            <ST.InputWrapper>
              <ST.Label>
                {translate.modals.create.expiration_date}
                <ST.Star>*</ST.Star>
              </ST.Label>
              <CalendarInput
                setStartDate={setCancellationDate}
                startDate={cancellationDate}
                minDate={new Date(Date.now())}
              />
            </ST.InputWrapper>

            <ST.InputWrapper>
              <ST.Label>
                {translate.modals.create.links.label}
                {linksIsRequired && <ST.Star>*</ST.Star>}
              </ST.Label>
              {values.links.map((link, index) => (
                <ST.InputWrapperWithDelete key={`links-${index}`}>
                  <ST.Input
                    type="text"
                    placeholder={Placeholders.url}
                    name={`links-${index}`}
                    value={link}
                    onBlur={handleBlur(`links[${index}]`)}
                    sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
                    onChange={(e) => {
                      changeLink(e.target.value, index)
                    }}
                  />
                  <ST.CloseWrapper>
                    <Close
                      onClick={() => {
                        removeLink(index)
                      }}
                    />
                  </ST.CloseWrapper>
                </ST.InputWrapperWithDelete>
              ))}
              {values.links.length > 0 &&
              Array.isArray(touched.links) &&
              touched.links.filter((link: boolean) => link).length > 0 &&
              !urlArrayNullable(values.links) ? (
                <ST.ErrorText style={{ marginBottom: '10px' }}>
                  {translate.modals.create.links.error}
                </ST.ErrorText>
              ) : (
                ''
              )}
              <ST.InputSmallWrapperButton>
                <ST.AddButton
                  disabled={
                    values.links.filter((link) => (link ?? '').length === 0)
                      .length > 0
                  }
                  onClick={() => addLink()}
                >
                  <Plus />
                  {translate.modals.create.links.add}
                </ST.AddButton>
              </ST.InputSmallWrapperButton>
            </ST.InputWrapper>

            <ST.InputWrapper>
              <ST.Label>{translate.modals.create.comment.label}</ST.Label>
              <ST.InputTextArea
                placeholder={translate.modals.create.comment.placeholder}
                sizeInput={CreateBenefitApplicationStrings.sizeInputBig}
                id={selectsBenefitApplicationEnum.commentary}
                name={selectsBenefitApplicationEnum.commentary}
                value={values.commentary}
                onChange={handleChange}
              />
            </ST.InputWrapper>
          </ST.InputsBlock>
          <ST.OutputBlock>
            <ST.ErrorText>
              {errors.value || errors.cancellationDate || errors.targetUserId
                ? errorText
                : ''}
            </ST.ErrorText>
          </ST.OutputBlock>
          <ST.ButtonWrapper>
            <BaseButton
              text={translate.modals.create.submit}
              type={ButtonTypes.submit}
              disabled={!isFullValid || isLoading}
              onClick={checkForm}
            />
          </ST.ButtonWrapper>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateBenefitApplication
