import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import * as ST from './styled'
import { SettingsSteps } from 'constants/settingsSteps'
import StaticHardwareTypesTabContent from './StaticHardwareTypesTabContent'
import { getHardwareTypes } from 'api/hardwareTypes'
import { HardwareTypes } from 'types/model/hardware'
import CreateHardwareType from 'components/dialogs/settings/CreateHardwareType'
import BaseButton from 'components/ui/buttons/BaseButton'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import Loader from 'components/ui/Loader'

interface IUserProps {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  step: SettingsSteps
}

const HardwareTypesTab: FC<IUserProps> = ({ isEditing }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_HARDWARE)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [hardwareTypes, setHardwareTypes] = useState<
    HardwareTypes.HardwareType[]
  >([])
  const [showModalCreateHardwareType, setShowModalCreateHardwareType] =
    useState<boolean>(false)

  const handleShowCreateHardwareType = useCallback((): void => {
    setShowModalCreateHardwareType((prev) => !prev)
  }, [])

  const updateHardwareTypes = () => {
    setIsLoading(true)
    getHardwareTypes()
      .then((resp) => setHardwareTypes(resp.data))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    updateHardwareTypes()
  }, [])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>{t('title')}</ST.EditingHeader>
          </ST.BackBlock>
          <BaseButton
            typeButton="primary"
            text={t('add')}
            onClick={handleShowCreateHardwareType}
            width="40%"
          />
        </ST.ManageBlock>
      )}
      {isLoading ? (
        <Loader marginTop={100} />
      ) : (
        <StaticHardwareTypesTabContent
          types={hardwareTypes}
          updateHardwareTypes={updateHardwareTypes}
        />
      )}
      {showModalCreateHardwareType && (
        <CreateHardwareType
          show={showModalCreateHardwareType}
          onClose={handleShowCreateHardwareType}
          showEndModal={updateHardwareTypes}
        />
      )}
    </ST.MainContainer>
  )
}

export default HardwareTypesTab
