import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { ReactComponent as Plus } from 'assets/icons/plusAddButton.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  handlerError,
  setToastError,
  setToastSuccess,
} from 'utils/handlerError'
import { RequiredFields } from 'constants/requiredFields'
import { FormikFields } from 'constants/formikFields'
import { InputSizes } from 'constants/inputSizes'
import { ButtonTypes } from 'constants/buttonTypes'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { createReview } from 'api/review'
import { getEmployees } from 'api/employees'
import { parseDataToEmployees } from 'utils/parseData'
import CalendarInput from 'components/ui/inputs/Calendar'
import moment, { Moment } from 'moment'
import debounce from 'lodash/debounce'
import { ReviewTypes } from 'types/model/review'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseCheckbox from 'components/ui/checkboxes/BaseCheckbox'
import { ReviewOperationTypes } from 'constants/reviewsOperationTypes'
import { UserStatuses } from 'constants/userStatuses'
import { UserTypes } from 'types/model/user'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { ReactComponent as DeleteButtonIcon } from 'assets/icons/Delete.svg'
import IconButton from 'components/ui/buttons/IconButton'
import { DATE_TIME_FORMAT_DTO, TIME_FORMAT } from 'constants/Date'
import TimeSelect from 'components/ui/inputs/TimeSelect'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { ProfileNS, ReviewsNS } from 'i18n/types'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'

const DEFAULT_EXPERT_VALUE: IItem = { item: '', value: 0 }

export enum ReviewRequirements {
  MINIMUM_EXPERTS = 2,
  MINIMUM_SURVEY_EXPERTS = 5,
}

export enum TaskStatusEnum {
  NOT_SUCCESS = 21,
  SUCCESS = 1,
}

export enum TaskTypeEnum {
  THIS_REVIEW = 0,
  NEXT_REVIEW = 1,
}

interface IRequestAccess {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<any>
  userId?: number
  roles?: string[]
  userData: UserTypes.Model[]
  review?: ReviewTypes.Model
  userInformation?: Nullable<UserTypes.UserInfo>
  disableUserSelect?: boolean
  forceUpdate?: Function
}

interface IForm {
  date: Moment | null
  dateEnd: Moment | null
  type: ReviewOperationTypes
  typeData: IItem
  task: string[]
  userData: IItem | null
  userSurvey: boolean
  expertSurvey: boolean
  experts: Nullable<IItem<number>>[]
  expertsForSurvey: Nullable<IItem<number>>[]
  user: number | null
}

const initialValues: IForm = {
  date: null,
  dateEnd: null,
  type: 0,
  typeData: { item: '', value: 0 },
  task: [''],
  userData: null,
  userSurvey: false,
  expertSurvey: false,
  experts: new Array(ReviewRequirements.MINIMUM_EXPERTS).fill(null),
  expertsForSurvey: new Array(ReviewRequirements.MINIMUM_SURVEY_EXPERTS).fill(
    null
  ),
  user: null,
}

enum Fields {
  date = 'date',
  type = 'type',
  typeData = 'typeData',
  task = 'task',
  userData = 'userData',
  userSurvey = 'userSurvey',
  experts = 'experts',
  expertSurvey = 'expertSurvey',
  expertsForSurvey = 'expertsForSurvey',
}

enum ToastErrors {
  USER_REQUIRED = 'Необходимо выбрать сотрудника',
  SURVEY_360_EXPERTS_REQUIRED = 'Для опроса 360 должно быть добавлено не менее 5 экспертов',
  TECH_REVIEW_EXPERTS_REQUIRED = 'Для технического ревью должно быть добавлено не менее 1 эксперта',
  REVIEW_EXPERTS_REQUIRED = 'Для ревью должно быть добавлено не менее 2 экспертов',
}

const MinExpertsCount = {
  EXPERT_SURVEY: 5,
  TECHNICAL_REVIEW: 1,
  GENERAL: 2,
}

const AddReview = ({
  show,
  onClose,
  showEndModal,
  userId,
  userData,
  review,
  userInformation,
  disableUserSelect = false,
  forceUpdate,
}: IRequestAccess) => {
  const { t } = useTypedTranslation(I18nNamespaces.REVIEWS)
  const { t: profileT } = useTypedTranslation(I18nNamespaces.PROFILE)
  const translate = t<ReviewsNS['modal']>('modal')

  const reviewTypes = useMemo(() => {
    const types = profileT<ProfileNS['reviews']>('reviews').types

    return Object.entries(types).map(([key, value]) => ({
      item: value,
      value: +key,
    }))
  }, [profileT])

  const [listEmployee, setListEmployee] = useState<Array<IItem>>([])
  const [filterUserList, setFilterUserList] = useState<Array<IItem>>([])
  const [filterUserListSurvey, setFilterUserListSurvey] = useState<
    Array<IItem>
  >([])
  const [userInfo, setUserInfo] = useState<
    Record<'fio' | 'department', string>
  >({
    fio: '',
    department: '',
  })

  const modalRef = useRef<ElementRef<'div'> | null>(null)

  useEffect(() => {
    if (userInformation?.id) {
      updateUserInfo(userInformation)
    }
  }, [userInformation])

  const updateUserInfo = (resp: UserTypes.UserInfo): void => {
    setUserInfo((prevState) => ({
      ...prevState,
      fio: `${resp.surname} ${resp.name} ${resp.patronymic}`,
      department: resp.department?.name ?? '',
    }))
  }

  useEffect(() => {
    if (!!userData?.length) {
      setListEmployee(parseDataToEmployees(userData))
      setFilterUserList(parseDataToEmployees(userData))
      setFilterUserListSurvey(parseDataToEmployees(userData))
    }
  }, [userData])

  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)

  const {
    values,
    errors,
    isValid,
    setFieldValue,
    resetForm,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik<IForm>({
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      ...initialValues,
      user: userId || null,
    },
    onSubmit: (_values) => {
      setBtnDisabled(true)
      values.user &&
        createReview({
          ...values,
          date: moment(_values.date).format(DATE_TIME_FORMAT_DTO),
          dateEnd: moment(_values.dateEnd).format(DATE_TIME_FORMAT_DTO),

          type: +_values.typeData.value,
          tasks: _values.task
            .filter((task) => !!task.length)
            .map((task) => ({
              type: TaskTypeEnum.THIS_REVIEW,
              text: task,
              status: TaskStatusEnum.NOT_SUCCESS,
            })),
          user: _values.user!,
          experts: _values.experts
            .map((expert) => expert!.value)
            .filter((expert) => !!expert && expert !== +(_values.user ?? 0)),
          expertsForSurvey: _values.expertSurvey
            ? _values.expertsForSurvey
                .map((expert) => expert!.value)
                .filter((expert) => !!expert && expert !== +(_values.user ?? 0))
            : [],
        })
          .then(() => {
            setToastSuccess('Ревью успешно сохранено')
            setBtnDisabled(false)
            userId && showEndModal?.(userId)
            if (forceUpdate) {
              forceUpdate()
            }
            resetForm()
            handleClose()
          })
          .catch((e) => {
            setBtnDisabled(false)
            handlerError(e)
          })
    },
    validationSchema: Yup.object().shape({
      date: Yup.mixed().nullable().required(RequiredFields.base),
      dateEnd: Yup.mixed().nullable().required(RequiredFields.base),
      type: Yup.number().required(RequiredFields.base),
      userSurvey: Yup.boolean(),
    }),
  })

  const handleClose = (): void => {
    resetForm()
    onClose(!show)
  }

  const updateTaskList = () => {
    setFieldValue(`task`, ['', ...values.task])
  }

  const updateExpertsList = () => {
    const experts = [...values.experts]
    experts.push({ item: '', value: 0 })

    setFieldValue(`experts`, experts)
  }

  const updateExpertsForSurveyList = () => {
    const experts = [...values.expertsForSurvey]
    experts.push({ item: '', value: 0 })

    setFieldValue(`expertsForSurvey`, experts)
  }

  const checkForm = () => {
    if (!values.user) return setToastError(ToastErrors.USER_REQUIRED)
    if (
      values.expertSurvey &&
      values.expertsForSurvey.filter((expert) => expert).length <
        MinExpertsCount.EXPERT_SURVEY
    ) {
      return setToastError(ToastErrors.SURVEY_360_EXPERTS_REQUIRED)
    }
    if (
      values.type === ReviewOperationTypes.technical &&
      values.experts.filter((expert) => expert).length <
        MinExpertsCount.TECHNICAL_REVIEW
    ) {
      return setToastError(ToastErrors.TECH_REVIEW_EXPERTS_REQUIRED)
    }
    if (
      values.type !== ReviewOperationTypes.technical &&
      values.experts.filter((expert) => expert).length < MinExpertsCount.GENERAL
    ) {
      return setToastError(ToastErrors.REVIEW_EXPERTS_REQUIRED)
    }

    return isValid ? handleSubmit() : handlerError(errors)
  }

  const handleInputEmployeeChange = (value: string) => {
    getEmployees(1, { status: [UserStatuses.active], isActive: true }, value)
      .then((data) => {
        setListEmployee(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, { status: [1], isActive: true }, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const handleInputSurveyChange = (value: string) => {
    getEmployees(1, { status: [UserStatuses.active], isActive: true }, value)
      .then((data) => {
        setFilterUserListSurvey(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const isRequiredValid = useMemo(
    () =>
      !!(
        values.user &&
        values.date &&
        values.dateEnd &&
        values.type &&
        values.experts.filter((expert) => expert?.value && expert?.value !== 0)
          ?.length >= MinExpertsCount.GENERAL &&
        (values.expertSurvey
          ? values.expertsForSurvey.filter(
              (expert) => expert?.value && expert?.value !== 0
            )?.length >= MinExpertsCount.EXPERT_SURVEY
          : true)
      ),
    [
      values.user,
      values.date,
      values.type,
      values.experts,
      values.expertSurvey,
      values.expertsForSurvey,
      values.dateEnd,
    ]
  )

  return (
    <ST.ModalOverlay isOpen={show}>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {show ? (
          <>
            {btnDisabled && (
              <ModalLoader height={modalRef.current?.scrollHeight} />
            )}
            <ST.ModalContent btnDisabled={btnDisabled}>
              <ST.Close onClick={handleClose}>
                <Close />
              </ST.Close>
              <ST.ModalTitle>{translate.title}</ST.ModalTitle>
              {!disableUserSelect ? (
                <AutocompleteInput
                  options={listEmployee}
                  value={values?.userData}
                  placeholder={translate.employee.placeholder}
                  label={translate.employee.label}
                  onInputChange={debounce(handleInputChange, 1000)}
                  onChange={(value) => {
                    if (value) {
                      setFieldValue('user', value?.value)
                      setFieldValue('userData', value)
                    } else {
                      setFieldValue('user', null)
                      setFieldValue('userData', null)
                    }
                  }}
                  getOptionLabel={(opt) => opt.item}
                  fullWidth
                  required
                />
              ) : (
                <ST.TextWrapper>
                  <ST.Label>
                    <ST.LabelName>{translate.employee.label}:</ST.LabelName>
                    <ST.LabelValue>
                      {userInfo
                        ? userInfo.fio
                        : `${review?.user.surname + ' ' + review?.user.name}`}
                    </ST.LabelValue>
                  </ST.Label>
                </ST.TextWrapper>
              )}

              <ST.InputsBlock>
                <ST.InputsRow>
                  <ST.InputWrapper>
                    <ST.LabelWrapper>
                      <ST.Label>
                        {translate.date}
                        <ST.Star>*</ST.Star>
                      </ST.Label>
                      <ST.ErrorText>{errors.date}</ST.ErrorText>
                    </ST.LabelWrapper>
                    <CalendarInput
                      startDate={values.date?.toDate() ?? new Date()}
                      value={values.date}
                      onChange={(newDate) => {
                        const value = newDate
                          ? typeof newDate === 'string'
                            ? new Date(newDate)
                            : newDate instanceof Date
                            ? newDate
                            : newDate.toDate()
                          : null

                        const newDateValue = moment(value)

                        setFieldValue(
                          'date',
                          moment(newDateValue).set({
                            hour: values.date?.get('hour'),
                            minute: values.date?.get('minute'),
                          })
                        )
                        setFieldValue(
                          'dateEnd',
                          moment(newDateValue).set({
                            hour: values.dateEnd?.get('hour'),
                            minute: values.dateEnd?.get('minute'),
                          })
                        )
                      }}
                    />
                  </ST.InputWrapper>
                  <TimeSelect
                    dateValue={values.date ?? undefined}
                    label={translate.date_start}
                    onChange={(val) => {
                      if (val?.value) {
                        setFieldValue(
                          'date',
                          (values.date ? moment(values.date) : moment()).set({
                            hour: val?.value.get('hour'),
                            minute: val?.value.get('minute'),
                          })
                        )
                      }
                    }}
                    clickableElements={[modalRef.current]}
                    scrollTarget={modalRef.current}
                    dropdownStyle={{ top: 70 }}
                    required
                  />
                  <TimeSelect
                    dateValue={values.dateEnd ?? undefined}
                    label={translate.date_end}
                    onChange={(val) => {
                      if (val?.value) {
                        setFieldValue(
                          'dateEnd',
                          (values.dateEnd
                            ? moment(values.dateEnd)
                            : moment()
                          ).set({
                            hour: val?.value.get('hour'),
                            minute: val?.value.get('minute'),
                          })
                        )
                      }
                    }}
                    clickableElements={[modalRef.current]}
                    scrollTarget={modalRef.current}
                    disableBefore={values.date?.format(TIME_FORMAT)}
                    dropdownStyle={{ top: 70 }}
                    required
                  />
                </ST.InputsRow>

                <ST.InputWrapper>
                  <BaseSelect
                    value={values?.typeData?.item || ''}
                    isSmallSelect={false}
                    label={translate.type.label}
                    placeHolder={translate.type.placeholder}
                    listItems={reviewTypes}
                    name={translate.type.placeholder}
                    typeSelect={FormikFields.reviewType}
                    passValue={(_, value) => {
                      setFieldValue('typeData', {
                        item: reviewTypes.find((e) => e.value === value)?.item,
                        value: value,
                      })
                      setFieldValue('type', value)
                    }}
                    clickableElements={[modalRef.current]}
                    required
                  />
                </ST.InputWrapper>
                <ST.InputWrapper>
                  <ST.LabelWrapper>
                    <ST.Label>{translate.tasks.label}</ST.Label>
                    <ST.ErrorText>{errors.task}</ST.ErrorText>
                  </ST.LabelWrapper>
                  <ST.ListWrapper>
                    {values.task.map((taskValue, index, array) => (
                      <ST.InputWrapperWithDelete key={`task-${index}`}>
                        <ST.Input
                          key={index}
                          type="text"
                          placeholder={translate.tasks.placeholder}
                          sizeInput={InputSizes.big}
                          name={`task[${index}]`}
                          value={taskValue}
                          onChange={(e) => {
                            const tasks = [...values.task]
                            tasks[index] = e.target?.value
                            setFieldValue('task', tasks)
                          }}
                        />
                        <IconButton
                          icon={DeleteButtonIcon}
                          disabled={array.length === 1}
                          onClick={() =>
                            array.length > 1 &&
                            setFieldValue(
                              'task',
                              values.task.filter((_, i) => i !== index)
                            )
                          }
                        />
                      </ST.InputWrapperWithDelete>
                    ))}
                  </ST.ListWrapper>

                  <ST.AddButton onClick={updateTaskList}>
                    <Plus />
                    {translate.add_task}
                  </ST.AddButton>
                </ST.InputWrapper>
                <ST.InputWrapper style={{ marginTop: 6 }}>
                  {values.experts?.map((expertsValue, index, array) => (
                    <ST.ExpertWrapper key={`expert-${index}`}>
                      <ST.ExpertInputWrapper>
                        <AutocompleteInput
                          options={filterUserList.filter(
                            (user) =>
                              !values.experts
                                .map((expert) => expert?.value)
                                .includes(+user.value) &&
                              user.value !== values.user
                          )}
                          label={translate.interviewers}
                          onInputChange={debounce(
                            handleInputEmployeeChange,
                            1000
                          )}
                          onChange={(value) => {
                            if (value) {
                              const newValue = array.map((exp, i) =>
                                i === index ? value : exp
                              )

                              setFieldValue('experts', newValue)
                            } else {
                              const newValue = array.map((exp, i) =>
                                i === index ? DEFAULT_EXPERT_VALUE : exp
                              )
                              setFieldValue('experts', newValue)
                            }
                          }}
                          getOptionLabel={(opt) => opt.item}
                          value={expertsValue}
                          fullWidth
                          required
                        />
                      </ST.ExpertInputWrapper>
                      <IconButton
                        icon={DeleteButtonIcon}
                        disabled={
                          array.length <= ReviewRequirements.MINIMUM_EXPERTS
                        }
                        onClick={() => {
                          if (
                            array.length > ReviewRequirements.MINIMUM_EXPERTS
                          ) {
                            setFieldValue(
                              'experts',
                              values.experts.filter((_, i) => i !== index)
                            )
                          }
                        }}
                      />
                    </ST.ExpertWrapper>
                  ))}

                  <ST.AddButton
                    onClick={updateExpertsList}
                    disabled={
                      !!values.experts.length &&
                      values.experts.some((e) => e?.value === 0)
                    }
                    style={{ marginTop: 10 }}
                  >
                    <Plus />
                    {translate.add_expert}
                  </ST.AddButton>
                </ST.InputWrapper>
                <ST.InputWrapper style={{ marginTop: 6 }}>
                  <ST.LabelWrapper>
                    <ST.Label>{translate.surveys}:</ST.Label>
                  </ST.LabelWrapper>
                  <ST.PollsWrapper>
                    <ST.Styled>
                      <BaseCheckbox
                        label={translate.user_survey}
                        id={Fields.userSurvey}
                        name={Fields.userSurvey}
                        checked={values.userSurvey}
                        onChange={handleChange}
                      />
                    </ST.Styled>
                    <ST.Styled>
                      <BaseCheckbox
                        label={translate.survey360}
                        id={Fields.expertSurvey}
                        name={Fields.expertSurvey}
                        checked={values.expertSurvey}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </ST.Styled>
                  </ST.PollsWrapper>
                </ST.InputWrapper>
                <ST.InputWrapper>
                  <ST.GeneralEmployeesWrapper isOpen={values.expertSurvey}>
                    {values.expertsForSurvey?.map(
                      (expertsForSurveyValue, index, array) => (
                        <ST.ExpertWrapper key={`expert-${index}`}>
                          <ST.ExpertInputWrapper>
                            <AutocompleteInput
                              options={filterUserListSurvey.filter(
                                (user) =>
                                  !values.expertsForSurvey
                                    .map((expert) => expert?.value)
                                    .includes(+user.value) &&
                                  user.value !== values.user
                              )}
                              value={expertsForSurveyValue}
                              label={translate.survey360_experts}
                              onInputChange={debounce(
                                handleInputSurveyChange,
                                1000
                              )}
                              onChange={(value) => {
                                if (value) {
                                  const newValue = array.map((exp, i) =>
                                    i === index ? value : exp
                                  )
                                  setFieldValue('expertsForSurvey', newValue)
                                } else {
                                  const newValue = array.map((exp, i) =>
                                    i === index ? DEFAULT_EXPERT_VALUE : exp
                                  )
                                  setFieldValue('expertsForSurvey', newValue)
                                }
                              }}
                              getOptionLabel={(opt) => opt.item}
                              fullWidth
                              required
                            />
                          </ST.ExpertInputWrapper>
                          <IconButton
                            icon={DeleteButtonIcon}
                            disabled={
                              array.length <=
                              ReviewRequirements.MINIMUM_SURVEY_EXPERTS
                            }
                            onClick={() => {
                              array.length >
                                ReviewRequirements.MINIMUM_SURVEY_EXPERTS &&
                                setFieldValue(
                                  'expertsForSurvey',
                                  array.filter((_, i) => i !== index)
                                )
                            }}
                          />
                        </ST.ExpertWrapper>
                      )
                    )}
                    <ST.AddButton
                      onClick={updateExpertsForSurveyList}
                      disabled={
                        !!values.expertsForSurvey.length &&
                        values.expertsForSurvey.some((e) => e?.value === 0)
                      }
                      style={{ marginTop: 10 }}
                    >
                      <Plus />
                      {translate.add_expert}
                    </ST.AddButton>
                  </ST.GeneralEmployeesWrapper>
                </ST.InputWrapper>
              </ST.InputsBlock>
              <ST.ButtonsWrapper>
                <BaseButton
                  text={translate.save}
                  typeButton={ButtonTypes.primary}
                  onClick={checkForm}
                  disabled={btnDisabled || !isRequiredValid}
                />
              </ST.ButtonsWrapper>
            </ST.ModalContent>
          </>
        ) : (
          <ModalLoader height={modalRef.current?.scrollHeight} />
        )}
      </ST.Modal>
    </ST.ModalOverlay>
  )
}

export default AddReview
