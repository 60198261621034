import React, { ElementRef, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { handlerError } from 'utils/handlerError'
import { FormikFields } from 'constants/formikFields'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { editHardwareType } from 'api/hardwareTypes'
import { HardwareTypes } from 'types/model/hardware'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import BaseInput from 'components/ui/inputs/BaseInput'

interface IDeniedAccess {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  hardwareType: HardwareTypes.HardwareType
}

const EditDocTemplate = ({
  show,
  onClose,
  showEndModal,
  hardwareType,
}: IDeniedAccess) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_HARDWARE,
    I18nNamespaces.TRANSLATION
  )
  const { RequiredFields } = useRequiredFields()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modalRef = useRef<ElementRef<'div'>>(null)
  const handleClose = (): void => {
    onClose(!show)
  }

  const { handleSubmit, handleChange, values, errors, isValid } = useFormik({
    initialValues: { ...hardwareType },
    onSubmit: () => {
      setIsLoading(true)
      editHardwareType(hardwareType.id, values)
        .then(() => {
          showEndModal?.(true)
          handleClose()
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().trim().required(RequiredFields.base),
    }),
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{t('edit_title')}</ST.ModalTitle>
          <BaseInput
            label={t('type')}
            placeholder={t('type')}
            id={FormikFields.name}
            name={FormikFields.name}
            value={values.name}
            onChange={handleChange}
            required
          />
          <ST.Button disabled={!isValid} onClick={checkForm}>
            {t('translation:save')}
          </ST.Button>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default EditDocTemplate
