import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { logout } from 'api/auth'
import { useNavigate } from 'react-router-dom'
import { PATHS } from 'constants/paths'
import { useDispatch } from 'react-redux'
import { AuthActionCreators } from 'store/actions/auth'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { MenusNS } from 'i18n/types'

interface IExitDialog {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
}

const ExitDialog = ({ show, onClose }: IExitDialog) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTypedTranslation(I18nNamespaces.MENU)

  const translate = t<MenusNS['logout']>('logout')

  const handleClose = (): void => {
    onClose(!show)
  }

  const handleLogout = () => {
    logout().then(() => {
      navigate(PATHS.auth)
      dispatch(AuthActionCreators.logout())
    })
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{translate.title}</ST.ModalTitle>
          <ST.ButtonsWrapper>
            <ST.ExitButton onClick={handleLogout}>
              {translate.button}
            </ST.ExitButton>
            <ST.CancelButton onClick={handleClose}>
              {translate.cancel}
            </ST.CancelButton>
          </ST.ButtonsWrapper>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default ExitDialog
