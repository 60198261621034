import { SystemAction, SystemActionTypes, SystemMode } from 'types/SystemType'
import { Dispatch } from 'redux'
import { SupportedLocales } from 'i18n/config'

export namespace SystemActionCreators {
  type ActionFn = (dispatch: Dispatch<SystemAction>) => void

  export const setMode =
    (value: SystemMode): ActionFn =>
    (dispatch) => {
      dispatch({
        type: SystemActionTypes.SET_MODE,
        payload: value,
      })
    }

  export const setUserLocale =
    (value: SupportedLocales): ActionFn =>
    (dispatch) => {
      dispatch({
        type: SystemActionTypes.SET_USER_LOCALE,
        payload: value,
      })
    }

  export const setSystemLocale =
    (value: SupportedLocales): ActionFn =>
    (dispatch) => {
      dispatch({
        type: SystemActionTypes.SET_SYSTEM_LOCALE,
        payload: value,
      })
    }
}
