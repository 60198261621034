import React from 'react'
import * as ST from './styled'
import { ReactComponent as UnderConsiderationIcon } from 'assets/icons/Applications/statuses/under-consideration.svg'
import { ReactComponent as RejectedIcon } from 'assets/icons/Applications/statuses/rejected.svg'
import { ReactComponent as ClosedIcon } from 'assets/icons/Applications/statuses/closed.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/Applications/statuses/approved.svg'
import {
  BenefitApplicationStatuses,
  BenefitApplicationStatusesDescription,
} from 'constants/benefitApplicationStatuses'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { BenefitsNS } from 'i18n/types'

interface Props {
  status: BenefitApplicationStatuses
}

const StatusBlock: FC<Props> = ({ status }) => {
  const { t } = useTypedTranslation(I18nNamespaces.BENEFITS)
  const icons = {
    [BenefitApplicationStatuses.underConsideration]: () => (
      <UnderConsiderationIcon />
    ),
    [BenefitApplicationStatuses.rejected]: () => <RejectedIcon />,
    [BenefitApplicationStatuses.approved]: () => <ApprovedIcon />,
    [BenefitApplicationStatuses.cancel]: () => <ClosedIcon />,
  }

  const labels = t<BenefitsNS['statuses']>('statuses')

  return (
    <ST.StatusBlock>
      {icons[status]()}
      <ST.Label>
        {labels[String(status) as keyof BenefitsNS['statuses']]}
      </ST.Label>
    </ST.StatusBlock>
  )
}

export default StatusBlock
