import axios from 'axios'
import { SupportedLocales } from 'i18n/config'
import { apiInstances } from 'api'

export const I18N_LOCALSTORAGE_URL_KEY = 'i18n:baseUrl'

const updateAxiosBaseUrl = (locale: SupportedLocales) => {
  const baseUrl = axios.defaults.baseURL

  if (baseUrl) {
    const newUrl =
      baseUrl.endsWith('api') || baseUrl.endsWith('api/')
        ? `${baseUrl}/${locale}`
        : baseUrl.replace(/(ru|en)/, locale)

    const instances = [...apiInstances, axios]

    instances.forEach((instance) => (instance.defaults.baseURL = newUrl))
    localStorage.setItem(I18N_LOCALSTORAGE_URL_KEY, newUrl)
  }
}

export default updateAxiosBaseUrl
