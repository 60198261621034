import React from 'react'
import * as ST from './styled'
import { Link } from 'react-router-dom'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  position: string
  grade: string
  salaryMax: number
  id: number
}

const UserLine: FC<Props> = ({ position, grade, salaryMax, id }) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.VACANCIES,
    I18nNamespaces.TRANSLATION
  )

  return (
    <Link to={`/vacancies/${id}`} target={'_blank'}>
      <ST.UserLine>
        <ST.Label>{position}, </ST.Label>
        <ST.Label>{grade}</ST.Label>
        <ST.Position>
          ({t('to', { to: salaryMax })} {t('translation:rub')})
        </ST.Position>
      </ST.UserLine>
    </Link>
  )
}

export default UserLine
