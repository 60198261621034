import {
  HiringType,
  HiringTypeActive,
  HiringTypeExpense,
} from 'types/model/hiringType'
import { apiInstance } from 'api/index'

export type HiringTypePost = {
  name: string
  expenses: Omit<HiringTypeExpense, 'id'>[]
}
export const getHiringTypes = async (): Promise<HiringType[]> =>
  apiInstance.get('hiringType').then((res) => res.data)

export const getActiveHiringTypes = async (): Promise<HiringTypeActive[]> =>
  apiInstance.get('hiringType/active').then((res) => res.data)

export const getHiringTypeById = async (id: number): Promise<HiringType> =>
  apiInstance.get(`hiringType/${id}`).then((res) => res.data)

export const postHiringType = async (
  body: HiringTypePost
): Promise<HiringType> =>
  apiInstance.post('hiringType', body).then((res) => res.data)

export const patchHiringType = async (
  id: number,
  body: HiringTypePost
): Promise<HiringType> =>
  apiInstance.patch(`hiringType/${id}`, body).then((res) => res.data)

export const archiveHiringType = async (id: number) =>
  apiInstance.patch(`hiringType/${id}/archive`)
