import * as ST from './styled'
import React from 'react'
import { CSSProperties } from 'styled-components'

interface Props {
  style?: CSSProperties
  children?: any
}

const Body: FC<Props> = ({ style, children }) => (
  <ST.Body style={style}>
    <ST.Content>{children}</ST.Content>
  </ST.Body>
)

export default Body
