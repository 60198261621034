import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { ModalTitles } from 'constants/modalTitles'
import { ButtonLabels } from 'constants/buttonLabels'
import { handlerError } from 'utils/handlerError'
import { Placeholders } from 'constants/placeholders'
import { FormikFields } from 'constants/formikFields'
import { useFormik } from 'formik'
import { changeDocumentTemplate } from 'api/settings'
import AttachFile, { IFileItem } from 'components/ui/inputs/AttachFile'
import { IDocumentTemplate } from 'types/model/documentTemplateType'
import * as Yup from 'yup'
import { FileTypes } from 'constants/fileTypes'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { postFile } from 'api/employment'
import getFileData from 'utils/file/getFileData'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseInput from 'components/ui/inputs/BaseInput'
import BaseCheckbox from 'components/ui/checkboxes/BaseCheckbox'
import { MessagesNS, SettingsLocales } from 'i18n/types'

interface IDeniedAccess {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  docTemplate: IDocumentTemplate
}

interface IForm {
  id: number
  name: string
  isPublished: boolean
  isRequired: boolean
  file: Nullable<IFileItem>
  fileDto: File | null
}

const EditDocTemplate = ({
  show,
  onClose,
  showEndModal,
  docTemplate,
}: IDeniedAccess) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_DOCUMENTS,
    I18nNamespaces.MESSAGES
  )

  const translateModal =
    t<SettingsLocales.Documents['edit_modal']>('edit_modal')

  const RequiredFields = t<MessagesNS['required_fields']>(
    'info_messages:required_fields'
  )

  const handleClose = (): void => {
    onClose(!show)
  }

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const modalRef = useRef<ElementRef<'div'>>(null)

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik<IForm>({
      initialValues: docTemplate
        ? {
            ...docTemplate,
            file: docTemplate.files?.[0],
            fileDto: null,
          }
        : {
            id: 0,
            name: '',
            isPublished: false,
            isRequired: false,
            file: null as IFileItem | null,
            fileDto: null,
          },
      onSubmit: (vals) => {
        const file = vals.file
        if (!file) return

        setIsLoading(true)
        if (vals.fileDto) {
          postFile(vals.fileDto, file.name ?? undefined).then((res) => {
            const fileId = res.payload?.id

            changeDocumentTemplate(values.id, {
              ...values,
              files: fileId ? [fileId] : [],
            })
              .then(() => {
                showEndModal?.(true)
                handleClose()
              })
              .finally(() => {
                setIsLoading(false)
              })
          })
        } else {
          changeDocumentTemplate(values.id, {
            ...values,
            files: docTemplate.files.map((f) => f.id),
          })
            .then(() => {
              showEndModal?.(true)
              handleClose()
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      },
      validationSchema: Yup.object().shape({
        name: Yup.string().trim().required(RequiredFields.base),
        isPublished: Yup.boolean().required(RequiredFields.base),
        isRequired: Yup.boolean().required(RequiredFields.base),
      }),
      validateOnMount: true,
    })

  const onDocChange = (details: IFileItem | null, file?: File) => {
    if (!!details?.id) {
      setFieldValue('file', details)
    }

    if (file) {
      const reader = new FileReader()
      reader.onload = () => {}
      reader.readAsDataURL(file)

      const fileData: IForm['file'] = {
        id: null,
        name: file.name,
      }

      setFieldValue('file', fileData)
      setFieldValue('fileDto', file)
    }
  }

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  useEffect(() => {
    setSubmitBtnDisabled(!isValid || !values.file)
  }, [isValid, values.file])

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{translateModal.title}</ST.ModalTitle>
          <BaseInput
            placeholder={Placeholders.docName}
            id={FormikFields.name}
            name={FormikFields.name}
            value={values.name}
            onChange={handleChange}
            label={t('name')}
            required
          />
          <ST.LabelWrapper>
            <ST.Label>{translateModal.checkboxes}</ST.Label>
          </ST.LabelWrapper>
          <ST.CheckboxInputWrapper>
            <BaseCheckbox
              id={'isPublished'}
              name={'isPublished'}
              checked={values.isPublished}
              onChange={handleChange}
              label={t('published')}
            />
            <BaseCheckbox
              id={'isRequired'}
              name={'isRequired'}
              checked={values.isRequired}
              onChange={handleChange}
              label={t('required')}
            />
          </ST.CheckboxInputWrapper>
          <ST.InputWrapper>
            <ST.LabelWrapper>
              <ST.Label>
                {translateModal.document}
                <ST.Star>*</ST.Star>
                {!values.file && (
                  <ST.ErrorText>{RequiredFields.base}</ST.ErrorText>
                )}
              </ST.Label>
            </ST.LabelWrapper>
            <AttachFile
              label={translateModal.load_document}
              replacement={!!values.file}
              replacementLabel={translateModal.load_document_replace}
              onChange={onDocChange}
              accept={FileTypes.documents}
              onRemove={() => {
                setFieldValue('file', null)
                setFieldValue('fileData', undefined)
                setFieldValue('fileDto', null)
              }}
              files={
                values.file
                  ? [
                      {
                        id: values.file.id,
                        name:
                          values.file.name ?? getFileData(values.file) ?? null,
                      },
                    ]
                  : []
              }
              getPathAsName
              noPreview
            />
          </ST.InputWrapper>
          <ST.Button
            onClick={checkForm}
            disabled={submitBtnDisabled}
            isDisabled={submitBtnDisabled}
            type={'submit'}
          >
            {translateModal.submit}
          </ST.Button>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default EditDocTemplate
