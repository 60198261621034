import React, { useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { IItem } from 'components/ui/BaseSelect'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError } from 'utils/handlerError'
import { createDepartment } from 'api/department'
import { getEmployees } from 'api/employees'
import { getPositions } from 'api/position'
import {
  parseDataToDynamicPositions,
  parseDataToEmployees,
} from 'utils/parseData'
import PositionInput from 'components/settings/Tabs/Department/EditDepartment/PositionInput'
import { IDynamicPosition } from 'components/settings/Tabs/Department/EditDepartment'
import { ButtonTypes } from 'constants/buttonTypes'
import BaseButton from 'components/ui/buttons/BaseButton'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import BaseInput from 'components/ui/inputs/BaseInput'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { SettingsLocales } from 'i18n/types'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'
import DashedButton from 'components/ui/buttons/DashedButton'
import debounce from 'lodash/debounce'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
  updateDepartments?: () => void
}

interface ICreateDirector {
  value: number
  item: string
}

interface ICreateDepartment {
  departmentName: string | null
  director: ICreateDirector | null
  positions: number[]
}

const inputName = 'departmentName'
const directorInputName = 'director'

const CreateDepartment = ({
  show,
  onClose,
  showEndModal,
  updateDepartments,
}: Props) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_DEPARTMENTS)
  const { RequiredFields } = useRequiredFields()
  const modalTranslate =
    t<SettingsLocales.Departments['create_modal']>('create_modal')

  const defaultPositionValue = {
    value: `new-0`,
    item: '',
    new: true,
  }

  const [allPositions, setAllPositions] = useState<IDynamicPosition[]>([])
  const [positions, setPositions] = useState<IDynamicPosition[]>([
    defaultPositionValue,
  ])
  const [newPositionCount, setNewPositionCount] = useState<number>(1)
  const [filterUserList, setFilterUserList] = useState<Array<IItem>>([])
  const [btnDisabled, setBtnDisabled] = useState<boolean>(false)

  const [tick, setTick] = useState(1)

  const updateTick = () => {
    setTick(tick + 1)
  }

  const fetchLists = async () => {
    await getEmployees()
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((err) => handlerError(err))
    await getPositions()
      .then((data) => setAllPositions(parseDataToDynamicPositions(data)))
      .catch((err) => handlerError(err))
  }

  const handleInputChange = (value: string) => {
    getEmployees(1, {}, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  const {
    handleChange,
    values,
    handleSubmit,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik<ICreateDepartment>({
    initialValues: {
      departmentName: null,
      director: null,
      positions: [],
    },
    onSubmit: (data) => {
      const newPositions = positions
        .map((item) => String(item.value))
        .filter((item) => item && !item.includes('new'))

      createDepartment(
        values.departmentName ?? '',
        data.director?.value ?? 0,
        newPositions
      )
        .then(() => {
          updateDepartments?.()
        })
        .catch((err) => handlerError(err))
      handleClose()
      resetFullForm()

      showEndModal?.(true)
    },
    validationSchema: Yup.object().shape({
      departmentName: Yup.string().required(RequiredFields.base),
      director: Yup.object().required(RequiredFields.base),
    }),
  })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const resetFullForm = () => {
    setPositions([])
    resetForm()
  }

  const handleClose = (): void => {
    onClose(!show)
  }

  useEffect(() => {
    fetchLists().then()
  }, [])

  const isValid = useMemo(
    () =>
      values.departmentName &&
      values.director?.value &&
      values.director?.value !== -1 &&
      positions.length &&
      positions.filter((p) => p.item.length !== 0).length > 0,
    [values.director?.value, values.departmentName, positions, tick]
  )

  useEffect(() => {
    setBtnDisabled(!isValid)
  }, [isValid])

  const removePosition = (indexToRemove: number) => {
    const position = positions[indexToRemove]

    if (position.new) {
      setPositions(positions.filter((_, index) => index !== indexToRemove))
    }
  }

  const createPosition = () => {
    setPositions([
      ...positions,
      {
        value: `new-${newPositionCount}`,
        item: '',
        new: true,
      },
    ])
    setNewPositionCount((prevState) => prevState + 1)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close
            onClick={() => {
              handleClose()
              resetFullForm()
            }}
          >
            <Close />
          </ST.Close>
          <ST.ModalTitle>{modalTranslate.title}</ST.ModalTitle>
          <ST.InputsBlock>
            <BaseInput
              id={inputName}
              name={inputName}
              value={values.departmentName ?? ''}
              onChange={handleChange}
              label={modalTranslate.name}
              required
            />
            <AutocompleteInput
              label={modalTranslate.boss}
              options={filterUserList}
              value={values.director}
              getOptionLabel={(option) => option.item}
              onChange={(value) => {
                if (value) {
                  setFieldValue(directorInputName, value)
                } else {
                  setFieldValue(directorInputName, { item: '', value: -1 })
                }
              }}
              onInputChange={debounce(handleInputChange, 1000)}
              containerStyle={{ width: '100%' }}
              inputContainerStyle={{ paddingRight: 0 }}
              required
            />
            <ST.PositionsBlock>
              <ST.Label>
                {modalTranslate.position}
                <ST.Star>*</ST.Star>
              </ST.Label>
              <ST.Positions>
                {positions.map((item, index, array) => (
                  <ST.PositionWrapper key={String(item.value)}>
                    <PositionInput
                      value={item}
                      placeholder={modalTranslate.position_placeholder}
                      listItems={allPositions}
                      selectedItems={positions}
                      disabledDelete={array.length === 1}
                      onChange={(newValue) => {
                        const newPositions = positions
                        if (newValue) {
                          newPositions[index] = newValue
                        } else {
                          newPositions[index] = defaultPositionValue
                        }
                        setPositions(newPositions)
                        updateTick()
                      }}
                      onRemove={() => removePosition(index)}
                    />
                  </ST.PositionWrapper>
                ))}
              </ST.Positions>
            </ST.PositionsBlock>
            <DashedButton
              style={{ width: 'max-content' }}
              onClick={createPosition}
            >
              <ST.Plus /> {modalTranslate.add_position}
            </DashedButton>
          </ST.InputsBlock>
          <ST.OutputBlock>
            <ST.ErrorText>
              {errors.departmentName ||
              errors.director ||
              values.director?.value === -1
                ? RequiredFields.base2
                : ''}
            </ST.ErrorText>
          </ST.OutputBlock>
          <ST.CreateButton>
            <BaseButton
              type={'submit'}
              text={modalTranslate.submit}
              typeButton={ButtonTypes.primary}
              onClick={checkForm}
              fakeDisabled={btnDisabled}
            />
          </ST.CreateButton>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateDepartment
