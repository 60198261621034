import styled from 'styled-components'
import { Label as InputLabel } from 'components/ui/inputs/BaseInput/styled'
import { Tags } from 'styles/Tags'

export namespace TinyMceStyles {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  `

  export const Label = styled(InputLabel)``

  export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
  `

  export const VariableValue = styled(Tags.P2)``
}
