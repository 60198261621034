import { apiInstance } from 'api'
import {
  HardwareTypes,
  ICreateHardwareResponse,
  IHardwareEdit,
  IHardwareRequest,
  IHardwareValuesFilter,
} from 'types/model/hardware'

import { ExtendedResponseType } from 'types/utils'

export const getHardwares = async (
  page?: number,
  parameters?: IHardwareValuesFilter
): Promise<ExtendedResponseType<HardwareTypes.Model>> => {
  const pageSize: number = 50
  let pageNumber: number
  if (page) {
    pageNumber = page
  } else {
    pageNumber = 1
  }
  const params = new URLSearchParams()
  params.append('pageSize', String(pageSize))
  params.append('pageNumber', String(pageNumber))

  if (parameters) {
    for (let [key, value] of Object.entries(parameters)) {
      value && params.append(key, value)
    }
  }

  return apiInstance
    .get(`hardware?${params.toString()}`)
    .then((resp) => resp.data)
}

export const getHardware = async (id: number): Promise<HardwareTypes.Model> => {
  return apiInstance.get(`hardware/${id}`).then((resp) => resp.data)
}

export const createHardware = async (
  obj: IHardwareRequest
): Promise<ICreateHardwareResponse> => {
  return apiInstance
    .post<ICreateHardwareResponse>('hardware', { ...obj })
    .then((resp) => resp.data)
}

export const editHardware = async (
  hardwareId: number,
  hardware: IHardwareEdit
): Promise<ICreateHardwareResponse> => {
  return apiInstance
    .put<ICreateHardwareResponse>(`hardware/${hardwareId}`, hardware)
    .then((resp) => resp.data)
}

export const removeHardware = async (hardwareId: number) => {
  return apiInstance.delete<ICreateHardwareResponse>(`hardware/${hardwareId}`)
}
