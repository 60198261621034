import { Editor } from '@tinymce/tinymce-react'
import { TinyMceApiKey } from 'constants/tinyMce'
import i18n from 'i18next'
import { TinyMceStyles as ST } from './styled'
import { IItem } from 'components/ui/BaseSelect'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

type Props = {
  initialValue?: string
  label?: string
  placeholder?: string
  variables?: IItem<string>[]
  required?: boolean
  setValue: (value: string) => void
}

const EditorComponent: FC<Props> = ({
  initialValue,
  placeholder,
  setValue,
}) => (
  <Editor
    apiKey={TinyMceApiKey}
    value={initialValue ?? ''}
    init={{
      min_height: 178,
      height: 178,
      max_height: 425,
      menubar: false,
      statusbar: false,
      plugins: [
        'autolink',
        'link',
        'charmap',
        'preview',
        'anchor',
        'searchreplace',
        'visualblocks',
        'insertdatetime',
        'code',
        'help',
        'autoresize',
      ],
      toolbar: `undo redo |
             bold italic forecolor |
             alignleft aligncenter alignright alignjustify |
             bullist numlist outdent indent |
             removeformat`,
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background: #f8f8f8f5 99% 50% no-repeat; }' +
        '.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {color: rgb(205 205 205);}' +
        '.mce-content-body{font-family: Golos, sans-serif; font-size: 16px; background: #f8f8f8f5 99% 50% no-repeat;}',
      language: i18n.resolvedLanguage,
      placeholder: placeholder,
      forced_root_block: '',
      force_br_newlines: true,
      force_p_newlines: false,
      setup: (editor) => {
        editor.on('keydown', (e) => {
          if (e.key === 'Enter') {
            if (!e.shiftKey) {
              e.preventDefault()
              editor.execCommand('InsertLineBreak')
            }
          }
        })
      },
    }}
    onEditorChange={setValue}
  />
)

const TinyMce: FC<Props> = ({ label, required, variables, ...props }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_LETTERS)

  return (
    <ST.Container>
      {!!label && <ST.Label $required={required}>{label}</ST.Label>}

      <EditorComponent {...props} />

      {!!variables?.length && (
        <ST.InfoContainer>
          <ST.Label>{t('variables')}</ST.Label>

          {variables.map(({ item, value }) => (
            <ST.VariableValue key={item}>
              {`{ ${item} }`} - {value}
            </ST.VariableValue>
          ))}
        </ST.InfoContainer>
      )}
    </ST.Container>
  )
}

export default TinyMce
