import React, { Dispatch, SetStateAction, useMemo } from 'react'
import * as ST from './styled'
import { listSettingsSteps } from 'constants/settingsStepsDescription'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { URLSearchParamsInit } from 'react-router-dom'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { SettingsLocales } from 'i18n/types'
import { IItem } from 'components/ui/BaseSelect'
import { SettingsSteps } from 'constants/settingsSteps'

interface IStepProps {
  step: number
  setStep: (tabIndex: number) => void
  roles?: string[]
  userId?: number
  userStatus?: number
  setIsEditing: Dispatch<SetStateAction<boolean>>
  setSearchParams: (
    nextInit: URLSearchParamsInit,
    navigateOptions?: {
      replace?: boolean | undefined
      state?: any
    }
  ) => void
}

const Tabs: FC<IStepProps> = ({
  step,
  setStep,
  setIsEditing,
  setSearchParams,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS)
  const isTabletMiddleDevice = useMediaQuery(
    `(max-width: ${BreakPoints.TABLETS_MIDDLE})`
  )

  const tabsList = useMemo<IItem<SettingsSteps>[]>(() => {
    const tabs = t<SettingsLocales.Settings['tabs']>('tabs')

    return Object.entries(tabs).map(([key, value]) => ({
      item: value,
      value: +key,
    }))
  }, [t])

  return isTabletMiddleDevice ? (
    <ST.WrapperSwiper>
      <Swiper
        slidesPerView={2.2686}
        spaceBetween={10}
        allowTouchMove
        simulateTouch
      >
        {tabsList.map((singleStep) => (
          <SwiperSlide key={singleStep.value}>
            <ST.SwiperTabCard
              isSelected={step === +singleStep.value}
              onClick={setStep.bind(this, +singleStep.value)}
            >
              <ST.SwiperTabCardTitle>{singleStep.item}</ST.SwiperTabCardTitle>
            </ST.SwiperTabCard>
          </SwiperSlide>
        ))}
      </Swiper>
    </ST.WrapperSwiper>
  ) : (
    <ST.Wrapper>
      {tabsList.map((singleStep) => (
        <ST.Step
          key={+singleStep.value}
          isSelected={step === +singleStep.value}
          onClick={() => {
            setStep(+singleStep.value)
            setIsEditing(false)
            setSearchParams({ tab: String(singleStep.value) })
          }}
        >
          {singleStep.item}
        </ST.Step>
      ))}
    </ST.Wrapper>
  )
}

export default Tabs
