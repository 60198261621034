import { IValuesFilter, PropsEmployee } from 'types/employeeType'
import { apiInstance } from 'api'
import { ExtendedResponseType } from 'types/utils'
import { UserTypes } from 'types/model/user'
import { UserApiTypes } from 'store/api/user/types'

interface TCreateEmployee {
  id: number
}

export interface IStatusesResponse {
  status: UserTypes.Status[]
}

export const getEmployees = async (
  page?: number | null,
  parameters?: IValuesFilter,
  searchValue?: string
): Promise<ExtendedResponseType<UserTypes.Model>> => {
  const params = new URLSearchParams()
  const pageSize: number = parameters?.pageSize ?? 50
  let pageNumber: number
  if (page) {
    pageNumber = page
  } else {
    pageNumber = 1
  }
  params.append('pageSize', String(pageSize))
  params.append('pageNumber', String(pageNumber))
  if (parameters) {
    for (let [key, value] of Object.entries(parameters)) {
      if (
        !!value && typeof value === 'string'
          ? !!value.length
          : Array.isArray(value)
          ? !!value.filter((v) => v).length
          : !!value
      ) {
        params.append(
          UserApiTypes.Request.GetAllFilterKeys.includes(
            key as keyof IValuesFilter
          )
            ? `filter[${key}][]`
            : key,
          value
        )
      }
    }
  }
  if (searchValue) {
    params.append('search', searchValue)
  }
  return apiInstance
    .get(`user/order?${params.toString()}`)
    .then((resp) => resp.data)
}

export const createEmployee = async (
  obj: PropsEmployee
): Promise<TCreateEmployee> => {
  return apiInstance
    .post<TCreateEmployee>('user', { ...obj })
    .then((resp) => resp.data)
}

export const getStatuses = async (): Promise<IStatusesResponse> => {
  return apiInstance
    .get<IStatusesResponse>('user/status')
    .then((resp) => resp.data)
}

export const restoreEmployee = async (
  userId: number
): Promise<UserTypes.RestoredUser> => {
  return apiInstance.patch(`user/reinstate/${userId}`).then((resp) => resp.data)
}
