import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import * as ST from './styled'
import StaticContactsTabContent from './StaticContactsTabContent'
import EditingContactsTabContent from './EditingContactsTabContent'
import { ButtonTypes } from 'constants/buttonTypes'
import { SettingsSteps } from 'constants/settingsSteps'
import { getContactsSettings } from 'api/settings'
import { ISettingSubsection } from 'types/model/settings'
import Loader from 'components/ui/Loader'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface IUserProps {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  step: SettingsSteps
}

const ContactsTab: FC<IUserProps> = ({ setIsEditing, isEditing }) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_CONTACTS,
    I18nNamespaces.TRANSLATION
  )
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [settingsData, setSettingsData] = useState<ISettingSubsection[]>([])

  const updateSettingsData = () => {
    getContactsSettings()
      .then(setSettingsData)
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    updateSettingsData()
  }, [])

  const handleIsEditing = () => {
    setIsEditing((prevState) => !prevState)
  }

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>{t('title')}</ST.EditingHeader>
          </ST.BackBlock>
          <ST.SaveButton type={ButtonTypes.submit} onClick={handleIsEditing}>
            {t('translation:edit')}
          </ST.SaveButton>
        </ST.ManageBlock>
      )}
      {isLoading ? (
        <Loader marginTop="40px" />
      ) : settingsData.length ? (
        isEditing ? (
          <EditingContactsTabContent
            handleIsEditing={handleIsEditing}
            data={settingsData}
            onEnd={updateSettingsData}
          />
        ) : (
          <StaticContactsTabContent data={settingsData} />
        )
      ) : (
        <></>
      )}
    </ST.MainContainer>
  )
}

export default ContactsTab
