import React from 'react'
import * as ST from './styled'
import DashedButton from 'components/ui/buttons/DashedButton'
import { ReactComponent as PlusIcon } from 'assets/icons/blue-plus.svg'

interface Props {
  onClick?: () => void
  text?: string
}

const AddGradeButton: FC<Props> = ({ onClick, text }) => (
  <DashedButton onClick={() => onClick && onClick()}>
    <ST.Container>
      <ST.Text>{text ?? 'Добавить грейд'}</ST.Text>
      <PlusIcon />
    </ST.Container>
  </DashedButton>
)

export default AddGradeButton
