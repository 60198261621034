import React from 'react'
import * as ST from './styled'
import { CSSProperties } from 'styled-components'

export interface Props {
  label: string
  value: string
  id?: string
  checked?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  style?: CSSProperties
}

interface RadioGroupProps {
  orientation?: 'vertical' | 'horizontal'
  label?: string
  required?: boolean
}

const BaseRadio: FC<Props> = ({
  label,
  id,
  value,
  checked,
  style,
  onChange,
}) => {
  const radioId = id || value

  return (
    <ST.Container style={style}>
      <ST.Radio
        type="radio"
        id={radioId}
        value={value}
        checked={checked}
        onChange={(e) => onChange && onChange(e)}
      />
      <ST.Label htmlFor={radioId}>{label}</ST.Label>
    </ST.Container>
  )
}

export const RadioGroup: FC<RadioGroupProps> = ({
  label,
  required,
  orientation = 'horizontal',
  children,
}) => (
  <ST.RadioGroupContainer>
    {label && <ST.GroupLabel required={required}>{label}</ST.GroupLabel>}
    <ST.RadioGroup $orientation={orientation}>{children}</ST.RadioGroup>
  </ST.RadioGroupContainer>
)

export default BaseRadio
