// TODO: move all to store/api/review

import { apiInstance } from 'api'
import { ReviewTypes } from 'types/model/review'
import { ReviewApiTypes as T } from 'api/review/types'
import { ExtendedResponseType } from 'types/utils'

export const getReviews = async (
  page?: number,
  parameters?: T.IReviewsValuesFilter
): Promise<ExtendedResponseType<ReviewTypes.Model>> => {
  const pageSize: number = 10
  let pageNumber: number
  if (page) {
    pageNumber = page
  } else {
    pageNumber = 1
  }
  const params = new URLSearchParams()
  params.append('pageSize', String(pageSize))
  params.append('pageNumber', String(pageNumber))

  if (parameters) {
    for (let [key, value] of Object.entries(parameters)) {
      if (value !== null && value !== undefined) {
        params.append(key, value.toString())
      }
    }
  }

  return apiInstance
    .get(`review?${params.toString()}`)
    .then((resp) => resp.data)
}

export const createReview = async (
  data: T.IRequestCreateReview
): Promise<number> => {
  return apiInstance.post('review', data).then((resp) => resp.data)
}

export const editReview = async (
  reviewId: number,
  review: T.IReviewEdit
): Promise<ReviewTypes.Model> => {
  return apiInstance.patch(`review/${reviewId}`, { ...review })
}

export const getReviewTypes = async (): Promise<T.IReview[]> => {
  return apiInstance.get('review/type').then((resp) => resp.data.data)
}

export const getReviewByUser = async (
  parameters?: T.IReviewsUserValuesFilter
): Promise<ExtendedResponseType<ReviewTypes.Model>> => {
  const params = new URLSearchParams()

  if (parameters) {
    for (let [key, value] of Object.entries(parameters)) {
      value && params.append(key, value.toString())
    }
  }

  return apiInstance
    .get(`review/user?${params.toString()}`)
    .then((resp) => resp.data)
}

export const requestCancelReview = async (
  reviewId: number,
  comment: string
): Promise<T.IRequestFinishReview> => {
  const params = new URLSearchParams()
  params.append('comment', comment)
  return apiInstance
    .patch(`review/cancel/${reviewId}?${params.toString()}`)
    .then((resp) => resp.data.data)
}

export const requestFinishReview = async (
  reviewId: number,
  data: any
): Promise<T.IRequestFinishReview> => {
  return apiInstance
    .patch(`review/skills/${reviewId}`, data)
    .then((resp) => resp.data.data)
}

export const getReviewById = async (id: number): Promise<ReviewTypes.Model> => {
  return apiInstance.get(`review/${id}`).then((resp) => resp.data.data)
}

export const writeSurveyAnswers = async (
  reviewId: number,
  answers: Pick<ReviewTypes.Survey, 'expertises' | 'textReview'>
): Promise<ReviewTypes.Model> => {
  return apiInstance
    .patch(`review/survey/complete/${reviewId}`, answers)
    .then((resp) => resp.data.data)
}

export const getNextReviewTasksById = async (
  userId: number
): Promise<ReviewTypes.Model[]> => {
  return apiInstance
    .get(`review/next-review-tasks/${userId}`)
    .then((resp) => resp.data.data)
}

export const getResultSurvey360 = async (
  reviewId: number
): Promise<ReviewTypes.Review360Result> => {
  return apiInstance
    .get(`review/survey-result/${reviewId}`)
    .then((resp) => resp.data)
}

export const getSurveyById = async (
  surveyId: number
): Promise<ReviewTypes.Survey> => {
  return apiInstance.get(`review/survey/${surveyId}`).then((resp) => resp.data)
}
