import React from 'react'
import * as ST from './styled'
import { ReactComponent as UnderConsiderationIcon } from 'assets/icons/Applications/statuses/under-consideration.svg'
import { ReactComponent as RejectedIcon } from 'assets/icons/Applications/statuses/rejected.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/Applications/statuses/approved.svg'
import { VacancyStatuses } from 'constants/vacancyStatusTypes'
import { ReactComponent as ClosedIcon } from 'assets/icons/Applications/statuses/closed.svg'
import { useGetResource } from 'i18n/hooks/useGetResource'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  status: VacancyStatuses
}

const StatusBlock: FC<Props> = ({ status }) => {
  const statuses = useGetResource(I18nNamespaces.VACANCIES, 'statuses')

  const icons = {
    [VacancyStatuses.NEW]: () => <UnderConsiderationIcon />,
    [VacancyStatuses.CLOSED]: () => <ClosedIcon />,
    [VacancyStatuses.IN_PROGRESS]: () => <ApprovedIcon />,
    [VacancyStatuses.CANCELED]: () => <ClosedIcon />,
    [VacancyStatuses.ON_HOLD]: () => <RejectedIcon />,
  }

  return (
    <ST.StatusBlock>
      {icons[status]()}
      <ST.Label>{statuses[status]}</ST.Label>
    </ST.StatusBlock>
  )
}

export default StatusBlock
