import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as ST from './styled'
import { PositionTypes } from 'types/model/position'
import { handlerError } from 'utils/handlerError'
import { removePosition } from 'api/position'
import { useModalWindowState } from 'hooks/useModalWindowState'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { CommonStyles } from 'styles/CommonStyles'
import { BreakPoints } from 'constants/breakPoints'
import Modal from 'components/ui/overlay/Modal'
import { Tags } from 'styles/Tags'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  positionInfo: PositionTypes.Model
  updatePositions?: () => void
}

const PositionBlock: FC<Props> = ({ positionInfo, updatePositions }) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_POSITIONS,
    I18nNamespaces.TRANSLATION
  )
  const navigate = useNavigate()
  const { modalOpen, toggleModal } = useModalWindowState()

  const goToPosition = (id: number) => {
    navigate(`/positions/${id}`)
  }

  const goToEditPosition = (id: number) => {
    navigate(`/positions/${id}/edit`)
  }

  const removeItem = () => {
    removePosition(positionInfo.id)
      .then(() => {
        updatePositions?.()
      })
      .catch((err) => handlerError(err))
  }

  return (
    <>
      <ST.PositionBlock onClick={() => goToPosition(positionInfo.id)}>
        <ST.DataContainer>
          <ST.Title
            maxWidth={900}
            title={positionInfo.name}
            mediaMaxWidth={{
              ...CommonStyles.DEFAULT_MEDIA,
              [BreakPoints.TABLETS]: 350,
            }}
          >
            {positionInfo.name}
          </ST.Title>
          <ST.Title maxWidth={900}>{positionInfo.employeeCount}</ST.Title>
        </ST.DataContainer>

        <ST.ButtonsBlock>
          <IconButton
            icon={EditIcon}
            onClick={(e) => {
              e.stopPropagation()
              goToEditPosition(positionInfo.id)
            }}
          />
          <IconButton
            icon={DeleteIcon}
            onClick={(e) => {
              e.stopPropagation()
              toggleModal()
            }}
          />
        </ST.ButtonsBlock>
      </ST.PositionBlock>

      <Modal
        title={t('delete_title')}
        isOpen={modalOpen}
        onClose={toggleModal}
        submitButtonText={t('translation:delete')}
        onConfirm={removeItem}
        style={{ width: 580 }}
      >
        <ST.ModalContent>
          {!!positionInfo.employeeCount && (
            <Tags.P2>
              {t('delete_content_1', {
                employeeCount: positionInfo.employeeCount,
              })}
            </Tags.P2>
          )}
          <Tags.P2>
            {t('delete_content_2', { name: positionInfo.name })}
          </Tags.P2>
        </ST.ModalContent>
      </Modal>
    </>
  )
}

export default PositionBlock
