import React, { useEffect, useState } from 'react'
import * as ST from './styled'
import BaseButton from 'components/ui/buttons/BaseButton'
import { LetterTemplate } from 'types/model/letterTemplate'
import { getListLetterTemplate } from 'api/letterTemplate'
import StaticLetterTemplateTabContent from './StaticLetterTemplateTabContent'
import CreateLetterTemplate from 'components/dialogs/settings/CreateLetterTemplate'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

const LetterTemplateTab = () => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_LETTERS)
  const [letterTemplates, setLetterTemplates] = useState<LetterTemplate[]>([])

  const [showModalCreateTemplate, setShowModalCreateTemplate] =
    useState<boolean>(false)

  const handleShowCreateTemplate = (): void => {
    setShowModalCreateTemplate((prev) => !prev)
  }

  const updateTemplates = () => {
    getListLetterTemplate().then((resp) => setLetterTemplates(resp.data))
  }

  useEffect(() => {
    updateTemplates()
  }, [])

  return (
    <ST.MainContainer>
      <ST.ManageBlock>
        <ST.BackBlock>
          <ST.EditingHeader>{t('title')}</ST.EditingHeader>
        </ST.BackBlock>
        <BaseButton
          typeButton="primary"
          text={t('add')}
          onClick={handleShowCreateTemplate}
          width="40%"
        />
      </ST.ManageBlock>
      {!!letterTemplates.length && (
        <StaticLetterTemplateTabContent
          templates={letterTemplates}
          updateTemplates={updateTemplates}
        />
      )}
      {showModalCreateTemplate && (
        <CreateLetterTemplate
          show={showModalCreateTemplate}
          onClose={handleShowCreateTemplate}
          updateTemplates={updateTemplates}
          selectedTemplate={null}
          isEdit={false}
        />
      )}
    </ST.MainContainer>
  )
}

export default LetterTemplateTab
