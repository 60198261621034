import React, { useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { parseDataToDynamicGrades } from 'utils/parseData'
import { handlerError } from 'utils/handlerError'
import { addPositionGrades, createPosition } from 'api/position'
import PositionInput from 'components/settings/Tabs/Department/EditDepartment/PositionInput'
import { IItem } from 'components/ui/BaseSelect'
import { useGetGradesQuery } from 'store/api/grade'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseInput from 'components/ui/inputs/BaseInput'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import BaseButton from 'components/ui/buttons/BaseButton'
import DashedButton from 'components/ui/buttons/DashedButton'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
  updatePositions?: () => void
}

interface ICreatePosition {
  positionName: string
}

export interface IDynamicGrade extends IItem {
  new?: boolean
}

const inputName = 'positionName'

const CreatePosition = ({
  show,
  onClose,
  showEndModal,
  updatePositions,
}: Props) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_POSITIONS)
  const { RequiredFields } = useRequiredFields()
  const [grades, setGrades] = useState<IDynamicGrade[]>([
    {
      value: `new-0`,
      item: '',
      new: true,
    },
  ])
  const [newGradeCount, setNewGradeCount] = useState<number>(1)

  const { allGrades } = useGetGradesQuery(undefined, {
    selectFromResult: ({ data, error, isLoading }) => ({
      allGrades: parseDataToDynamicGrades(data || []),
      error,
      isLoading,
    }),
  })

  const addGrades = async (positionId: number) => {
    const newGrades = grades
      .map((item) => String(item.value))
      .filter((item) => item && !item.includes('new'))
      .map((item) => +item)

    positionId && (await addPositionGrades(+positionId, newGrades).then())
  }

  const resetGrades = () => {
    setGrades([
      {
        value: `new-0`,
        item: '',
        new: true,
      },
    ])
  }

  const { handleChange, values, handleSubmit, errors, isValid } =
    useFormik<ICreatePosition>({
      initialValues: {
        positionName: '',
      },
      onSubmit: (data, { resetForm }) => {
        createPosition(values.positionName)
          .then((res) => {
            addGrades(+res.id).then(() => {
              resetForm()
              resetGrades()
            })
            if (updatePositions) {
              updatePositions()
            }
          })
          .catch((err) => handlerError(err))
        handleClose()

        if (showEndModal) {
          showEndModal(true)
        }
      },
      validationSchema: Yup.object().shape({
        positionName: Yup.string().required(RequiredFields.base),
      }),
    })

  const checkForm = (): void => {
    if (isValid && grades[0].item) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const handleClose = (): void => {
    onClose(!show)
  }

  const removeGrade = (indexToRemove: number) => {
    const grade = grades[indexToRemove]

    if (grade.new) {
      setGrades(grades.filter((_, index) => index !== indexToRemove))
    }
  }

  const createGrade = () => {
    setGrades([
      ...grades,
      {
        value: `new-${newGradeCount}`,
        item: '',
        new: true,
      },
    ])
    setNewGradeCount((prevState) => prevState + 1)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{t('create_title')}</ST.ModalTitle>
          <ST.InputsBlock>
            <BaseInput
              id={inputName}
              name={inputName}
              value={values.positionName}
              onChange={handleChange}
              label={t('name')}
              required
            />
            <ST.GradesBlock>
              <ST.Label>
                {t('grade')}
                <ST.Star>*</ST.Star>
              </ST.Label>
              <ST.GradeWrapper>
                {grades.map((item, index) => (
                  <PositionInput
                    key={String(item.value)}
                    value={item}
                    placeholder={t('grade_placeholder')}
                    listItems={allGrades}
                    selectedItems={grades}
                    disabledDelete={index === 0}
                    onChange={(newValue) => {
                      if (newValue) {
                        const newGrades = grades
                        newGrades[index] = newValue
                        setGrades(newGrades)
                      }
                    }}
                    onRemove={() => removeGrade(index)}
                  />
                ))}
              </ST.GradeWrapper>
            </ST.GradesBlock>
            <DashedButton
              onClick={createGrade}
              style={{ width: 'max-content' }}
            >
              <ST.Plus />
              {t('add_grade')}
            </DashedButton>
          </ST.InputsBlock>
          <ST.OutputBlock>
            <ST.ErrorText>
              {errors.positionName || !grades[0].item
                ? RequiredFields.base2
                : ''}
            </ST.ErrorText>
          </ST.OutputBlock>

          <BaseButton text={t('create_submit')} onClick={checkForm} />
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreatePosition
