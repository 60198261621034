import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import {
  useGetServiceModeStatusQuery,
  useSetSystemModeMutation,
} from 'store/api/setting'
import BaseButton from 'components/ui/buttons/BaseButton'
import { ButtonTypes } from 'constants/buttonTypes'
import Loader from 'components/ui/Loader'
import { setToastError, setToastSuccess } from 'utils/handlerError'
import { ROLES } from 'constants/roles'
import { SystemMode } from 'types/SystemType'
import checkRole from 'utils/profile/checkRole'
import { useAuth } from 'hooks/useAuth'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
}

const ServiceModeSwitch: FC<Props> = ({ show, onClose }) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_GENERAL,
    I18nNamespaces.TRANSLATION
  )
  const { roles } = useAuth()
  const {
    data: serviceMode,
    isLoading: isServiceModeLoading,
    isFetching: isServiceModeFetching,
  } = useGetServiceModeStatusQuery(undefined, {
    skip: !checkRole(roles, [ROLES.admin, ROLES.owner, ROLES.sysadmin]),
  })
  const [setServiceModeStatus] = useSetSystemModeMutation()

  const isServiceMode = serviceMode?.systemMode === SystemMode.SERVICE

  const handleClose = (): void => onClose(!show)
  const switchServiceMode = () => {
    const newValue = isServiceMode ? SystemMode.ACTIVE : SystemMode.SERVICE
    setServiceModeStatus(newValue)
      .then(() =>
        setToastSuccess(
          t('service_mode_success', {
            action: t(newValue === SystemMode.SERVICE ? 'enabled' : 'disabled'),
          })
        )
      )
      .catch(() => setToastError(t('service_mode_error')))
    handleClose()
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          {isServiceModeLoading || isServiceModeFetching ? (
            <Loader />
          ) : (
            <>
              {' '}
              <ST.ModalTitle>
                {t(
                  isServiceMode ? 'modal_title_disable' : 'modal_title_enable'
                )}
              </ST.ModalTitle>
              <ST.Description>{t('service_mode_info')}</ST.Description>
              <ST.ButtonsWrapper>
                <BaseButton
                  typeButton={ButtonTypes.primary}
                  text={isServiceMode ? t('disable') : t('enable')}
                  onClick={switchServiceMode}
                />
                <BaseButton
                  typeButton={ButtonTypes.secondary}
                  text={t('translation:cancel')}
                  onClick={handleClose}
                />
              </ST.ButtonsWrapper>
            </>
          )}
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default ServiceModeSwitch
