import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { IDocumentTemplate } from 'types/model/documentTemplateType'
import { SettingsLocales } from 'i18n/types'

interface Props {
  show: boolean
  title?: string
  onClose: (isShow: boolean) => void
  handleConfirm?: (id: number) => void
  doc: IDocumentTemplate
  translate: SettingsLocales.Documents['delete_modal']
}

const DeleteSection: FC<Props> = ({
  show,
  title,
  onClose,
  handleConfirm,
  doc,
  translate,
}) => {
  const handleClose = (): void => {
    onClose(!show)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{title ?? translate.title}</ST.ModalTitle>
          <ST.Description>{translate.description}</ST.Description>
          <ST.ButtonsBlock>
            <ST.Button
              onClick={() => {
                handleConfirm && handleConfirm(doc.id)
                handleClose()
              }}
            >
              {translate.submit}
            </ST.Button>
            <ST.Button onClick={handleClose} typeButton={'secondary'}>
              {translate.cancel}
            </ST.Button>
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteSection
