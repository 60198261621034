import React from 'react'
import * as ST from './styled'
import TitleLine from './TitleLine'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AuthorLine from './AuthorLine'
import { StatisticByEmployeesEmployeeType } from 'types/statisticType'
import { HiringStep } from 'types/model/hiring'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  employee: StatisticByEmployeesEmployeeType
  stages: HiringStep[]
  tableWidth: number | undefined
  disableCompare: boolean
}

const StatisticEmployeeCard: FC<Props> = ({
  employee,
  tableWidth,
  stages,
  disableCompare,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.STATISTIC)

  return (
    <>
      {employee.vacancies?.length ? (
        <ST.StatisticCard width={tableWidth}>
          <Accordion sx={{ boxShadow: '0' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  flexDirection: 'column',
                },
                '& .MuiAccordionSummary-expandIconWrapper': {
                  position: 'absolute',
                  right: '20px',
                  top: '20px',
                },
              }}
            >
              <ST.StatisticHeader>
                <ST.Author>
                  <ST.ImageBlock>
                    <ST.Image>
                      <ST.Status>
                        <ST.PhotoContainer imageSrc={''}></ST.PhotoContainer>
                      </ST.Status>
                    </ST.Image>
                  </ST.ImageBlock>
                  <ST.AuthorName
                    title={`${employee.recruiter.surname} ${employee.recruiter.name}`}
                  >
                    {employee.recruiter.surname} {employee.recruiter.name}
                  </ST.AuthorName>
                  <ST.AuthorPosition>
                    {employee.recruiter.position}
                  </ST.AuthorPosition>
                </ST.Author>
                <ST.Result>
                  <ST.ResultTitle>
                    {t('hunting')} -{' '}
                    <ST.ResultScore>{employee.huntingCount}</ST.ResultScore>
                  </ST.ResultTitle>
                  <ST.ResultTitle>
                    {t('recruiting')} -{' '}
                    <ST.ResultScore>{employee.recruitingCount}</ST.ResultScore>
                  </ST.ResultTitle>
                  <ST.ResultTitle>
                    {t('recommendations')} -{' '}
                    <ST.ResultScore>
                      {employee.recommendationCount}
                    </ST.ResultScore>
                  </ST.ResultTitle>
                </ST.Result>
              </ST.StatisticHeader>
              <ST.StatisticVisibleBody>
                {employee?.byStages?.map((e) => (
                  <ST.SingleScore
                    key={e.stage.id}
                    width={100 / employee.byStages.length}
                  >
                    <ST.SingleScoreResult>
                      <ST.SingleMainScore>{e.count}</ST.SingleMainScore>
                      {!disableCompare && (
                        <ST.SingleScoreCompare positive={e.diff > 0}>
                          {e.diff > 0 ? `+${e.diff}` : e.diff}
                        </ST.SingleScoreCompare>
                      )}
                    </ST.SingleScoreResult>
                    <ST.SingleScoreNotice>
                      {e.toRejected} {t('reject')}
                    </ST.SingleScoreNotice>
                    <ST.SingleScoreNotice>
                      {e.toReserve} {t('reserve')}
                    </ST.SingleScoreNotice>
                  </ST.SingleScore>
                ))}
              </ST.StatisticVisibleBody>
            </AccordionSummary>
            <AccordionDetails>
              {employee?.vacancies?.map((vacancy) => (
                <>
                  <ST.StatisticHeader>
                    <AuthorLine
                      position={vacancy.position}
                      grade={vacancy.grade}
                      id={vacancy.vacancyId}
                    />
                    <TitleLine
                      deadlineDate={vacancy.dateDeadline}
                      date={vacancy.dateTaking}
                      status={vacancy.status}
                      priority={vacancy.priority}
                      expired={vacancy.expired}
                      daysAfterDeadline={vacancy.daysAfterDeadline}
                    />
                  </ST.StatisticHeader>
                  <ST.StatisticHiddenBody>
                    {vacancy?.byStages
                      ?.filter((_, i) => stages[i]?.isActive)
                      ?.map((stage) => (
                        <ST.SingleHiddenScore
                          key={stage.stage.id}
                          width={100 / vacancy.byStages.length}
                        >
                          <ST.SingleScoreResult>
                            <ST.SingleMainScore>
                              {stage.count}
                            </ST.SingleMainScore>
                          </ST.SingleScoreResult>
                          <ST.NoticeWrapper>
                            <ST.SingleScoreNotice>
                              <ST.SingleScoreNoticeSpan>
                                {stage.toRejected}
                              </ST.SingleScoreNoticeSpan>{' '}
                              {t('reject')}
                            </ST.SingleScoreNotice>
                            <ST.SingleScoreNotice>
                              <ST.SingleScoreNoticeSpan>
                                {stage.toReserve}
                              </ST.SingleScoreNoticeSpan>{' '}
                              {t('reserve')}
                            </ST.SingleScoreNotice>
                          </ST.NoticeWrapper>
                        </ST.SingleHiddenScore>
                      ))}
                  </ST.StatisticHiddenBody>
                </>
              ))}
            </AccordionDetails>
          </Accordion>
        </ST.StatisticCard>
      ) : (
        <ST.StatisticNoCollapseCard width={tableWidth}>
          <ST.StatisticHeader>
            <ST.Author>
              <ST.ImageBlock>
                <ST.Image>
                  <ST.Status>
                    <ST.PhotoContainer imageSrc={''}></ST.PhotoContainer>
                  </ST.Status>
                </ST.Image>
              </ST.ImageBlock>
              <ST.AuthorName
                title={`${employee.recruiter.surname} ${employee.recruiter.name}`}
              >
                {employee.recruiter.surname} {employee.recruiter.name}
              </ST.AuthorName>
              <ST.AuthorPosition>
                {employee.recruiter.position}
              </ST.AuthorPosition>
            </ST.Author>
            <ST.Result>
              <ST.ResultTitle>
                {t('hunting')} -{' '}
                <ST.ResultScore>{employee.huntingCount}</ST.ResultScore>
              </ST.ResultTitle>
              <ST.ResultTitle>
                {t('recruiting')} -{' '}
                <ST.ResultScore>{employee.recruitingCount}</ST.ResultScore>
              </ST.ResultTitle>
              <ST.ResultTitle>
                {t('recommendations')} -{' '}
                <ST.ResultScore>{employee.recommendationCount}</ST.ResultScore>
              </ST.ResultTitle>
            </ST.Result>
          </ST.StatisticHeader>
          <ST.StatisticVisibleBody>
            {employee?.byStages
              ?.filter((s, i) => stages[i]?.isActive)
              .map((e) => (
                <ST.SingleScore
                  key={e.stage.id}
                  width={100 / employee.byStages.length}
                >
                  <ST.SingleScoreResult>
                    <ST.SingleMainScore>{e.count}</ST.SingleMainScore>
                    {!disableCompare && (
                      <ST.SingleScoreCompare positive={e.diff > 0}>
                        {e.diff > 0 ? `+${e.diff}` : e.diff}
                      </ST.SingleScoreCompare>
                    )}
                  </ST.SingleScoreResult>
                  <ST.SingleScoreNotice>
                    {e.toRejected} {t('reject')}
                  </ST.SingleScoreNotice>
                  <ST.SingleScoreNotice>
                    {e.toReserve} {t('reserve')}
                  </ST.SingleScoreNotice>
                </ST.SingleScore>
              ))}
          </ST.StatisticVisibleBody>
        </ST.StatisticNoCollapseCard>
      )}
    </>
  )
}

export default StatisticEmployeeCard
