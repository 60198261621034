import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Endpoint } from 'constants/endpoint'
import { setToastError } from 'utils/handlerError'
import { PATHS } from 'constants/paths'
import { formatEndpoint } from 'utils/url/formatUrl'
import i18n from 'i18next'
import { ResponseErrorMessages } from 'constants/responseErrorMessages'
import {
  I18N_LOCALSTORAGE_KEY,
  SUPPORTED_LOCALES,
  SupportedLocales,
  SupportedLocalesValues,
} from 'i18n/config'
import { I18N_LOCALSTORAGE_URL_KEY } from 'utils/url/updateAxiosBaseUrl'

type Params = {
  token?: string
  keepAlive?: boolean
} | void

let displayedErrors: string[] = []

const errorMessagesBlackList: string[] = [
  ResponseErrorMessages.ERROR_403_NOT_EXIST,
  ResponseErrorMessages.ERROR_403_EXPIRED,
]

const setErrors = (value: string) => {
  displayedErrors = displayedErrors.includes(value)
    ? displayedErrors
    : [...displayedErrors, value]
}

const BASE_URL = `${formatEndpoint(Endpoint)}api`

axios.defaults.baseURL = BASE_URL

export function apiRequest(params: Params) {
  let headers = {
    'Content-Type': 'application/json',
  }

  const axiosConfig: AxiosRequestConfig = {
    baseURL:
      localStorage.getItem(I18N_LOCALSTORAGE_URL_KEY) ?? axios.defaults.baseURL,
    headers: headers,
  }

  // TODO keepAlive для axios, нужен для метода {@link changeResumeStatus}

  const axiosInstance = axios.create(
    params?.keepAlive
      ? {
          ...axiosConfig,
        }
      : axiosConfig
  )

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      localStorage.setItem('service', 'false')
      window.location.pathname === PATHS.servicemode &&
        (window.location.href = PATHS.auth)
      return Promise.resolve(response)
    },
    async (error: AxiosError) => {
      const lang = i18n.resolvedLanguage

      if (error?.response?.status! >= 500) {
        setToastError(
          i18n.language === SupportedLocalesValues.en
            ? 'An error occurred on the server. Please try again later.'
            : 'Произошла ошибка на сервере. Попробуйте позже.'
        )
      }

      localStorage.setItem('service', 'false')

      // TODO: QUESTIONABLE
      if (
        !!error.response &&
        error.response?.data &&
        error.response.status !== 403
      ) {
        const { data } = error.response

        const errorMessage =
          lang === SupportedLocalesValues.en
            ? data?.detail ?? data?.message
            : data?.validationError?.body?.[0]?.message ?? data?.message

        const hasCodeInQuery =
          window.location.pathname === PATHS.auth &&
          new URLSearchParams(window.location.search).has('code')

        if (
          errorMessage &&
          (errorMessagesBlackList.includes(errorMessage)
            ? !displayedErrors.includes(errorMessage)
            : true) &&
          !hasCodeInQuery
        ) {
          setToastError(errorMessage)
          setErrors(errorMessage)
        }
      }
      // TODO: refactor service mode switch
      if (
        !!error.response &&
        error.response?.data &&
        error.response.status === 433
      ) {
        localStorage.setItem('service', 'true')
        if (window.location.pathname !== `${PATHS.servicemode}`) {
          window.location.pathname = `${PATHS.servicemode}`
        }
      }

      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
    const lang =
      i18n.resolvedLanguage ?? localStorage.getItem(I18N_LOCALSTORAGE_KEY)

    if (!lang) {
      return Promise.reject()
    }

    // TODO: Refactor it
    if (request.url !== '/api/security/check') {
      if (request.url !== '/api/security/login') {
        if (request.headers && !request.headers?.apiKey) {
          const accessToken: string = JSON.parse(
            localStorage.getItem('auth') as string
          )?.accessToken
          if (params?.token || accessToken) {
            request.headers.apiKey = params?.token ?? accessToken
          }

          return Promise.resolve(request)
        }
      }
    }
    return Promise.resolve(request)
  })
  return axiosInstance
}

export function apiRequestForFile(
  responseType?: AxiosRequestConfig['responseType']
) {
  const headers = {
    'Content-Type': 'multipart/form-data',
  }
  return axios.create({
    headers: headers,
    responseType: responseType ?? 'blob',
  })
}
