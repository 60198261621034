import React, { ButtonHTMLAttributes, HTMLAttributeAnchorTarget } from 'react'
import * as ST from './styled'
import { CSSProperties } from 'styled-components'
import { PopupWrapper, PopupWrapperProps } from 'components/ui/overlay/Popup'

export interface Props {
  text: string
  typeButton?: 'primary' | 'secondary'
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type']
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  padding?: CSSProperties['padding']
  style?: CSSProperties
  disabled?: boolean
  fakeDisabled?: boolean
  onClick?: (e?: any) => void
  href?: string
  target?: HTMLAttributeAnchorTarget
  popupText?: string
  popupProps?: Omit<PopupWrapperProps, 'text'>
}

const BaseButton: FC<Props> = ({
  typeButton = 'primary',
  type,
  text,
  width,
  height,
  onClick,
  padding,
  popupText,
  target,
  style,
  popupProps,
  href,
  disabled = false,
  fakeDisabled = false,
}) => {
  const BaseButtonComponent = () =>
    href !== undefined ? (
      <ST.ButtonLink
        to={href}
        target={target}
        typeButton={typeButton}
        $disabled={disabled}
        fakeDisabled={fakeDisabled}
        onClick={onClick}
        style={{
          ...style,
          width: width || '100%',
          height: height || '48px',
          padding,
        }}
      >
        {text}
      </ST.ButtonLink>
    ) : (
      <ST.Button
        typeButton={typeButton}
        type={type || 'submit'}
        disabled={disabled}
        fakeDisabled={fakeDisabled}
        style={{
          ...style,
          width: width || '100%',
          height: height || '48px',
          padding,
        }}
        onClick={onClick}
      >
        {text}
      </ST.Button>
    )

  return popupText ? (
    <PopupWrapper {...popupProps} text={popupText}>
      <BaseButtonComponent />
    </PopupWrapper>
  ) : (
    <BaseButtonComponent />
  )
}

export default BaseButton
