import { InputWithIconWrapperTypes as Types } from './types'
import { InputWithIconWrapperStyles as ST } from './styled'

export const InputWithIconWrapper: FC<Types.Props> = ({
  children,
  icon,
  ...props
}) => (
  <ST.Wrapper>
    {children}

    {icon && (
      <ST.Icon {...props} pointer={!!props.onClick}>
        {icon}
      </ST.Icon>
    )}
  </ST.Wrapper>
)
