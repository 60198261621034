import React, { useContext } from 'react'
import * as ST from './styled'
import { ReviewTypes } from 'types/model/review'
import { ReviewStatuses, SurveyStatuses } from 'constants/reviewStatuses'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import BaseButton from 'components/ui/buttons/BaseButton'
import { Link } from 'react-router-dom'
import { COLORS } from 'constants/colors'
import checkRole from 'utils/profile/checkRole'
import { useAuth } from 'hooks/useAuth'
import {
  OtherUserProfileStepsByRoles,
  ProfileSteps,
} from 'constants/profileSteps'
import { SalaryType } from 'constants/salaryType'
import { SalaryName } from 'constants/salaryName'
import { ProfileNS } from 'i18n/types'
import { ReviewCardTranslateContext } from 'components/reviews/ReviewCard/index'
import { I18nNamespaces } from 'i18n/config'
import { useGetResource } from 'i18n/hooks/useGetResource'

interface Props {
  review: ReviewTypes.Model
  userId?: number
  isCurrentUser?: boolean
  isProfile?: boolean
}

interface TasksListProps {
  title: string
  color?: string
  tasks: ReviewTypes.Task[]
  filterValue: (task: ReviewTypes.Task) => boolean
}

const ButtonStyles = {
  height: 40,
}

const TasksList: FC<TasksListProps> = ({
  tasks,
  filterValue,
  color,
  title,
}) => (
  <>
    <ST.TaskListTitle color={color}>
      <ST.CircleAccordion />
      {title}
    </ST.TaskListTitle>
    {tasks?.filter(filterValue)?.map((task) => (
      <ST.Task inlineBlock={false} key={`task-${task.id}`}>
        {task.text}
      </ST.Task>
    ))}
  </>
)

const TargetLine: FC<Props> = ({
  review,
  isCurrentUser,
  userId,
  isProfile,
}) => {
  const salaryTypes = useGetResource(
    I18nNamespaces.REVIEWS,
    'finish_modal'
  ).salary_type

  const { roles } = useAuth()
  const survey360 = review.surveys.find(
    (survey) =>
      (survey.expert.id === userId && survey.type === 2) || survey.type === 0
  )
  const { tasks, ...ctx } = useContext<ProfileNS['reviews'] | null>(
    ReviewCardTranslateContext
  )!

  return (
    <ST.TargetLine>
      <ST.InfoCard>
        <ST.CardContent>
          {(review.status === ReviewStatuses.close ||
          review.status === ReviewStatuses.not_complete
            ? !!review.tasks?.length
            : !!review.tasks?.filter(
                (task) => task.type === 1 && task.status === 1
              )?.length) && (
            <ST.AccordionWrapper>
              <Accordion sx={{ boxShadow: '0', marginRight: '0 !important' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ST.InfoTitle>{tasks}</ST.InfoTitle>
                </AccordionSummary>
                <AccordionDetails>
                  {review.tasks
                    ?.filter((task) => task.type === 1 && task.status === 1)
                    ?.map((task) => (
                      <ST.AccordionItem key={`task-${task.id}`}>
                        <ST.CircleAccordion />
                        <ST.AccordionValue maxWidth={800}>
                          {task.text}
                        </ST.AccordionValue>
                      </ST.AccordionItem>
                    ))}

                  {(review.status === ReviewStatuses.close ||
                    review.status === ReviewStatuses.not_complete) && (
                    <>
                      <TasksList
                        title={ctx.completed_tasks}
                        tasks={review.tasks}
                        filterValue={(task) =>
                          task.type === 0 && task.status === 1
                        }
                        color={COLORS.green}
                      />
                      <TasksList
                        title={ctx.uncompleted_tasks}
                        tasks={review.tasks}
                        filterValue={(task) =>
                          task.type === 0 && task.status === 21
                        }
                        color={COLORS.errorRed}
                      />
                      <TasksList
                        title={ctx.next_tasks}
                        tasks={review.tasks}
                        filterValue={(task) => task.type === 1}
                      />
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </ST.AccordionWrapper>
          )}

          {(review.status === ReviewStatuses.close ||
            review.status === ReviewStatuses.cancel) &&
            (isProfile
              ? checkRole(
                  roles,
                  OtherUserProfileStepsByRoles[ProfileSteps.reviewTab]
                )
              : true) && (
              <ST.AccordionWrapper>
                <Accordion sx={{ boxShadow: '0', marginRight: '0 !important' }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <ST.InfoTitle>{ctx.documents}</ST.InfoTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                    {review.userSurvey && (
                      <Link
                        to={`/reviews/survey/results/${review.userSurvey.id}`}
                      >
                        <ST.AccordionDocuments>
                          — {ctx.user_survey}
                        </ST.AccordionDocuments>
                      </Link>
                    )}
                    <Link to={`/reviews/survey360/results/${review.id}`}>
                      <ST.AccordionDocuments>
                        — {ctx.survey360}
                      </ST.AccordionDocuments>
                    </Link>
                  </AccordionDetails>
                </Accordion>
              </ST.AccordionWrapper>
            )}

          {!!review?.experts?.length && (
            <ST.AccordionWrapper>
              <Accordion sx={{ boxShadow: '0', marginRight: '0 !important' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ST.InfoTitle>{ctx.interviewers}</ST.InfoTitle>
                </AccordionSummary>
                <AccordionDetails>
                  {review?.experts?.map((expert) => (
                    <ST.AccordionItem key={`expert-${expert.id}`}>
                      <ST.CircleAccordion />
                      <ST.AccordionValue>
                        <Link
                          to={`/user/${expert.id}`}
                          target="_blank"
                          style={{ textDecoration: 'underline' }}
                        >
                          {expert.surname} {expert.name} {expert.patronymic}
                        </Link>
                      </ST.AccordionValue>
                    </ST.AccordionItem>
                  ))}
                </AccordionDetails>
              </Accordion>
            </ST.AccordionWrapper>
          )}
        </ST.CardContent>
        {review.status === ReviewStatuses.close && (
          <ST.ReviewResults>
            <ST.AccordionWrapper>
              <Accordion sx={{ boxShadow: '0', marginRight: '0 !important' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ST.InfoTitle>{ctx.summary}</ST.InfoTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <ST.AccordionItem>
                    <ST.CircleAccordion />
                    <ST.AccordionValue>
                      {ctx.grade} — {review.grade?.name || ctx.not_set}
                    </ST.AccordionValue>
                  </ST.AccordionItem>
                  <ST.AccordionItem>
                    <ST.CircleAccordion />
                    <ST.AccordionValue>
                      {review.user?.salaryType == SalaryType.HOUR
                        ? salaryTypes.hour
                        : salaryTypes.salary}{' '}
                      — {review.salary} ₽
                    </ST.AccordionValue>
                  </ST.AccordionItem>
                  {review.comment !== null && !isCurrentUser && (
                    <>
                      <ST.AccordionItem>
                        <ST.CircleAccordion />
                        <ST.AccordionValue>{ctx.commentary}</ST.AccordionValue>
                      </ST.AccordionItem>
                      <ST.Comment>{review.comment}</ST.Comment>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>
            </ST.AccordionWrapper>
          </ST.ReviewResults>
        )}
        {review.status === ReviewStatuses.cancel && (
          <ST.ReviewResults>
            <ST.AccordionWrapper>
              <Accordion sx={{ boxShadow: '0', marginRight: '0 !important' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <ST.InfoTitle>{ctx.cancel}</ST.InfoTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <ST.AccordionItem>
                    <ST.CircleAccordion />
                    <ST.AccordionValue>
                      {ctx.responsible} —{' '}
                      {review.responsibleUser?.position?.name}
                      {', '}
                      {review.responsibleUser?.surname}{' '}
                      {review.responsibleUser?.name}
                    </ST.AccordionValue>
                  </ST.AccordionItem>

                  <ST.AccordionItem>
                    <ST.CircleAccordion />
                    <ST.AccordionValue>{ctx.reason}</ST.AccordionValue>
                  </ST.AccordionItem>
                  <ST.Comment>
                    {review.comment ? review.comment : '—'}
                  </ST.Comment>
                </AccordionDetails>
              </Accordion>
            </ST.AccordionWrapper>
          </ST.ReviewResults>
        )}
        {isCurrentUser && review.status === ReviewStatuses.wait && (
          <ST.ButtonsContainer>
            {review.userSurvey?.id && (
              <BaseButton
                {...ButtonStyles}
                text={
                  review.userSurvey.status === SurveyStatuses.COMPLETED
                    ? ctx.changeUserSurvey
                    : ctx.takeUserSurvey
                }
                href={`/reviews/survey/${review.userSurvey.id}`}
              />
            )}
            {survey360?.id && (
              <BaseButton
                {...ButtonStyles}
                text={
                  survey360.status === SurveyStatuses.COMPLETED
                    ? ctx.changeSurvey360
                    : ctx.takeSurvey360
                }
                href={`/reviews/survey360/${survey360.id}`}
              />
            )}
          </ST.ButtonsContainer>
        )}
      </ST.InfoCard>
    </ST.TargetLine>
  )
}

export default TargetLine
