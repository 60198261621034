import { NumberBadge as ST } from './styled'
import React from 'react'
import { CSSProperties } from 'styled-components'
import { compactLargeNumber } from 'utils/numbers/reduceLargeNumber'

export enum NumberBadgeType {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  type?: NumberBadgeType
  value: number
  style?: CSSProperties
}

const NumberBadge: FC<Props> = ({ type, value, style }) => (
  <ST.Container type={type || NumberBadgeType.Primary} style={style}>
    <ST.Value type={type || NumberBadgeType.Primary}>
      {compactLargeNumber(value)}
    </ST.Value>
  </ST.Container>
)

export default NumberBadge
