import { PhoneInputTypes as Types } from './types'
import React from 'react'
import BaseInput from 'components/ui/inputs/BaseInput'
import NumberFormat from 'react-number-format'
import { PhoneFormat } from 'constants/phone'
import { Placeholders } from 'constants/placeholders'
import * as ST from 'components/profile/Tabs/General/EditingGeneralTabContent/styled'
import * as STH from 'components/dialogs/Hiring/ChangeStatus/styled'

const PhoneInput: FC<Types.Props> = ({
  label = '',
  value = '',
  onChange = (e: any) => {},
  required = false,
  type,
  ...props
}) => {
  return (
    <ST.InputWrapper>
      <STH.LabelWrapper>
        <ST.Label>
          {label}
          {required ? <ST.Star>*</ST.Star> : ''}
        </ST.Label>
      </STH.LabelWrapper>
      <NumberFormat
        {...props}
        format={PhoneFormat.numberFormat}
        placeholder={Placeholders.phone}
        value={value}
        onChange={onChange}
        required={required}
      />
    </ST.InputWrapper>
  )
}

export default PhoneInput
