import React, {
  forwardRef,
  KeyboardEventHandler,
  memo,
  useCallback,
} from 'react'
import * as ST from './styled'
import NumberFormat from 'react-number-format'
import { BaseInputTypes as T } from './types'
import { InputWithIconWrapper } from 'components/ui/inputs/BaseInput/InputWithIconWrapper'
import useId from '@mui/material/utils/useId'
import { COLORS } from 'constants/colors'

const InputComponent = forwardRef<HTMLInputElement, T.Props>(
  (
    {
      value,
      id,
      name,
      placeholder,
      required,
      label,
      isValid,
      error,
      containerStyle,
      onChange,
      onBlur,
      onFocus,
      children,
      disabled,
      clipPlaceholder,
      autoComplete,
      inputStyle,
      acceptChars,
      ...props
    },
    ref
  ) => {
    const muiId = useId(id)

    const handleKeydown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
      (e) => {
        if (props.type === 'number') return

        if (
          acceptChars &&
          (typeof acceptChars === 'string'
            ? !acceptChars.includes(e.key)
            : !e.key.match(acceptChars))
        ) {
          e.preventDefault()
        }
      },
      [acceptChars, props.type]
    )

    return (
      <ST.Container style={containerStyle}>
        {label && (
          <ST.Label title={label} htmlFor={muiId} $required={required}>
            {label}
          </ST.Label>
        )}

        <ST.InputContainer>
          {props.type === 'number' ? (
            <NumberFormat
              {...props}
              style={error ? { borderColor: COLORS.errorRed } : undefined}
              type="text"
              customInput={props.customInput ?? ST.Input}
              placeholder={placeholder}
              id={muiId}
              format={props.format}
              value={value}
              onChange={onChange}
              disabled={disabled}
            />
          ) : (
            <ST.Input
              ref={ref}
              id={muiId}
              name={name}
              value={value}
              isValid={!error && isValid}
              style={inputStyle}
              onKeyDown={handleKeydown}
              placeholder={placeholder}
              onChange={onChange}
              onBlur={onBlur}
              onFocus={onFocus}
              disabled={disabled}
              clipPlaceholder={clipPlaceholder}
              autoComplete={autoComplete}
            />
          )}

          {error && <ST.Error>{error}</ST.Error>}
        </ST.InputContainer>
      </ST.Container>
    )
  }
)

const BaseInput: FC<T.Props> = ({
  rightAdornmentIcon,
  onRightAdornmentIconClick,
  ...props
}) =>
  rightAdornmentIcon ? (
    <InputWithIconWrapper
      icon={rightAdornmentIcon}
      onClick={onRightAdornmentIconClick}
    >
      <InputComponent {...props} />
    </InputWithIconWrapper>
  ) : (
    <InputComponent {...props} />
  )

export const BaseInputForwardedRefComponent = forwardRef<
  HTMLInputElement,
  T.Props
>(({ rightAdornmentIcon, onRightAdornmentIconClick, ...props }, ref) =>
  rightAdornmentIcon ? (
    <InputWithIconWrapper
      icon={rightAdornmentIcon}
      onClick={onRightAdornmentIconClick}
    >
      <InputComponent ref={ref} {...props} />
    </InputWithIconWrapper>
  ) : (
    <InputComponent ref={ref} {...props} />
  )
)

export const BaseInputForwardedRefComponentMemoized = memo(
  BaseInputForwardedRefComponent
) as typeof BaseInputForwardedRefComponent

export default BaseInput
