import {
  I18nNamespaces,
  SupportedLocales,
  SupportedLocalesValues,
} from 'i18n/config'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { Resources } from 'react-i18next'

export const useGetResourceBundle = <
  N extends I18nNamespaces,
  T extends Resources[N] = Resources[N]
>(
  ns: N,
  locale?: SupportedLocales
): T => {
  const { i18n } = useTypedTranslation(ns)

  const lang: SupportedLocales =
    locale ??
    (i18n.resolvedLanguage as SupportedLocales) ??
    SupportedLocalesValues.ru

  return i18n.getResourceBundle(lang, ns) as T
}
