import React, { ElementRef, useMemo, useRef, useState } from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import { handlerError } from 'utils/handlerError'
import * as ST from './styled'
import { BenefitPatchData, createBenefit } from 'api/benefits'
import { BenefitOperationTypes } from 'constants/benefitOperationTypes'
import * as Yup from 'yup'
import { BenefitStatuses } from 'constants/benefitStatuses'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseInput from 'components/ui/inputs/BaseInput'
import BaseButton from 'components/ui/buttons/BaseButton'
import { BenefitsNS } from 'i18n/types'
import { jsonObjectToItemsArray } from 'i18n/utils'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<any>>
}

const operationTypeData = 'operationTypeData'

export const selectsEnum = {
  type: 'type',
  operationType: 'operationType',
}

const CreateBenefit = ({ show, onClose, showEndModal }: Props) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_BENEFIT,
    I18nNamespaces.BENEFITS
  )
  const { RequiredFields } = useRequiredFields()
  const [benefit, setBenefit] = useState<BenefitPatchData | null>(null)

  const modalRef = useRef<ElementRef<'div'>>(null)

  const operationTypes = useMemo<IItem<BenefitOperationTypes>[]>(() => {
    const types = t<BenefitsNS['operationTypes']>('benefits:operationTypes')

    return jsonObjectToItemsArray(types)
  }, [t])

  const { values, handleSubmit, resetForm, errors, isValid, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      validateOnMount: true,
      validationSchema: Yup.object().shape({
        type: Yup.string().required(RequiredFields.base),
        operationTypeData: Yup.object().shape({
          value: Yup.number(),
          item: Yup.string().required(RequiredFields.base),
        }),
      }),
      initialValues: {
        type: '',
        operationType: BenefitOperationTypes.refill,
        operationTypeData: {
          value: benefit?.operationType || 1,
          item: null,
        },
      },
      onSubmit: async (newObj) => {
        await createBenefit({
          type: newObj.type,
          operationType: newObj.operationTypeData.value,
          status: BenefitStatuses.active,
        }).then(() => {
          handleClose()
          resetForm()
          if (showEndModal) {
            showEndModal('')
          }
          setBenefit(null)
          setFieldValue(selectsEnum.type, '')
          setFieldValue(operationTypeData, {
            value: benefit?.operationType || 1,
            item: `${BenefitOperationTypes[benefit?.operationType!]}`,
          })
        })
      },
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const handleClose = (): void => {
    onClose(!show)
  }

  const SignupForm = () => {
    return (
      <>
        <ST.InputsBlock>
          <BaseInput
            id={selectsEnum.type}
            name={selectsEnum.type}
            value={values?.type}
            onChange={(e) => {
              setFieldValue(selectsEnum.type, e.target.value)
            }}
            label={t('benefit_type')}
            required
          />
          <BaseSelect
            label={t('type')}
            isSmallSelect={false}
            placeHolder={t('select_type')}
            listItems={operationTypes}
            name={operationTypeData}
            value={values?.operationTypeData?.item || ''}
            typeSelect={operationTypeData}
            onChange={(newValue) => setFieldValue(operationTypeData, newValue)}
            clickableElements={[modalRef.current]}
            required
          />
        </ST.InputsBlock>

        <BaseButton
          onClick={checkForm}
          disabled={!isValid}
          text={t('create_submit')}
        />
      </>
    )
  }

  return (
    <>
      {show ? (
        <ST.ModalOverlay>
          <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
            <ST.ModalContent>
              <ST.Close onClick={handleClose}>
                <Close />
              </ST.Close>
              <ST.ModalTitle>{t('create_title')}</ST.ModalTitle>
              {SignupForm()}
            </ST.ModalContent>
          </ST.Modal>
        </ST.ModalOverlay>
      ) : null}
    </>
  )
}

export default CreateBenefit
