import { createContext } from 'react'
import { NewCandidateTypes as Types } from './types'

export namespace NewCandidateData {
  export const MAX_STORY_LENGTH = 10000
  export const MAX_COMPANY_NAME_LENGTH = 511
  export const MAX_RESPONSIBILITIES_LENGTH = 4095
  export const EditContext = createContext<boolean>(false)

  export const InitialValues: Types.Form = {
    hiring: 0,
    hiringType: { item: '', value: 0 },
    hireType: null,
    employmentType: null,
    source: { item: '', value: '' },
    sourceSelectedValue: null,
    resumeLink: '',
    name: '',
    patronymic: '',
    surname: '',
    birthday: '',
    city: '',
    gender: null,
    isMarried: false,
    isHaveChildren: false,
    story: '',
    portfolioLink: '',
    portfolioFile: null,
    portfolioFileToDeleteId: null,
    photoFile: null,
    citizenship: [''],
    workPermit: [''],
    readyToWork: NaN,
    position: '',
    grade: '',
    salary: NaN,
    specialization: [''],
    hardSkills: [''],
    removal: NaN,
    businessTrip: NaN,
    timeToWork: NaN,
    busyness: {
      fullEmployment: false,
      partTimeEmployment: false,
      projectWork: false,
      volunteering: false,
      internship: false,
    },
    workSchedule: {
      fullDay: false,
      shiftSchedule: false,
      flexibleSchedule: false,
      remoteWork: false,
      shiftMethod: false,
    },
    candidateWorkPlace: [
      {
        companyName: '',
        companyWebsite: '',
        companyLocation: '',
        companyActivity: '',
        position: '',
        responsibilities: '',
        hardSkills: [''],
        startWorkMonthType: { item: '', value: 0 },
        startWorkMonth: NaN,
        startWork: '',
        endWorkMonthType: { item: '', value: 0 },
        endWorkMonth: NaN,
        endWork: '',
      },
    ],
    educationLvlType: null,
    educationLvl: null,
    education: [
      {
        name: '',
        location: '',
        educationForm: 0,
        educationFormType: { item: '', value: 0 },
        yearEntered: NaN,
        yearEnded: NaN,
        faculty: '',
        directionTraining: '',
        speciality: '',
        successes: '',
        diploma: null,
        _diplomaFile: null,
        _diplomaFileToDelete: null,
      },
    ],
    additionalEducation: [
      {
        name: '',
        courseName: '',
        yearEntered: NaN,
        yearEnded: NaN,
        hardSkills: [''],
        successes: '',
        certificateLink: '',
        certificate: NaN,
        certificateName: '',
        _certificateFile: null,
        _certificateFileToDeleteId: null,
        certificateFile: null,
      },
    ],
    isHaveDrivingLicense: false,
    isHaveAuto: false,
    driverLicenseCategory: [''],
    nativeLanguage: '',
    foreignLanguage: [
      {
        name: '',
        nameType: { item: '', value: 0 },
        level: 0,
        levelType: { item: '', value: 0 },
      },
    ],
    candidate: {
      phone: null,
      additionalPhone: null,
      comment: '',
      email: '',
      additionalEmail: '',
      personalSiteLink: '',
      messengers: [
        {
          name: '',
          nick: '',
        },
      ],
    },
    photo: null,
    resumeFile: null,
    resume: null,
    portfolio: null,
    photoPath: '',
    resumeFilePath: '',
    portfolioPath: '',
    isReserved: false,
  }

  export const AdditionalEducationPattern = {
    name: '',
    location: '',
    educationForm: 1,
    educationFormType: { item: '', value: 0 },
    hardSkills: [''],
    yearEntered: '',
    yearEnded: '',
    faculty: '',
    directionTraining: '',
    speciality: '',
    successes: '',
  }

  export const CandidateWorkPlacePattern = {
    companyName: '',
    companyWebsite: '',
    companyLocation: '',
    companyActivity: '',
    position: '',
    responsibilities: '',
    hardSkills: [''],
    startWorkMonthType: { item: '', value: 0 },
    startWorkMonth: '',
    startWork: '',
    endWorkMonthType: { item: '', value: 0 },
    endWorkMonth: '',
    endWork: '',
  }

  export const EducationPattern = {
    name: '',
    location: '',
    educationForm: 1,
    educationFormType: { item: '', value: 0 },
    yearEntered: 0,
    yearEnded: 0,
    faculty: '',
    directionTraining: '',
    speciality: '',
    successes: '',
  }

  export const ForeignLanguagePattern = {
    name: '',
    level: 0,
    levelType: { item: '', value: 0 },
  }

  export enum Messages {
    SUCCESS_NEW = 'Новый кандидат успешно добавлен',
    SUCCESS_EDIT = 'Кандидат успешно изменен',
    ERROR_NEW = 'Произошла ошибка при добавлении нового кандидата',
    ERROR_EDIT = 'Произошла ошибка при изменении кандидата',
    ERROR_FETCH = 'Произошла ошибка при получении данных кандидата',
  }

  export const PHONE_LENGTH = 10
}
