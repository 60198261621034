import { InputWithButtonWrapperTypes as T } from './types'
import { InputWithButtonWrapperStyles as ST } from './styled'

export const InputWithButtonWrapper: FC<T.Props> = ({
  buttonText,
  buttonUrl,
  buttonAction,
  children,
}) => {
  return (
    <ST.Wrapper>
      {buttonUrl ? (
        <ST.LinkButton href={buttonUrl} onClick={buttonAction} target="_blank">
          {buttonText}
        </ST.LinkButton>
      ) : (
        <ST.Button onClick={buttonAction}>{buttonText}</ST.Button>
      )}

      {children}
    </ST.Wrapper>
  )
}
