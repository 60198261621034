import React, { ElementRef, useEffect, useMemo, useRef, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { handlerError } from 'utils/handlerError'
import { Placeholders } from 'constants/placeholders'
import { FormikFields } from 'constants/formikFields'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { BenefitOperationTypesLabels } from 'constants/benefitOperationTypesDescription'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import { editBenefit } from 'api/benefits'
import { BenefitOperationTypes, BenefitTypes } from 'types/model/benefit'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseInput from 'components/ui/inputs/BaseInput'
import { BenefitsNS } from 'i18n/types'

interface IDeniedAccess {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  benefitType: BenefitTypes.Model
}

const EditBenefitType = ({
  show,
  onClose,
  showEndModal,
  benefitType,
}: IDeniedAccess) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_BENEFIT,
    I18nNamespaces.BENEFITS,
    I18nNamespaces.TRANSLATION
  )
  const { RequiredFields } = useRequiredFields()

  const operationTypes = useMemo<IItem<BenefitOperationTypes>[]>(() => {
    const types = t<BenefitsNS['operationTypes']>('benefits:operationTypes')

    return Object.entries(types).map(([key, value]) => ({
      item: value,
      value: +key,
    }))
  }, [t])

  const handleClose = (): void => {
    onClose(!show)
  }
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modalRef = useRef<ElementRef<'div'>>(null)

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik({
      initialValues: benefitType
        ? {
            ...benefitType,
            operationTypeData: {
              value: benefitType.operationType,
              item: BenefitOperationTypesLabels[benefitType.operationType],
            },
          }
        : {
            id: 0,
            type: '',
            operationType: 0,
            operationTypeData: {
              value: 0,
              item: '',
            },
          },
      onSubmit: () => {
        setIsLoading(true)
        editBenefit(values.id, values)
          .then(() => {
            showEndModal?.(true)
            handleClose()
          })
          .finally(() => {
            setIsLoading(false)
          })
      },
      validationSchema: Yup.object().shape({
        type: Yup.string().trim().required(RequiredFields.base),
        operationType: Yup.number().required(RequiredFields.base),
      }),
    })

  const isValid = useMemo(() => !Object.keys(errors).length, [errors])

  useEffect(() => {
    setSubmitBtnDisabled(!isValid)
  }, [isValid])

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{t('edit_modal_title')}</ST.ModalTitle>
          <BaseInput
            placeholder={Placeholders.type}
            id={FormikFields.type}
            name={FormikFields.type}
            value={values.type}
            onChange={handleChange}
            label={t('benefit_type')}
            error={errors.type}
            required
          />
          <BaseSelect
            label={t('type')}
            error={errors.operationType}
            isSmallSelect={false}
            placeHolder={t('select_type')}
            listItems={operationTypes}
            name={'operationTypeData'}
            value={values?.operationTypeData?.item ?? ''}
            typeSelect={'operationTypeData'}
            onChange={(value) => {
              setFieldValue('operationTypeData', value)
              setFieldValue('operationType', value?.value)
            }}
            required
          />

          <ST.Button
            disabled={submitBtnDisabled}
            onClick={checkForm}
            type={'submit'}
            isDisabled={submitBtnDisabled}
          >
            {t('translation:save')}
          </ST.Button>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default EditBenefitType
