import { apiInstance } from 'api'
import {
  HardwareTypes,
  HardwareTypeTypeRequest,
  IHardwareValuesFilter,
} from 'types/model/hardware'

import { ExtendedResponseType } from 'types/utils'

export const getHardwareTypes = async (
  page?: number,
  parameters?: IHardwareValuesFilter
): Promise<ExtendedResponseType<HardwareTypes.HardwareType>> => {
  const pageSize: number = 50
  let pageNumber: number
  if (page) {
    pageNumber = page
  } else {
    pageNumber = 1
  }
  const params = new URLSearchParams()
  params.append('pageSize', String(pageSize))
  params.append('pageNumber', String(pageNumber))

  if (parameters) {
    for (let [key, value] of Object.entries(parameters)) {
      value && params.append(key, value)
    }
  }

  return apiInstance
    .get(`hardwareTypes?${params.toString()}`)
    .then((resp) => resp.data)
}

export const createHardwareType = async (
  obj: HardwareTypeTypeRequest
): Promise<HardwareTypes.HardwareType> => {
  return apiInstance
    .post<HardwareTypes.HardwareType>('hardwareTypes', { ...obj })
    .then((resp) => resp.data)
}

export const editHardwareType = async (
  hardwareTypeId: number,
  hardwareType: HardwareTypeTypeRequest
): Promise<HardwareTypes.HardwareType> => {
  return apiInstance
    .patch<HardwareTypes.HardwareType>(
      `hardwareTypes/${hardwareTypeId}`,
      hardwareType
    )
    .then((resp) => resp.data)
}

export const removeHardwareType = async (hardwareId: number) => {
  return apiInstance.delete<HardwareTypes.HardwareType>(
    `hardwareTypes/${hardwareId}`
  )
}
