import React from 'react'
import { EmptyResult as ST } from './styled'
import { EmptyResultTypes as T } from './types'
import { ReactComponent as NotFound } from 'assets/images/notFound.svg'
import BaseButton from 'components/ui/buttons/BaseButton'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

const defaultButtonSize = {
  width: 277,
  height: 50,
}

const EmptyResult: FC<T.Props> = ({
  icon,
  title,
  description,
  orientation = 'vertical',
  style,
  primaryButton,
  secondaryButton,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.MESSAGES)

  const descriptionValue =
    description ?? t('info_messages:not_found_description')

  const titleValue = title ?? t('info_messages:not_found_default')

  return (
    <ST.Content orientation={orientation} style={style}>
      <ST.NotFoundContainer
        imageAlign={
          titleValue || descriptionValue
            ? orientation === 'vertical'
              ? 'center'
              : 'right'
            : 'center'
        }
      >
        {icon ?? <NotFound />}
      </ST.NotFoundContainer>
      {(titleValue || descriptionValue) && (
        <ST.TextContainer>
          {titleValue && <ST.Title>{titleValue}</ST.Title>}
          {descriptionValue && (
            <ST.Description>{descriptionValue}</ST.Description>
          )}
        </ST.TextContainer>
      )}

      {(primaryButton || secondaryButton) && (
        <ST.ButtonsContainer>
          {primaryButton &&
            (!!primaryButton.href ? (
              <ST.LinkButton href={primaryButton.href}>
                {primaryButton.text}
              </ST.LinkButton>
            ) : (
              <BaseButton
                {...defaultButtonSize}
                text={primaryButton.text}
                onClick={primaryButton.action}
              />
            ))}
          {secondaryButton &&
            (!!secondaryButton.href ? (
              <ST.LinkButton href={secondaryButton.href} typeButton="secondary">
                {secondaryButton.text}
              </ST.LinkButton>
            ) : (
              <BaseButton
                {...defaultButtonSize}
                typeButton="secondary"
                text={secondaryButton.text}
                onClick={secondaryButton.action}
              />
            ))}
        </ST.ButtonsContainer>
      )}
    </ST.Content>
  )
}

export default EmptyResult
