import React, { useCallback, useEffect, useState } from 'react'
import * as ST from './styled'
import StaticBenefitTypesTabContent from './StaticBenefitTypesTabContent'
import { BenefitTypes } from 'types/model/benefit'
import { getBenefits } from 'api/benefits'
import { handlerError } from 'utils/handlerError'
import CreateBenefit from 'components/dialogs/CreateBenefit'
import { useModalWindowState } from 'hooks/useModalWindowState'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseButton from 'components/ui/buttons/BaseButton'

interface IUserProps {
  isEditing: boolean
}

const BenefitTab: FC<IUserProps> = ({ isEditing }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_BENEFIT)
  const { modalOpen, toggleModal } = useModalWindowState()
  const [benefitsList, setBenefitsList] = useState<BenefitTypes.Model[]>([])

  const updateBenefits = useCallback(() => {
    getBenefits()
      .then((data) => setBenefitsList(data.data))
      .catch((err) => handlerError(err))
  }, [])

  useEffect(() => {
    updateBenefits()
  }, [updateBenefits])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>{t('title')}</ST.EditingHeader>
          </ST.BackBlock>
          <BaseButton text={t('add')} width={220} onClick={toggleModal} />
        </ST.ManageBlock>
      )}
      {!!benefitsList.length && (
        <StaticBenefitTypesTabContent
          benefits={benefitsList}
          updateBenefits={updateBenefits}
        />
      )}
      {modalOpen && (
        <CreateBenefit
          onClose={toggleModal}
          showEndModal={updateBenefits}
          show
        />
      )}
    </ST.MainContainer>
  )
}

export default BenefitTab
