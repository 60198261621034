import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import * as ST from './styled'
import { SettingsSteps } from 'constants/settingsSteps'
import { getSettingSectionByType } from 'api/settings'
import { HiringStep } from 'types/model/hiring'
import StaticHiringStepsTabContent from './StaticHiringStepsTabContent'
import EditingHiringStepsTabContent from './EditingHiringStepsTabContent'
import { setToastError } from 'utils/handlerError'
import Loader from 'components/ui/Loader'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseButton from 'components/ui/buttons/BaseButton'

interface IUserProps {
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  step: SettingsSteps
}

const HiringStepsTab: FC<IUserProps> = ({ setIsEditing, isEditing, step }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_HIRING_STAGES)
  const [hiringSteps, setHiringSteps] = useState<HiringStep[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const updateSteps = useCallback(() => {
    setIsLoading(true)
    getSettingSectionByType<HiringStep[]>(step)
      .then(setHiringSteps)
      .catch(setToastError)
      .finally(() => {
        setIsLoading(false)
      })
  }, [step])

  const handleIsEditing = () => {
    setIsEditing((prevState) => !prevState)
    updateSteps()
  }

  useEffect(() => {
    updateSteps()
  }, [updateSteps])

  return (
    <ST.MainContainer>
      {!isEditing && (
        <ST.ManageBlock>
          <ST.BackBlock>
            <ST.EditingHeader>{t('title')}</ST.EditingHeader>
          </ST.BackBlock>
          <BaseButton
            text={t('edit')}
            onClick={handleIsEditing}
            disabled={isLoading}
            width={220}
            height={40}
          />
        </ST.ManageBlock>
      )}
      {isLoading ? (
        <Loader marginTop="40px" />
      ) : isEditing ? (
        <EditingHiringStepsTabContent
          handleIsEditing={handleIsEditing}
          isLoading={isLoading}
          updateSteps={updateSteps}
          hiringSteps={hiringSteps}
        />
      ) : (
        <StaticHiringStepsTabContent
          hiringSteps={hiringSteps}
          updateSteps={updateSteps}
        />
      )}
    </ST.MainContainer>
  )
}

export default HiringStepsTab
