import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/close.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetupInterceptors } from 'hooks'
import Contacts from 'assets/icons/Header/contacts.svg'
import Reviews from 'assets/icons/Header/reviews.svg'
import Settings from 'assets/icons/Header/settings.svg'
import Exit from 'assets/icons/Header/exit.svg'
import ExitDialog from 'components/dialogs/exitDialog'
import { PATHS } from 'constants/paths'
import NumberBadge, { NumberBadgeType } from 'components/ui/badges/NumberBadge'
import { useDispatch } from 'react-redux'
import { useAuth } from 'hooks/useAuth'
import { useGetUserInfoQuery } from 'store/api/user'
import { setUserInfo } from 'store/actions/userInfo'
import { AuthActionCreators } from 'store/actions/auth'
import Applications from 'assets/icons/Header/applications.svg'
import Benefits from 'assets/icons/Header/benefits.svg'
import Vacancies from 'assets/icons/Header/vacancies.svg'
import Hardware from 'assets/icons/Header/hardware.svg'
import ActivityLog from 'assets/icons/Header/activityLog.svg'
import checkRole from 'utils/profile/checkRole'
import { MenuData as Data } from './data'
import { MenuTypes as Types } from './types'
import AvatarImage from 'components/ui/image/Avatar'
import getFileData from 'utils/file/getFileData'
import { baseApi } from 'store/api/baseApi'
import LocaleSwitch from 'components/i18n/LocaleSwitch'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { MenusNS } from 'i18n/types'

const DEFAULT_USER_ID = 0

const DEFAULT_IMG_USER: Types.IImgUser = { id: 0, path: '', url: '' }

const COUNTER_REFRESH_PERIOD = 15_000 // 15 seconds

const MenuItem: FC<Types.MenuItemProps> = ({
  icon,
  activePaths,
  name,
  path,
  goToPage,
  badgeValue,
  onClick,
}) => {
  const location = useLocation()

  const generateClassName = (page: string): string => {
    const className = `activeItem`

    if (location.pathname === '/') {
      return location.pathname === page ? className : ''
    }

    const locations = location.pathname.split('/').filter((l) => !!l.length)

    const activePathsValue = [...(activePaths ?? []), path]

    return locations
      .map((l) => `/${l}`)
      .some((loc) => activePathsValue?.includes(loc as PATHS))
      ? className
      : ''
  }

  return (
    <ST.Item
      onClick={onClick ?? (() => path && goToPage?.(path))}
      className={path ? generateClassName(path) : undefined}
    >
      {typeof icon === 'string' ? <ST.LinkIcon imageSrc={icon} /> : icon}
      <ST.LinkName>{name}</ST.LinkName>

      {badgeValue !== undefined && (
        <NumberBadge
          value={badgeValue}
          type={
            location.pathname === path
              ? NumberBadgeType.Secondary
              : NumberBadgeType.Primary
          }
          style={{ marginLeft: 12 }}
        />
      )}
    </ST.Item>
  )
}

const menuApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Одобрение заявки
    getCounter: builder.query<Types.Counter[], undefined>({
      query: () => ({
        url: `info/menu/counter`,
        method: 'GET',
      }),
    }),
  }),
})

const Menu: FC<Types.Props> = ({ show, onClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { token, userId, roles, isAuth } = useAuth()
  const { t } = useTypedTranslation(I18nNamespaces.MENU)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const counter: Types.Counter[] =
    menuApi.useGetCounterQuery(undefined, {
      pollingInterval: COUNTER_REFRESH_PERIOD,
      skip: !isAuth,
    })?.data ?? []

  const getCountByType = useCallback(
    (type: number): number | undefined => {
      const result = counter.find((item) => item.itemType === type)?.count
      return result === 0 ? undefined : result
    },
    [counter]
  )

  useSetupInterceptors(token)

  const [imgUser, setImgUser] = useState<Types.IImgUser>(DEFAULT_IMG_USER)
  const [exitDialogIsShow, setExitDialogIsShow] = useState(false)

  const { data: userInfo, refetch: refetchUserInfo } = useGetUserInfoQuery(
    userId ?? DEFAULT_USER_ID,
    {
      skip: userId === DEFAULT_USER_ID || !userId,
    }
  )

  useEffect(() => {
    if (userInfo) {
      dispatch(setUserInfo(userInfo))
      dispatch(AuthActionCreators.update({ role: userInfo.roles }))
      setImgUser(userInfo.userImg)
    }
  }, [dispatch, userInfo])

  const handleClose = () => {
    onClose(!show)
  }

  const handleCloseExitDialogIsShow = () => {
    setExitDialogIsShow((prevState) => !prevState)
  }

  // TODO запрос на получение ролей отправляется на каждой вкладке
  const goToPage = (url: string) => {
    handleClose()
    refetchUserInfo()
    navigate(url)
  }

  const MenuItemsList = useMemo<Types.MenuItemType[]>(
    () => [
      {
        path: PATHS.home,
        icon: <AvatarImage path={getFileData(imgUser)} size="small" />,
        name: t('home'),
      },
      {
        path: PATHS.employees,
        icon: Contacts,
        name: t('employees'),
        activePaths: ['/user' as PATHS],
      },
      {
        path: PATHS.reviews,
        icon: Reviews,
        name: t('reviews'),
        badgeValue: getCountByType(Types.MenuItem.REVIEW),
      },
      {
        name: t('applications'),
        path: PATHS.applications,
        icon: Applications,
        badgeValue: getCountByType(Types.MenuItem.APPLICATION),
      },
      {
        name: t('benefitApplication'),
        path: PATHS.benefitApplications,
        icon: Benefits,
        badgeValue: getCountByType(Types.MenuItem.BENEFIT_APPLICATION),
      },
      {
        name: t('vacancies'),
        path: PATHS.vacancies,
        icon: Vacancies,
        activePaths: [PATHS.statistic],
      },
      {
        name: t('hardware'),
        path: PATHS.hardwares,
        icon: Hardware,
      },
      {
        name: t('activityLog'),
        path: PATHS.activityLog,
        icon: ActivityLog,
      },
      {
        name: t('settings'),
        path: PATHS.settings,
        icon: Settings,
        activePaths: [PATHS.departments, PATHS.positions, PATHS.grades],
      },
    ],
    [t, getCountByType, imgUser]
  )

  return (
    <>
      <ST.MenuOverlay onClick={handleClose} isActive={show} />
      <ST.MenuContainer onClick={(e) => e.stopPropagation()} isActive={show}>
        <ST.MenuHeader>
          <LocaleSwitch />
          <ST.CloseButton onClick={handleClose}>
            <Close />
          </ST.CloseButton>
        </ST.MenuHeader>

        <ST.ListItems>
          {MenuItemsList.map(
            (item) =>
              checkRole(roles, Data.MenuItemsByRoles[item.path!]) && (
                <MenuItem key={item.path} {...item} goToPage={goToPage} />
              )
          )}

          <MenuItem
            icon={Exit}
            name={t<MenusNS['logout']>('logout').button}
            onClick={() => {
              handleClose()
              handleCloseExitDialogIsShow()
            }}
          />
        </ST.ListItems>
      </ST.MenuContainer>
      <ExitDialog
        show={exitDialogIsShow}
        onClose={handleCloseExitDialogIsShow}
      />
    </>
  )
}

export default Menu
