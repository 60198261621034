import styled from 'styled-components'
import { COLORS } from 'constants/colors'

type Props = {
  fitContent: boolean
}

export const Button = styled.button<Props>`
  border: 1px dashed ${COLORS.blue};
  width: ${({ fitContent }) => (fitContent ? 'fit-content' : 'initial')};
  background: transparent;
  color: ${COLORS.blue};
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
`
