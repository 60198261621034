import { AvatarImageStyles as ST } from './styled'
import { AvatarImageTypes as Types } from './types'
import { useEffect, useState } from 'react'
import defaultProfileImage from 'constants/defaultProfileImage'

const DEFAULT_SIZE: Types.Size = 'medium'

const AvatarImage: FC<Types.Props> = ({
  path,
  badgeColor,
  onClick,
  badgeSize,
  size = DEFAULT_SIZE,
}) => {
  const [imgPath, setImgPath] = useState<string>(path || defaultProfileImage)

  const handleError = () => {
    setImgPath(defaultProfileImage)
  }

  useEffect(() => {
    setImgPath(path || defaultProfileImage)
  }, [path])

  return (
    <ST.Container onClick={onClick} size={size} pointer={!!onClick}>
      <ST.Image src={imgPath || defaultProfileImage} onError={handleError} />
      {!!badgeColor && (
        <ST.Badge $color={badgeColor} size={badgeSize ?? size} />
      )}
    </ST.Container>
  )
}

export default AvatarImage
