import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { LetterTemplate } from 'types/model/letterTemplate'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import BaseButton from 'components/ui/buttons/BaseButton'

interface Props {
  show: boolean
  onClose: (isShow: boolean) => void
  handleConfirm?: (id: number) => void
  showEndModal?: SetState<boolean>
  selectedTemplate: LetterTemplate | null
}

const DeleteSection: FC<Props> = ({
  show,
  onClose,
  handleConfirm,
  showEndModal,
  selectedTemplate,
}) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_LETTERS,
    I18nNamespaces.TRANSLATION
  )
  const handleClose = (): void => {
    if (showEndModal) {
      showEndModal(true)
    }
    onClose(!show)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{t('delete_title')}</ST.ModalTitle>

          <ST.Description>
            {t('delete_description', { name: selectedTemplate?.name })}
          </ST.Description>

          <ST.ButtonsBlock>
            <BaseButton
              text={t('translation:delete')}
              onClick={() => {
                handleConfirm &&
                  selectedTemplate?.id &&
                  handleConfirm(selectedTemplate?.id)
                handleClose()
              }}
            />
            <BaseButton
              text={t('translation:cancel')}
              onClick={handleClose}
              typeButton="secondary"
            />
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteSection
