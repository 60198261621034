import React from 'react'
import * as ST from './styled'
import Loader from 'components/ui/Loader'

const GlobalLoader: FC = () => (
  <ST.Container>
    <Loader />
  </ST.Container>
)

export default GlobalLoader
