import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import {
  I18nNamespaces,
  SUPPORTED_LOCALES,
  SUPPORTED_LOCALES_OPTIONS,
} from 'i18n/config'
import { useTranslation } from 'react-i18next'
import { useCallback, useLayoutEffect, useMemo, useTransition } from 'react'
import { setUserLocale } from 'api/settings'
import { setToastError } from 'utils/handlerError'
import { useDispatch } from 'react-redux'
import { SystemActionCreators } from 'store/actions/system'
import moment from 'moment'
import updateAxiosBaseUrl from 'utils/url/updateAxiosBaseUrl'
import { useGetResource } from 'i18n/hooks/useGetResource'

type SupportedLocales = keyof typeof SUPPORTED_LOCALES

const listItems: IItem<SupportedLocales>[] = Object.entries(
  SUPPORTED_LOCALES
).map(([key, value]) => ({
  item: value,
  value: key as SupportedLocales,
}))

type LanguageChangeHandler = (value: IItem<SupportedLocales> | null) => void

type Props = {
  options?: IItem<SupportedLocales>[]
  disabled?: boolean
}

const LocaleSwitch: FC<Props> = ({ options, disabled = false }) => {
  const dispatch = useDispatch()
  const [isPending, startTransition] = useTransition()
  const { t, i18n } = useTranslation()
  const months = useGetResource(I18nNamespaces.TRANSLATION, 'months')

  const selectedLanguage = useMemo(
    () => listItems.find((item) => item.value === i18n.resolvedLanguage)?.item,
    [i18n.resolvedLanguage]
  )

  const handleChange = useCallback<LanguageChangeHandler>(
    async (value) => {
      if (value?.value && value.item !== selectedLanguage) {
        try {
          startTransition(() => {
            updateAxiosBaseUrl(value.value)

            void setUserLocale(value.value)
            moment.updateLocale(value.value, { months: Object.values(months) })
            dispatch(SystemActionCreators.setUserLocale(value.value))
            updateAxiosBaseUrl(value.value)
            void i18n.changeLanguage(value?.value)
          })
        } catch (e: any) {
          setToastError(e.message)
        }
      }
    },
    [dispatch, i18n, selectedLanguage]
  )

  useLayoutEffect(() => {
    const lang = i18n.resolvedLanguage as SupportedLocales

    if (lang) {
      document.documentElement.lang = lang
      dispatch(SystemActionCreators.setUserLocale(lang))
    }
  }, [dispatch, i18n.resolvedLanguage])

  return (
    <BaseSelect
      value={selectedLanguage}
      listItems={options ?? listItems}
      getOptionDisplayText={(value) => {
        const val = listItems.find((v) => v.item === value)?.value

        return val ? SUPPORTED_LOCALES_OPTIONS[val] ?? value : value ?? ''
      }}
      placeHolder={t('locale_switch_placeholder')}
      onChange={handleChange}
      disabled={isPending || disabled}
      variant="transparent"
      required
    />
  )
}

export default LocaleSwitch
