import { Resources, useTranslation } from 'react-i18next'
import { TOptions } from 'i18next'
import { I18nNamespaces } from 'i18n/config'

// Utils

type NamespacesTuple = readonly [I18nNamespaces, ...I18nNamespaces[]]

type MultipleNamespaceKey<T extends I18nNamespaces> = `${T}:${Extract<
  TKeys<T>,
  string
>}`

type ResourceType<
  T extends I18nNamespaces,
  K extends keyof Resources[T]
> = Resources[T][K]

const DEFAULT_NAMESPACES: Readonly<NamespacesTuple> = [
  I18nNamespaces.TRANSLATION,
] as const

// Types

export type TKeys<T extends I18nNamespaces = (typeof DEFAULT_NAMESPACES)[0]> =
  T extends NamespacesTuple ? keyof Resources[T[number]] : keyof Resources[T]

export type TFunctionTyped<
  T extends NamespacesTuple = typeof DEFAULT_NAMESPACES
> = <R = string>(
  str: TKeys<T[number]> | MultipleNamespaceKey<T[number]>,
  params?: TOptions
) => R

export type TranslationResponse<
  T extends NamespacesTuple = typeof DEFAULT_NAMESPACES
> = {
  t: TFunctionTyped<T>
} & Omit<ReturnType<typeof useTranslation>, 't'>

// TODO сделать дефолтные ns глобально общими
// TODO типизировать возвращаемые объекты
/**
 * Обертка над хуком {@link useTranslation}, позволяющая типизировать значения в рамках проекта
 *
 * Есть проблема с возвращаемыми типами вложенных объектов, для таких случаев лучше использовать {@link useGetResource}
 * <br/>
 * Использование:
 * ```ts
 * const { t } = useTypedTranslation(I18nNamespaces.PROFILE, I18nNamespaces.REVIEWS)
 *
 * // Получение значений по ключам из первого namespace
 * const title = t('title')
 * // Получение ключей определенных в параметрах хука namespace'ов, начиная со второго
 * const reviewsTitle = t('reviews:title')
 * // Получение типизированных значений вложенных объектов (TODO можно улучшить это)
 * const reviews = t<ProfileNS['tabs']>('tabs')
 * ```
 *
 * @template T extends {@link I18nNamespaces}
 * @param ns - массив namespace'ов для локализации
 * */
export const useTypedTranslation = <
  T extends NamespacesTuple = typeof DEFAULT_NAMESPACES
>(
  ...ns: T
): TranslationResponse<T> => {
  return useTranslation(ns) as TranslationResponse<T>
}
