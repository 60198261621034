import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { MessagesNS } from 'i18n/types'

type Response = {
  RequiredFields: MessagesNS['required_fields']
}

export const useRequiredFields = (): Response => {
  const { t } = useTypedTranslation(I18nNamespaces.MESSAGES)

  return { RequiredFields: t<MessagesNS['required_fields']>('required_fields') }
}
