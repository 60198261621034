import { createContext, useContext } from 'react'
import { ROLES } from 'constants/roles'
import { UserTypes } from 'types/model/user'
import i18next from 'i18next'
import { TFunctionTyped } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

export namespace ProfilePageTypes {
  export type Props = {
    isActiveTab: boolean
    userIdFromUrl: string | undefined
  }

  export type ProfilePageContextType = {
    roles: ROLES[]
    userId: number
    userData: UserTypes.UserInfo | null
    isCurrentUser: boolean
    isBossToCurrentUser?: boolean
    updateUserData?: () => void
    t: TFunctionTyped<[I18nNamespaces.PROFILE]>
  }

  export const defaultContextValue: ProfilePageContextType = {
    roles: [],
    userId: 0,
    isCurrentUser: false,
    userData: null,
    isBossToCurrentUser: false,
    t: i18next.t as TFunctionTyped<[I18nNamespaces.PROFILE]>,
  }

  export const ProfilePageContext =
    createContext<ProfilePageContextType>(defaultContextValue)

  export const useProfilePageContext = (
    defaults?: Partial<ProfilePageContextType>
  ) => {
    const context = useContext(ProfilePageContext)

    return context ?? createContext(defaults)
  }
}
