import { AuthAction, AuthActionTypes, AuthState } from 'types/authType'
import { LocalStorageUtils } from 'utils/localStorage'

const initialState: AuthState = {
  accessToken: '',
  role: [],
  isAuthorised: false,
}

export const authReducer = (
  state = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      const { payload } = action

      const newStateObj = {
        accessToken: payload.accessToken,
        role: payload.role,
        isAuthorised: true,
      }

      localStorage.setItem('auth', JSON.stringify(newStateObj))

      return {
        ...state,
        accessToken: newStateObj.accessToken,
        isAuthorised: newStateObj.isAuthorised,
        role: newStateObj.role,
      }
    }
    case AuthActionTypes.LOGOUT: {
      LocalStorageUtils.clear()
      sessionStorage.clear()

      return {
        ...state,
        accessToken: initialState.accessToken,
        role: initialState.role,
        isAuthorised: initialState.isAuthorised,
      }
    }
    case AuthActionTypes.UPDATE: {
      const newState: AuthState = { ...state, ...(action.payload ?? {}) }

      localStorage.setItem('auth', JSON.stringify(newState))

      return newState
    }
    case AuthActionTypes.SET_ABORT:
      return { ...state, abortLoginController: action.payload }
    case AuthActionTypes.ABORT:
      state.abortLoginController?.abort()
      return { ...state, abortLoginController: null }
    default:
      return state
  }
}
