import { VacanciesNotFoundStyles as ST } from './styled'

import EmptyResult from 'components/views/feedback/EmptyResult'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

export const VacanciesNotFound: FC = () => {
  const { t } = useTypedTranslation(
    I18nNamespaces.MESSAGES,
    I18nNamespaces.VACANCIES
  )
  return (
    <ST.Wrapper>
      <EmptyResult
        title={t('not_found', { entityName: t('vacancies:title') })}
        description={t('not_found_description')}
      />
    </ST.Wrapper>
  )
}
