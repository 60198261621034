import React, { useMemo, useState } from 'react'
import * as ST from './styled'
import { useFormik } from 'formik'
import {
  handlerError,
  setToastError,
  setToastSuccess,
} from 'utils/handlerError'
import { ISetting, SettingsKeys } from 'types/model/settings'
import { getSettingByKey } from 'utils/parseData'
import { putSetting } from 'api/settings'
import { ErrorMessage } from 'components/ui/ErrorMessage'
import * as Yup from 'yup'
import BaseButton from 'components/ui/buttons/BaseButton'
import BaseInput from 'components/ui/inputs/BaseInput'
import BackButton from 'components/ui/buttons/BackButton'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { useGetResource } from 'i18n/hooks/useGetResource'
import { IntegrationsSettingsTypes as T } from 'components/settings/Tabs/Integrations/EditingIntegrationsTabContent/EditingTabs/types'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'

interface Props {
  handleIsEditing: () => void
  setIsEditing: SetState<boolean>
  isEditingTabs: boolean
  data: ISetting[]
}

type IForm = Record<
  | 'telegram_review_chat_id'
  | 'telegram_admin_chat_id'
  | 'telegram_name'
  | 'telegram_access_chat_id'
  | 'bot_telegram_token'
  | 'telegram_bot_nick',
  string | undefined
>

const TelegramTab: FC<Props> = ({ handleIsEditing, data }) => {
  const translate = useGetResource(I18nNamespaces.SETTINGS_INTEGRATIONS, 'edit')
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_INTEGRATIONS)
  const { RequiredFields } = useRequiredFields()

  const inputsData = useMemo<T.InputData[]>(
    () => [
      {
        label: translate.name.label,
        placeholder: translate.name.placeholder,
        field: 'telegram_name',
      },
      {
        label: translate.nickname,
        placeholder: translate.nickname,
        field: 'telegram_bot_nick',
      },
      {
        label: translate.token,
        placeholder: translate.token,
        field: 'bot_telegram_token',
      },
      {
        label: t('admin_chat'),
        placeholder: t('admin_chat'),
        field: 'telegram_admin_chat_id',
      },
      {
        label: t('access_chat'),
        placeholder: t('access_chat'),
        field: 'telegram_access_chat_id',
      },
      {
        label: t('review_chat'),
        placeholder: t('review_chat'),
        field: 'telegram_review_chat_id',
      },
    ],
    [t, translate]
  )

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const onSubmit = async () => {
    const filteredSettings = data.filter((setting) =>
      [
        SettingsKeys.TELEGRAM_REVIEW_CHAT_ID,
        SettingsKeys.TELEGRAM_ADMIN_CHAT_ID,
        SettingsKeys.TELEGRAM_ACCESS_CHAT_ID,
        SettingsKeys.TELEGRAM_TOKEN,
        SettingsKeys.TELEGRAM_NICK,
        SettingsKeys.TELEGRAM_NAME,
      ].includes(setting.name as SettingsKeys)
    )

    // eslint-disable-next-line array-callback-return
    const settingsPromises = filteredSettings.map((setting) => {
      switch (setting.name) {
        case SettingsKeys.TELEGRAM_REVIEW_CHAT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.telegram_review_chat_id,
          })
        case SettingsKeys.TELEGRAM_ADMIN_CHAT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.telegram_admin_chat_id,
          })
        case SettingsKeys.TELEGRAM_ACCESS_CHAT_ID:
          return putSetting(setting.id, {
            ...setting,
            value: values.telegram_access_chat_id,
          })
        case SettingsKeys.TELEGRAM_TOKEN:
          return putSetting(setting.id, {
            ...setting,
            value: values.bot_telegram_token,
          })
        case SettingsKeys.TELEGRAM_NICK:
          return putSetting(setting.id, {
            ...setting,
            value: values.telegram_bot_nick,
          })
        case SettingsKeys.TELEGRAM_NAME:
          return putSetting(setting.id, {
            ...setting,
            value: values.telegram_name,
          })
      }
    })

    setButtonDisabled(true)
    Promise.all(settingsPromises)
      .then(() => {
        handleIsEditing()
        setToastSuccess(translate.saved)
      })
      .catch((error) => {
        setToastError(error)
      })
      .finally(() => {
        setButtonDisabled(false)
      })
  }

  const { handleSubmit, handleChange, values, errors, isValid } =
    useFormik<IForm>({
      enableReinitialize: true,
      initialValues: {
        telegram_review_chat_id: getSettingByKey(
          data,
          SettingsKeys.TELEGRAM_REVIEW_CHAT_ID
        )?.value,
        telegram_admin_chat_id: getSettingByKey(
          data,
          SettingsKeys.TELEGRAM_ADMIN_CHAT_ID
        )?.value,
        telegram_name: getSettingByKey(data, SettingsKeys.TELEGRAM_NAME)?.value,
        telegram_access_chat_id: getSettingByKey(
          data,
          SettingsKeys.TELEGRAM_ACCESS_CHAT_ID
        )?.value,
        bot_telegram_token: getSettingByKey(data, SettingsKeys.TELEGRAM_TOKEN)
          ?.value,
        telegram_bot_nick: getSettingByKey(data, SettingsKeys.TELEGRAM_NICK)
          ?.value,
      },
      onSubmit,
      validationSchema: Yup.object().shape({
        telegram_review_chat_id: Yup.string()
          .trim()
          .required(RequiredFields.base),
        telegram_admin_chat_id: Yup.string()
          .trim()
          .required(RequiredFields.base),
        telegram_name: Yup.string().trim().required(RequiredFields.base),
        telegram_access_chat_id: Yup.string()
          .trim()
          .required(RequiredFields.base),
        bot_telegram_token: Yup.string().trim().required(RequiredFields.base),
        telegram_bot_nick: Yup.string().trim().required(RequiredFields.base),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return (
    <>
      <ST.ManageBlock>
        <ST.BackBlock>
          <BackButton
            onClick={() => {
              handleIsEditing()
            }}
          />
          <ST.EditingHeader>{translate.title}</ST.EditingHeader>
          {!isValid && <ErrorMessage message={RequiredFields.base2} />}
        </ST.BackBlock>
        <BaseButton
          typeButton="primary"
          text={translate.save}
          onClick={checkForm}
          disabled={!isValid || buttonDisabled}
          width={220}
          height={40}
        />
      </ST.ManageBlock>
      <ST.BlockInfo>
        {inputsData.map(({ label, placeholder, field }) => (
          <ST.BottomWrapper key={field}>
            <BaseInput
              label={label}
              placeholder={placeholder}
              id={field}
              name={field}
              value={values[field as keyof IForm]}
              onChange={handleChange}
            />
          </ST.BottomWrapper>
        ))}
      </ST.BlockInfo>
    </>
  )
}

export default TelegramTab
