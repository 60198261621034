import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as ST from './styled'
import { DepartmentTypes } from 'types/model/department'
import DeleteSection from 'components/dialogs/DeleteSection'
import { deleteDepartment } from 'api/department'
import { handlerError } from 'utils/handlerError'
import { ModalTitles } from 'constants/modalTitles'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import { useModalWindowState } from 'hooks/useModalWindowState'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import getFileData from 'utils/file/getFileData'
import AvatarImage from 'components/ui/image/Avatar'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  departmentInfo: DepartmentTypes.Model
  updateDepartments?: () => void
}

const DepartmentBlock: FC<Props> = ({ departmentInfo, updateDepartments }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_DEPARTMENTS)
  const navigate = useNavigate()
  const isTabletDevice = useMediaQuery(`(max-width: ${BreakPoints.TABLETS})`)
  const { modalOpen, toggleModal } = useModalWindowState()

  const goToDepartment = (id: number) => {
    navigate(`/departments/${id}`)
  }

  const goToEditDepartment = (id: number) => {
    navigate(`/departments/${id}/edit`)
  }

  const removeItem = async () => {
    await deleteDepartment(departmentInfo.id)
      .then(() => {
        if (updateDepartments) {
          updateDepartments()
        }
      })
      .catch((err) => handlerError(err))
  }

  const directorName = `${departmentInfo.director?.name} ${departmentInfo.director?.surname}`

  return (
    <>
      <ST.DepartmentBlock onClick={() => goToDepartment(departmentInfo.id)}>
        <ST.InfoWrapper>
          {isTabletDevice && <ST.Label>{t('dept')}</ST.Label>}
          <ST.Title title={departmentInfo.name}>{departmentInfo.name}</ST.Title>
        </ST.InfoWrapper>
        <ST.InfoWrapper>
          {isTabletDevice && <ST.Label>{t('boss')}</ST.Label>}
          {departmentInfo.director ? (
            <ST.Boss to={`/user/${departmentInfo.director?.id}`}>
              <AvatarImage
                path={getFileData(departmentInfo.director.userImg)}
                size={30}
              />
              <ST.BossName title={directorName}>{directorName}</ST.BossName>
            </ST.Boss>
          ) : (
            <ST.EmptyDirector>{t('unset')}</ST.EmptyDirector>
          )}
        </ST.InfoWrapper>
        <ST.InfoWrapper>
          {isTabletDevice && <ST.Label>{t('employees')}</ST.Label>}
          <ST.Title>{departmentInfo.employeesCount}</ST.Title>
        </ST.InfoWrapper>
        <ST.ButtonsBlock>
          <IconButton
            icon={EditIcon}
            onClick={(e) => {
              e.stopPropagation()
              goToEditDepartment(departmentInfo.id)
            }}
          />
          <IconButton
            icon={DeleteIcon}
            onClick={(e) => {
              e.stopPropagation()
              toggleModal()
            }}
          />
        </ST.ButtonsBlock>
      </ST.DepartmentBlock>
      <DeleteSection
        title={ModalTitles.departmentDeleting}
        show={modalOpen}
        onClose={toggleModal}
        handleConfirm={removeItem}
      />
    </>
  )
}

export default DepartmentBlock
