import React, { useCallback, useMemo, useState } from 'react'
import * as ST from './styled'
import { HiringStep, NotDeletableReasons } from 'types/model/hiring'
import { ReactComponent as DeleteButton } from 'assets/icons/Delete.svg'
import { useMediaQuery } from '@mui/material'
import { BreakPoints } from 'constants/breakPoints'
import DeleteHiringStep from 'components/dialogs/Hiring/DeleteHiringStep'
import IconButton from 'components/ui/buttons/IconButton'
import { RolesNames } from 'constants/roles'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces, SupportedLocalesValues } from 'i18n/config'
import { SettingsLocales } from 'i18n/types'
import { HiringStepsEnum } from 'constants/settingsSteps'

interface IUserProps {
  hiringSteps: HiringStep[]
  updateSteps: () => void
}

const StaticHiringStepsTabContent: FC<IUserProps> = ({
  updateSteps,
  hiringSteps,
}) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTypedTranslation(
    I18nNamespaces.SETTINGS_HIRING_STAGES,
    I18nNamespaces.TRANSLATION
  )
  const isNotebookDevice = useMediaQuery(`(max-width: ${BreakPoints.NOTEBOOK})`)
  const [selectedStep, setSelectedStep] = useState<HiringStep | null>(null)
  const [showModalDeleteStage, setShowModalDeleteStage] =
    useState<boolean>(false)

  const handleShowModalDeleteStage = useCallback((): void => {
    setShowModalDeleteStage((prev) => !prev)
  }, [])

  const { actionsList, noData, no } = useMemo(
    () => ({
      actionsList: t<SettingsLocales.HiringStages['actions']>('actions'),
      noData: t('translation:no_data'),
      no: t('no'),
    }),
    [t]
  )

  const getActionFromList = useCallback(
    (action: HiringStepsEnum | null): string =>
      action ? actionsList[String(action) as keyof typeof actionsList] : noData,
    [actionsList, noData]
  )

  return isNotebookDevice ? (
    <ST.StepsNotebookContainer>
      {hiringSteps.map((step) => (
        <ST.StepCard key={step.id}>
          <ST.StepInfoRow>
            <ST.StepCardTitle>{t('name')}:</ST.StepCardTitle>
            <ST.StepCardValue>{step.name}</ST.StepCardValue>
          </ST.StepInfoRow>
          <ST.StepInfoRow>
            <ST.StepCardTitle>{t('notify')}</ST.StepCardTitle>
            <ST.StepCheckbox
              type={'checkbox'}
              checked={step.isNotifyCustomer ?? false}
            />
          </ST.StepInfoRow>
          <ST.StepInfoRow>
            <ST.StepCardTitle>{t('active')}:</ST.StepCardTitle>
            <ST.StepCheckbox type={'checkbox'} checked={step.isActive} />
          </ST.StepInfoRow>
          <ST.StepInfoRow>
            <ST.StepCardTitle>{t('action')}:</ST.StepCardTitle>
            <ST.StepCardValue>
              {getActionFromList(step.action)}
            </ST.StepCardValue>
          </ST.StepInfoRow>
          <DeleteButton />
        </ST.StepCard>
      ))}
    </ST.StepsNotebookContainer>
  ) : (
    <ST.StaticHiringTable>
      <ST.StaticHiringThead>
        <ST.StaticHiringTRTitle>
          <ST.StaticHiringTH
            style={{
              width:
                resolvedLanguage === SupportedLocalesValues.ru ? '19%' : '14%',
            }}
          >
            {t('name')}
          </ST.StaticHiringTH>
          <ST.StaticHiringTH
            style={{
              textAlign: 'center',
              width:
                resolvedLanguage === SupportedLocalesValues.ru ? '12%' : '14%',
              padding: '0 0 0 60px',
            }}
          >
            {t('notify')}
          </ST.StaticHiringTH>
          <ST.StaticHiringTH style={{ textAlign: 'center' }}>
            {t('active')}
          </ST.StaticHiringTH>
          <ST.StaticHiringTH
            style={{
              width:
                resolvedLanguage === SupportedLocalesValues.ru ? '25%' : '20%',
            }}
          >
            {t('action')}
          </ST.StaticHiringTH>
          <ST.StaticHiringTH></ST.StaticHiringTH>
        </ST.StaticHiringTRTitle>
      </ST.StaticHiringThead>
      <ST.StaticHiringTBody>
        {hiringSteps.map((step) => {
          const notificationsData: RolesNames[] = []

          if (step.isNotifyCustomer) notificationsData.push(RolesNames.customer)
          if (step.isNotifyRecruiter)
            notificationsData.push(RolesNames.recruiter)

          return (
            <ST.StaticHiringTR key={step.id}>
              <ST.StaticHiringTD>
                <ST.StaticHiringTDText>{step.name}</ST.StaticHiringTDText>
              </ST.StaticHiringTD>
              <ST.StaticHiringTD style={{ textAlign: 'center' }}>
                {notificationsData?.length ? notificationsData.join(', ') : no}
              </ST.StaticHiringTD>
              <ST.StaticHiringTD style={{ textAlign: 'center', padding: 0 }}>
                <ST.Checkbox type={'checkbox'} checked={step.isActive} />
              </ST.StaticHiringTD>
              <ST.StaticHiringTD>
                <ST.StaticHiringTDText>
                  {getActionFromList(step.action)}
                </ST.StaticHiringTDText>
              </ST.StaticHiringTD>
              <ST.StaticHiringTD>
                <IconButton
                  icon={DeleteButton}
                  onClick={() => {
                    if (
                      step.notDeletableReason !==
                      NotDeletableReasons.BASIC_ACTION
                    ) {
                      setSelectedStep(step)
                      setShowModalDeleteStage((prevState) => !prevState)
                    }
                  }}
                  disabled={
                    step.notDeletableReason === NotDeletableReasons.BASIC_ACTION
                  }
                />
              </ST.StaticHiringTD>
            </ST.StaticHiringTR>
          )
        })}
      </ST.StaticHiringTBody>
      {!!selectedStep && !!hiringSteps.length && showModalDeleteStage && (
        <DeleteHiringStep
          show={showModalDeleteStage}
          onClose={handleShowModalDeleteStage}
          step={selectedStep}
          showEndModal={updateSteps}
          setHiringSteps={() => updateSteps()}
          hiringSteps={hiringSteps}
        />
      )}
    </ST.StaticHiringTable>
  )
}

export default StaticHiringStepsTabContent
