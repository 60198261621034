import React from 'react'
import * as ST from './styled'
import AvatarImage from 'components/ui/image/Avatar'
import { TFunctionTyped } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  photo?: string
  name?: string
  id: number
  t: TFunctionTyped<[I18nNamespaces.BENEFITS]>
}

const AuthorLine: FC<Props> = ({ photo, name, id, t }) => (
  <ST.AuthorLine>
    <ST.Label>{t('author')}</ST.Label>
    <AvatarImage path={photo} size="small" />
    <ST.Name to={`/user/${id}`} maxWidth={400} title={name}>
      {name}
    </ST.Name>
  </ST.AuthorLine>
)

export default AuthorLine
