import React from 'react'
import * as ST from './styled'
import { BreakPoints } from 'constants/breakPoints'
import { Link } from 'react-router-dom'

interface Props {
  position: string
  grade: string
  id: number
}

const AuthorLine: FC<Props> = ({ position, grade, id }) => (
  <Link to={`/vacancies/${id}`} target={'_blank'}>
    <ST.AuthorLine>
      <ST.Label
        title={position}
        maxWidth={400}
        mediaMaxWidth={{ [BreakPoints.NOTEBOOK]: 300 }}
      >
        {position},{' '}
      </ST.Label>
      <ST.Label>{grade}</ST.Label>
    </ST.AuthorLine>
  </Link>
)

export default AuthorLine
