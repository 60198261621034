import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const SUPPORTED_LOCALES = {
  ru: 'Русский',
  en: 'English',
} as const

export const SUPPORTED_LOCALES_OPTIONS = {
  ru: 'РУС',
  en: 'ENG',
}

export type SupportedLocales = keyof typeof SUPPORTED_LOCALES

export const SupportedLocalesValues = Object.keys(SUPPORTED_LOCALES).reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: curr,
  }),
  {} as Record<SupportedLocales, SupportedLocales>
)

export enum I18nNamespaces {
  TRANSLATION = 'translation',
  PROFILE = 'profile',
  MESSAGES = 'info_messages',
  UI_COMPONENTS = 'ui-components',
  LANGUAGES = 'languages',
  REVIEWS = 'reviews',
  MENU = 'menu',
  EMPLOYEES = 'employees',
  ERROR_MESSAGES = 'error_messages',
  REQUIRED_FIELDS = 'required_fields',
  APPLICATIONS = 'applications',
  BENEFITS = 'benefits',
  NOTIFICATIONS = 'notifications',
  AUTH = 'auth',
  HARDWARE = 'hardware',
  ACTIVITY_LOG = 'activity_log',
  VACANCIES = 'vacancies',
  VACANCY = 'vacancy',
  STATISTIC = 'statistic',
  CANDIDATE_FORM = 'vacancy_candidate',
  SURVEY = 'survey',
  SURVEY_360 = 'survey360',
  EMPLOYMENT = 'employment',

  SETTINGS = 'settings',
  SETTINGS_GENERAL = 'settings_general',
  SETTINGS_CONTACTS = 'settings_contacts',
  SETTINGS_DOCUMENTS = 'settings_documents',
  SETTINGS_HIRING_STAGES = 'settings_hiring_stages',
  SETTINGS_HARDWARE = 'settings_hardware',
  SETTINGS_INTEGRATIONS = 'settings_integrations',
  SETTINGS_BENEFIT = 'settings_benefit',
  SETTINGS_LETTERS = 'settings_letter_templates',
  SETTINGS_HIRING_TYPES = 'settings_hiring_types',
  SETTINGS_DEPARTMENTS = 'settings_departments',
  SETTINGS_POSITIONS = 'settings_positions',
  SETTINGS_GRADES = 'settings_grades',
}

const SETTINGS_NAMESPACES: I18nNamespaces[] = [
  I18nNamespaces.SETTINGS,
  I18nNamespaces.SETTINGS_GENERAL,
  I18nNamespaces.SETTINGS_CONTACTS,
  I18nNamespaces.SETTINGS_DOCUMENTS,
  I18nNamespaces.SETTINGS_HIRING_STAGES,
  I18nNamespaces.SETTINGS_HARDWARE,
  I18nNamespaces.SETTINGS_INTEGRATIONS,
  I18nNamespaces.SETTINGS_BENEFIT,
  I18nNamespaces.SETTINGS_LETTERS,
  I18nNamespaces.SETTINGS_HIRING_TYPES,
  I18nNamespaces.SETTINGS_DEPARTMENTS,
  I18nNamespaces.SETTINGS_POSITIONS,
  I18nNamespaces.SETTINGS_GRADES,
]

export const I18N_LOCALSTORAGE_KEY = 'i18n:language'

const LangDetector = new LanguageDetector(null, {
  lookupLocalStorage: I18N_LOCALSTORAGE_KEY,
})

void i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(LangDetector)
  .init({
    returnObjects: true,
    fallbackLng: 'ru',
    ns: Object.values(I18nNamespaces),
    defaultNS: 'translation',
    // debug: process.env.NODE_ENV === 'development',
    supportedLngs: Object.keys(SUPPORTED_LOCALES),
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    interpolation: { escapeValue: false },
    backend: {
      backends: [HttpBackend, LocalStorageBackend],
      // TODO более универсальное решение
      backendOptions: [
        {
          loadPath: (lng: [SupportedLocales], ns: [I18nNamespaces]) => {
            const nsValue = ns[0],
              lngValue = lng[0]

            return SETTINGS_NAMESPACES.includes(nsValue)
              ? `/locales/${lngValue}/settings/${nsValue}.json`
              : '/locales/{{lng}}/{{ns}}.json'
          },
        },
      ],
    },
  })
