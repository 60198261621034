import * as ST from './styled'
import React from 'react'
import { PositionTypes } from 'types/model/position'

interface Props {
  grades: PositionTypes.Grade[]
}

const GradesLine: FC<Props> = ({ grades }) => (
  <ST.Container>
    <ST.Label>Грейды:</ST.Label>
    <ST.Tags>
      {grades.map((grade) => (
        <ST.Tag key={grade.id}>{grade.name}</ST.Tag>
      ))}
    </ST.Tags>
  </ST.Container>
)

export default GradesLine
