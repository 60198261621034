import React from 'react'
import * as ST from './styled'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import { Link } from 'react-router-dom'
import { BenefitApplicationStatuses } from 'constants/benefitApplicationStatuses'
import { setToastSuccess } from 'utils/handlerError'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as CopyIcon } from 'assets/icons/Copy.svg'
import { I18nNamespaces } from 'i18n/config'
import { TFunctionTyped } from 'i18n/hooks/useTypedTranslation'
import { BenefitOperationTypes } from 'types/model/benefit'

interface Props {
  benefitApplication: BenefitApplicationTypes.Model
  t: TFunctionTyped<[I18nNamespaces.BENEFITS, I18nNamespaces.MESSAGES]>
}

const TargetLine: FC<Props> = ({ benefitApplication, t }) => (
  <ST.TargetLine>
    <ST.InfoCard>
      <ST.CardTitle>{t('employee')}</ST.CardTitle>
      <ST.CardLines>
        <Link to={`/user/${benefitApplication.targetUser.id}`}>
          <ST.CardLine>
            {benefitApplication.targetUser?.surname}{' '}
            {benefitApplication.targetUser?.name}{' '}
            {benefitApplication.targetUser?.patronymic}
          </ST.CardLine>
        </Link>
        <ST.CardLine>
          {t('balance')}:{' '}
          {benefitApplication.targetUser?.benefitWallet?.balance.toLocaleString(
            'ru'
          ) || 0}
        </ST.CardLine>
      </ST.CardLines>
    </ST.InfoCard>
    <ST.InfoCard>
      <ST.CardTitle>
        {t(
          benefitApplication.benefit.operationType ===
            BenefitOperationTypes.refill
            ? 'refill'
            : 'withdrawal'
        )}
      </ST.CardTitle>
      <ST.CardLines>
        <ST.CardLine>
          {t('benefit_type')}: {benefitApplication.benefit?.type}
        </ST.CardLine>
        <ST.CardLine>
          {t('denomination')}:{' '}
          {benefitApplication.value.toLocaleString('ru') ?? 0}
        </ST.CardLine>
      </ST.CardLines>
    </ST.InfoCard>
    <ST.InfoCard
      fullWidth={
        benefitApplication.status !==
        BenefitApplicationStatuses.underConsideration
      }
    >
      <ST.CardTitle>{t('commentary')}</ST.CardTitle>
      <ST.CardLines>
        <ST.CardLine>{benefitApplication.commentary}</ST.CardLine>
      </ST.CardLines>
      {benefitApplication.links && benefitApplication.links.length > 0 && (
        <ST.CardLines>
          <ST.CardLine>
            {t('links')}:
            {benefitApplication.links?.map((link, index) => (
              <ST.ContainerLink key={index}>
                <ST.CardLineLink href={link} target={'_blank'}>
                  {link}
                </ST.CardLineLink>
                <IconButton
                  style={{ width: '30px', height: '30px' }}
                  onClick={() => {
                    navigator.clipboard.writeText(link).then(() => {
                      setToastSuccess(t('info_messages:copied'))
                    })
                  }}
                  icon={CopyIcon}
                  noFill
                />
              </ST.ContainerLink>
            ))}
          </ST.CardLine>
        </ST.CardLines>
      )}
    </ST.InfoCard>
    {benefitApplication.status === BenefitApplicationStatuses.rejected && (
      <ST.InfoCard>
        <ST.CardTitle>{t('reason')}</ST.CardTitle>
        <ST.CardLines>
          <ST.CardLine>{benefitApplication.reviewerCommentary}</ST.CardLine>
        </ST.CardLines>
      </ST.InfoCard>
    )}
  </ST.TargetLine>
)

export default TargetLine
