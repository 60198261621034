import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError } from 'utils/handlerError'
import { useCreateGradeMutation } from 'store/api/grade'
import BaseInput from 'components/ui/inputs/BaseInput'
import BaseButton from 'components/ui/buttons/BaseButton'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

export interface Props {
  show: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  showEndModal?: React.Dispatch<React.SetStateAction<boolean>>
}

interface ICreateGrade {
  gradeName: string
  bonus: string | null
}

const inputName = 'gradeName'

const CreateGrade: FC<Props> = ({ show, onClose, showEndModal }) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_GRADES)
  const { RequiredFields } = useRequiredFields()
  const [createGrade] = useCreateGradeMutation()
  const { handleChange, values, handleSubmit, errors, resetForm, isValid } =
    useFormik<ICreateGrade>({
      validateOnMount: true,
      initialValues: {
        gradeName: '',
        bonus: null,
      },
      onSubmit: (data, { resetForm: reset }) => {
        createGrade({
          name: data.gradeName,
          recruiterPremium: +data.bonus!.split(' ').join(''),
        })
          .then(() => {
            reset()
            handleClose()
          })
          .catch((err) => handlerError(err))
          .finally(() => {
            showEndModal?.(true)
          })
      },
      validationSchema: Yup.object().shape({
        gradeName: Yup.string().required(RequiredFields.base),
        bonus: Yup.number().nullable().required(RequiredFields.base),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  const handleClose = (): void => {
    onClose(!show)
    resetForm()
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal
        onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
      >
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{t('create_title')}</ST.ModalTitle>
          <ST.InputsBlock>
            <BaseInput
              value={values.gradeName}
              label={t('name')}
              id={inputName}
              name={inputName}
              placeholder={t('name_placeholder')}
              onChange={handleChange}
              required
            />
            <BaseInput
              thousandsGroupStyle="thousand"
              thousandSeparator=" "
              value={values.bonus ?? ''}
              type="number"
              label={t('bonus')}
              placeholder={t('bonus_placeholder')}
              id="bonus"
              name="bonus"
              onChange={handleChange}
              required
            />
          </ST.InputsBlock>
          <BaseButton
            onClick={checkForm}
            text={t('create_submit')}
            disabled={!isValid}
          />
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateGrade
