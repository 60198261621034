import React, { useMemo, useState } from 'react'
import * as ST from './styled'
import TitleLine from './TitleLine'
import AuthorLine from './AuthorLine'
import TargetLine from './TargetLine'
import BaseButton from 'components/ui/buttons/BaseButton'
import { ButtonTypes } from 'constants/buttonTypes'
import ApproveBenefitApplication from 'components/dialogs/BenefitApplicationsDialogs/ApproveBenefitApplication'
import DeniedBenefitApplication from 'components/dialogs/BenefitApplicationsDialogs/DeniedBenefitApplications'
import { BenefitApplicationTypes } from 'types/model/benefitApplication'
import EditBenefitApplication from 'components/dialogs/EditBenefitApplication'
import CancelBenefitApplication from 'components/dialogs/CancelBenefitApplication'
import { BenefitTypes } from 'types/model/benefit'
import { UserTypes } from 'types/model/user'
import { useModalWindowState } from 'hooks/useModalWindowState'
import { useAuth } from 'hooks/useAuth'
import { BenefitApplicationStatuses } from 'constants/benefitApplicationStatuses'
import { BenefitStatuses } from 'constants/benefitStatuses'
import { PopupWrapperProps } from 'components/ui/overlay/Popup'
import getFileData from 'utils/file/getFileData'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import checkRole from 'utils/profile/checkRole'
import { ROLES } from 'constants/roles'

interface Props {
  benefitApplicationInfo: BenefitApplicationTypes.Model
  userList: UserTypes.Model[]
  benefitList: BenefitTypes.Model[]
  onClose: () => void
}

type DisabledButtonsProps = Partial<{
  popupText: string
  disabled: boolean
  popupProps: Omit<PopupWrapperProps, 'text'>
}>

const baseButtonProps = {
  type: 'submit',
  width: '200px',
} as const

const BenefitApplicationCard: FC<Props> = ({
  benefitApplicationInfo,
  onClose,
  userList,
  benefitList,
}) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.BENEFITS,
    I18nNamespaces.MESSAGES
  )
  const ARCHIVED_BENEFIT_TEXT = t('benefit_archived')

  const author = benefitApplicationInfo.createdUser
  const authorName = `${author.surname} ${author.name}`

  const [dateValue, setDateValue] = useState<Date | null>(
    benefitApplicationInfo.cancellationDate
      ? new Date(benefitApplicationInfo.cancellationDate)
      : null
  )

  const { modalOpen: showModalApprove, toggleModal: toggleModalApprove } =
    useModalWindowState()
  const { modalOpen: showModalDeny, toggleModal: toggleModalDeny } =
    useModalWindowState()
  const { modalOpen: showModalEdit, toggleModal: toggleModalEdit } =
    useModalWindowState()
  const { modalOpen: showModalCancel, toggleModal: toggleModalCancel } =
    useModalWindowState()

  const { userId, roles } = useAuth()

  const handleShowModalApprove = () => {
    toggleModalApprove()
    onClose()
  }

  const handleShowModalDeny = () => {
    toggleModalDeny()
    onClose()
  }

  const handleShowModalEdit = () => {
    toggleModalEdit()
    onClose()
  }

  const handleShowModalCancel = () => {
    toggleModalCancel()
    onClose()
  }

  const disabledButtonsProps = useMemo<DisabledButtonsProps>(() => {
    const isBenefitArchived =
      benefitApplicationInfo.benefit.status === BenefitStatuses.archived

    return isBenefitArchived
      ? {
          popupText: ARCHIVED_BENEFIT_TEXT,
          disabled: true,
          popupProps: { centered: true },
        }
      : {}
  }, [benefitApplicationInfo.benefit.status])

  return (
    <ST.ApplicationCard>
      <ST.HeaderCard>
        <AuthorLine
          name={authorName}
          id={author.id}
          photo={getFileData(author.userImg)}
          t={t}
        />
        <TitleLine
          date={benefitApplicationInfo.createdAt}
          status={benefitApplicationInfo.status}
        />
      </ST.HeaderCard>
      <ST.TargetBlock>
        {benefitApplicationInfo && (
          <TargetLine benefitApplication={benefitApplicationInfo} t={t} />
        )}
        {benefitApplicationInfo.status ===
          BenefitApplicationStatuses.underConsideration && (
          <ST.Controls>
            {checkRole(roles, [ROLES.admin]) && (
              <BaseButton
                {...baseButtonProps}
                {...disabledButtonsProps}
                text={t('approve')}
                typeButton={ButtonTypes.primary}
                onClick={handleShowModalApprove}
              />
            )}
            {checkRole(roles, [ROLES.admin]) && (
              <BaseButton
                {...baseButtonProps}
                {...disabledButtonsProps}
                type={'submit'}
                text={t('reject')}
                typeButton={ButtonTypes.secondary}
                onClick={handleShowModalDeny}
              />
            )}

            {benefitApplicationInfo?.createdUser?.id === userId && (
              <BaseButton
                {...baseButtonProps}
                type={'submit'}
                text={t('edit')}
                typeButton={ButtonTypes.secondary}
                onClick={handleShowModalEdit}
              />
            )}
            {benefitApplicationInfo.createdUser.id === userId && (
              <BaseButton
                {...baseButtonProps}
                type={'submit'}
                text={t('cancel')}
                typeButton={ButtonTypes.secondary}
                onClick={handleShowModalCancel}
              />
            )}
          </ST.Controls>
        )}
      </ST.TargetBlock>
      {benefitApplicationInfo && (
        <ApproveBenefitApplication
          show={showModalApprove}
          onClose={handleShowModalApprove}
          benefitApplication={benefitApplicationInfo}
          userData={userList}
          benefitData={benefitList}
          dateValue={dateValue}
        />
      )}
      {benefitApplicationInfo && (
        <EditBenefitApplication
          show={showModalEdit}
          onClose={handleShowModalEdit}
          benefitApplication={benefitApplicationInfo}
          userData={userList}
          benefitData={benefitList}
          setDateValue={setDateValue}
        />
      )}
      {benefitApplicationInfo && (
        <DeniedBenefitApplication
          show={showModalDeny}
          onClose={handleShowModalDeny}
          benefitApplication={benefitApplicationInfo}
        />
      )}
      {benefitApplicationInfo && (
        <CancelBenefitApplication
          title={t('cancel_modal_title')}
          show={showModalCancel}
          onClose={handleShowModalCancel}
          benefitApplicationId={benefitApplicationInfo.id}
        />
      )}
    </ST.ApplicationCard>
  )
}

export default BenefitApplicationCard
