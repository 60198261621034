import React, { useContext } from 'react'
import * as ST from './styled'
import { ReactComponent as RejectedIcon } from 'assets/icons/Applications/statuses/rejected.svg'
import { ReactComponent as WaitIcon } from 'assets/icons/Applications/statuses/under-consideration.svg'
import { ReactComponent as ApprovedIcon } from 'assets/icons/Applications/statuses/approved.svg'
import { ReactComponent as ClosedIcon } from 'assets/icons/Applications/statuses/closed.svg'
import { ReviewStatuses } from 'constants/reviewStatuses'
import { ProfileNS } from 'i18n/types'
import { ReviewCardTranslateContext } from 'components/reviews/ReviewCard/index'

interface Props {
  status: ReviewStatuses
}

const StatusBlock: FC<Props> = ({ status }) => {
  const { statuses } = useContext<ProfileNS['reviews'] | null>(
    ReviewCardTranslateContext
  )!

  const icons = {
    [ReviewStatuses.not_complete]: () => <RejectedIcon />,
    [ReviewStatuses.wait]: () => <WaitIcon />,
    [ReviewStatuses.close]: () => <ApprovedIcon />,
    [ReviewStatuses.cancel]: () => <ClosedIcon />,
  }

  return (
    <ST.StatusBlock>
      {icons[status]()}
      <ST.Label>{statuses[status]}</ST.Label>
    </ST.StatusBlock>
  )
}

export default StatusBlock
