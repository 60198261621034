import React, { ReactNode } from 'react'
import * as ST from './styled'
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { IntegrationSteps } from 'constants/settingsSteps'
import { getSettingByKey } from 'utils/parseData'
import { ISetting, SettingsKeys } from 'types/model/settings'
import PasswordViewer from 'components/ui/labels/PasswordViewer'
import IconButton from 'components/ui/buttons/IconButton'
import { ReactComponent as ApprovedIcon } from 'assets/icons/Applications/statuses/approved.svg'
import { IItem } from 'components/ui/BaseSelect'
import getFileData from 'utils/file/getFileData'
import useIntegrationsRedirect from 'hooks/useIntegrationsRedirect'
import { PATHS } from 'constants/paths'
import BaseButton from 'components/ui/buttons/BaseButton'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface IUserProps {
  handleSelectEdit: (step: IntegrationSteps) => void
  mailData: ISetting[]
  calendarData: ISetting[]
  reviewData: ISetting[]
  vkData: ISetting[]
  hhData: ISetting[]
  habrData: { id: string }
  avitoData: ISetting[]
  telegramData: ISetting[]
  posToSheet: Array<IItem[]>
}

type EditButtonProps = {
  step: IntegrationSteps
}

type GridBlockItem =
  | IItem<SettingsKeys | ReactNode, string | SettingsKeys>
  | {
      subheader: string
      title?: string
    }

type GridBlockProps =
  | {
      data: ISetting[]
      items: GridBlockItem[]
      titleSettingsKey: SettingsKeys
    }
  | {
      data: ISetting[]
      items: GridBlockItem[]
      title: string
    }

const DeleteButton = () => <IconButton icon={DeleteIcon} disabled />

const isSettingsKey = (key: string): key is SettingsKeys =>
  Object.values(SettingsKeys).includes(key as SettingsKeys)

const GridBlock: FC<GridBlockProps> = ({ data, items, children, ...props }) => (
  <ST.GridBlock>
    <ST.InfoBlock>
      <ST.TopBlock>
        <ST.HeaderBlock>
          {'title' in props
            ? props.title
            : getSettingByKey(data, props.titleSettingsKey)?.value}
        </ST.HeaderBlock>
        {children && <ST.TopBlockIcons>{children}</ST.TopBlockIcons>}
      </ST.TopBlock>

      {items.map((item) => {
        const isSubheader = 'subheader' in item
        const key = isSubheader
          ? item.subheader
          : typeof item.value === 'string'
          ? item.value
          : item.item
        const value = isSubheader
          ? item.subheader
          : typeof item.value === 'string' && isSettingsKey(item.value)
          ? getSettingByKey(data, item.value)?.value
          : item.value

        const label = !isSubheader
          ? isSettingsKey(item.item)
            ? getSettingByKey(data, item.item)?.title
            : item.item
          : null

        return isSubheader ? (
          <ST.SubHeaderBlock key={key} title={item.title}>
            {value}
          </ST.SubHeaderBlock>
        ) : (
          <ST.GridInfoBlock key={key}>
            <ST.TitleAttribute>{label}</ST.TitleAttribute>
            <ST.ValueAttribute>{value}</ST.ValueAttribute>
          </ST.GridInfoBlock>
        )
      })}
    </ST.InfoBlock>
  </ST.GridBlock>
)

const StaticIntegrationsTabContent: FC<IUserProps> = ({
  handleSelectEdit,
  mailData,
  calendarData,
  reviewData,
  vkData,
  hhData,
  avitoData,
  posToSheet,
  telegramData,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_INTEGRATIONS)
  const { handleClick } = useIntegrationsRedirect(PATHS.settingsIntegrations)
  const EditButton: FC<EditButtonProps> = ({ step }) => (
    <IconButton icon={EditIcon} onClick={() => handleSelectEdit(step)} />
  )

  const surveyPatternExcel = getSettingByKey(
    reviewData,
    SettingsKeys.SURVEY_PATTERN_EXCEL_FILE
  )

  return (
    <>
      <GridBlock
        data={mailData}
        items={[
          { item: t('email'), value: SettingsKeys.MAIL_SENDER },
          {
            item: t('port'),
            value: SettingsKeys.PORT,
          },
          {
            item: t('host'),
            value: SettingsKeys.HOST,
          },
          {
            item: t('login'),
            value: SettingsKeys.LOGIN,
          },
          {
            item: t('password'),
            value: getSettingByKey(mailData, SettingsKeys.PASSWORD)?.value && (
              <PasswordViewer
                password={
                  getSettingByKey(mailData, SettingsKeys.PASSWORD)?.value
                }
              />
            ),
          },
        ]}
        titleSettingsKey={SettingsKeys.MAIL_NAME}
      >
        <EditButton step={IntegrationSteps.MAIL} />
        <DeleteButton />
      </GridBlock>

      <GridBlock
        data={calendarData}
        titleSettingsKey={SettingsKeys.CALENDAR_NAME}
        items={[
          { item: t('email'), value: SettingsKeys.CALENDAR_MAIL_SENDER },
          {
            item: t('port'),
            value: SettingsKeys.CALENDAR_PORT,
          },
          {
            item: t('host'),
            value: SettingsKeys.CALENDAR_HOST,
          },
          {
            item: t('login'),
            value: SettingsKeys.CALENDAR_LOGIN,
          },
          {
            item: t('password'),
            value: getSettingByKey(calendarData, SettingsKeys.CALENDAR_PASSWORD)
              ?.value && (
              <PasswordViewer
                password={
                  getSettingByKey(calendarData, SettingsKeys.CALENDAR_PASSWORD)
                    ?.value
                }
              />
            ),
          },
        ]}
      >
        <EditButton step={IntegrationSteps.CALENDAR} />
        <DeleteButton />
      </GridBlock>

      <GridBlock
        data={reviewData}
        titleSettingsKey={SettingsKeys.REVIEW_NAME}
        items={[
          { item: t('key'), value: SettingsKeys.GOOGLE_CLIENT_ID },
          {
            item: t('private_key'),
            value: SettingsKeys.GOOGLE_REDIRECT_URI,
          },
          { subheader: t('documents') },
          {
            item: t('survey360'),
            value: SettingsKeys.PATTERN_SURVEY_360,
          },
          {
            item: t('user_survey'),
            value: SettingsKeys.PATTERN_COMMON_SURVEY,
          },
          {
            item: t('tech_questionnaire'),
            value: SettingsKeys.PATTERN_TECH_SURVEY,
          },
          {
            subheader: t('positions_sheets'),
          },
          ...posToSheet.map((position) => ({
            item: position[0].item,
            value: position[1].item,
          })),
          {
            subheader: t('surveys_template'),
            title: surveyPatternExcel?.description,
          },
          {
            item: t('review_surveys'),
            value: getFileData(surveyPatternExcel?.img) ? (
              <ST.LinkAttribute
                href={getFileData(surveyPatternExcel?.img)}
                target="_blank"
              >
                {t('employees_survey')}
              </ST.LinkAttribute>
            ) : (
              <ST.ValueAttribute>{t('no_data')}</ST.ValueAttribute>
            ),
          },
        ]}
      >
        {getSettingByKey(reviewData, SettingsKeys.GOOGLE_STATUS)?.value ===
        'true' ? (
          <ST.SecondaryButton>
            <a
              href={
                getSettingByKey(reviewData, SettingsKeys.GOOGLE_AUTH_LINK)
                  ?.value ?? ''
              }
              target={'_blank'}
              rel="noreferrer"
            >
              {t('change_account')}
              <ApprovedIcon style={{ marginLeft: '10px' }} />
            </a>
          </ST.SecondaryButton>
        ) : (
          <BaseButton
            href={
              getSettingByKey(reviewData, SettingsKeys.GOOGLE_AUTH_LINK)
                ?.value ?? ''
            }
            width={220}
            height={40}
            target={'_blank'}
            text={t('auth')}
          />
        )}
        <EditButton step={IntegrationSteps.REVIEWS} />
        <DeleteButton />
      </GridBlock>

      <GridBlock
        data={vkData}
        titleSettingsKey={SettingsKeys.BOT_VK_NAME}
        items={[
          {
            item: t('admin_chat'),
            value: SettingsKeys.ADMIN_CHAT_ID,
          },
          {
            item: t('access_chat'),
            value: SettingsKeys.ACCESS_CHAT_ID,
          },
          {
            item: t('review_chat'),
            value: SettingsKeys.REVIEW_CHAT_ID,
          },
        ]}
      >
        <EditButton step={IntegrationSteps.VK_BOT} />
        <DeleteButton />
      </GridBlock>

      <GridBlock
        data={telegramData}
        titleSettingsKey={SettingsKeys.TELEGRAM_NAME}
        items={[
          {
            item: t('admin_chat'),
            value: SettingsKeys.TELEGRAM_ADMIN_CHAT_ID,
          },
          {
            item: t('access_chat'),
            value: SettingsKeys.TELEGRAM_ACCESS_CHAT_ID,
          },
          {
            item: t('review_chat'),
            value: SettingsKeys.TELEGRAM_REVIEW_CHAT_ID,
          },
        ]}
      >
        <EditButton step={IntegrationSteps.TELEGRAM} />
        <DeleteButton />
      </GridBlock>

      <GridBlock
        data={avitoData}
        titleSettingsKey={SettingsKeys.AVITO_NAME}
        items={[
          {
            item: SettingsKeys.AVITO_CLIENT_ID,
            value: SettingsKeys.AVITO_CLIENT_ID,
          },
          {
            item: SettingsKeys.AVITO_SECRET,
            value: SettingsKeys.AVITO_SECRET,
          },
          {
            item: SettingsKeys.AVITO_REDIRECT_URI,
            value: SettingsKeys.AVITO_REDIRECT_URI,
          },
        ]}
      >
        {getSettingByKey(avitoData, SettingsKeys.AVITO_STATUS)?.value ===
        'true' ? (
          <ST.SecondaryButton>
            <a
              href={
                getSettingByKey(avitoData, SettingsKeys.AVITO_AUTH_LINK)
                  ?.value ?? ''
              }
              target={'_blank'}
              onClick={handleClick}
              rel="noreferrer"
            >
              {t('change_account')}
              <ApprovedIcon style={{ marginLeft: '10px' }} />
            </a>
          </ST.SecondaryButton>
        ) : (
          <BaseButton
            href={
              getSettingByKey(avitoData, SettingsKeys.AVITO_AUTH_LINK)?.value ??
              ''
            }
            onClick={handleClick}
            width={220}
            height={40}
            target={'_blank'}
            text={t('auth')}
          />
        )}

        <EditButton step={IntegrationSteps.AVITO} />
        <DeleteButton />
      </GridBlock>

      <GridBlock
        data={hhData}
        titleSettingsKey={SettingsKeys.API_HH_NAME}
        items={[
          {
            item: 'Ключ:',
            value: SettingsKeys.HH_APIKEY,
          },
          {
            item: 'Client Secret:',
            value: SettingsKeys.HH_SECRET,
          },
          {
            item: 'Client ID:',
            value: SettingsKeys.HH_CLIENT_ID,
          },
        ]}
      >
        {getSettingByKey(hhData, SettingsKeys.HH_STATUS)?.value === 'true' ? (
          <ST.SecondaryButton>
            <a
              href={
                getSettingByKey(hhData, SettingsKeys.HH_AUTH_LINK)?.value ?? ''
              }
              onClick={handleClick}
              target={'_blank'}
              rel="noreferrer"
            >
              {t('change_account')}
              <ApprovedIcon style={{ marginLeft: '10px' }} />
            </a>
          </ST.SecondaryButton>
        ) : (
          <BaseButton
            href={
              getSettingByKey(hhData, SettingsKeys.HH_AUTH_LINK)?.value ?? ''
            }
            onClick={handleClick}
            width={220}
            height={40}
            target={'_blank'}
            text={t('auth')}
          />
        )}
        <EditButton step={IntegrationSteps.API_HH} />
        <DeleteButton />
      </GridBlock>

      <GridBlock
        data={[]}
        title="API career.habr.com"
        items={[
          {
            item: t('key'),
            value: null,
          },
        ]}
      >
        <ST.Button disabled>{t('auth')}</ST.Button>
        <EditButton step={IntegrationSteps.API_HABR} />
        <DeleteButton />
      </GridBlock>
    </>
  )
}

export default StaticIntegrationsTabContent
