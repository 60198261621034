import React, { useLayoutEffect } from 'react'
import 'App.css'
import { Routing } from 'routing'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components'
import { ErrorBoundary } from '@sentry/react'
import ErrorBoundaryFallback from 'components/error/ErrorBoundaryFallback'
import { saveHost } from 'utils/saveHost'
import 'i18n/config'
import i18n from 'i18next'
import {
  I18N_LOCALSTORAGE_KEY,
  I18nNamespaces,
  SupportedLocales,
} from 'i18n/config'
import updateAxiosBaseUrl from 'utils/url/updateAxiosBaseUrl'
import moment from 'moment'
import { useGetResource } from 'i18n/hooks/useGetResource'

const theme = {
  supports: {
    mozAppearance:
      typeof CSS !== 'undefined' && CSS.supports('-moz-appearance: none'),
  },
}

const App: FC = () => {
  const months = useGetResource(I18nNamespaces.TRANSLATION, 'months')

  useLayoutEffect(() => {
    const lang = (i18n.resolvedLanguage ??
      localStorage.getItem(I18N_LOCALSTORAGE_KEY)) as SupportedLocales

    moment.updateLocale(lang, { months: Object.values(months) })

    if (lang) updateAxiosBaseUrl(lang)
    saveHost()
  }, [months, i18n.resolvedLanguage])

  return (
    <ErrorBoundary fallback={(props) => <ErrorBoundaryFallback {...props} />}>
      <ThemeProvider theme={theme}>
        <Routing />
        <ToastContainer />
      </ThemeProvider>
    </ErrorBoundary>
  )
}
export default App
