import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { cancelBenefitApplication } from 'api/benefitsApplications'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  show: boolean
  title: string
  submitText?: string
  onClose: (isShow: boolean) => void
  onSuccess?: () => void
  benefitApplicationId: number
}

const CancelBenefitApplication: FC<Props> = ({
  show,
  title,
  onClose,
  onSuccess,
  submitText,
  benefitApplicationId,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.BENEFITS)
  const handleClose = (): void => {
    onClose(!show)
  }

  const cancelBenefitApplicationHandler = () => {
    cancelBenefitApplication(benefitApplicationId).then(() => {
      handleClose()
      onSuccess?.()
    })
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{title}</ST.ModalTitle>
          <ST.ButtonsBlock>
            <ST.Button onClick={cancelBenefitApplicationHandler}>
              {submitText ?? t('cancel_modal_submit')}
            </ST.Button>
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CancelBenefitApplication
