import React from 'react'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import IconButton from 'components/ui/buttons/IconButton'

interface Props {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const EditButton: FC<Props> = ({ onClick }) => (
  <IconButton icon={EditIcon} onClick={onClick} />
)

export default EditButton
