import React, { ElementRef, useRef, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { handlerError } from 'utils/handlerError'
import * as ST from './styled'
import { ModalLoader } from 'components/ui/overlay/Modal/Loader'
import { FormikFields } from 'constants/formikFields'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { LetterTemplate } from 'types/model/letterTemplate'
import TinyMce from 'components/tinyMce'
import { createLetterTemplate, editLetterTemplate } from 'api/letterTemplate'
import { stringNotEmpty } from 'utils/validation/tests'
import BaseButton from 'components/ui/buttons/BaseButton'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'
import { useRequiredFields } from 'i18n/hooks/useRequiredFields'
import BaseInput from 'components/ui/inputs/BaseInput'
import { jsonObjectToItemsArray } from 'i18n/utils'

type Props = {
  show: boolean
  onClose: SetState<boolean>
  showEndModal?: SetState<boolean>
  selectedTemplate: LetterTemplate | null
  updateTemplates: () => void
  isEdit: boolean
}

const CreateLetterTemplate = ({
  show,
  onClose,
  showEndModal,
  selectedTemplate,
  updateTemplates,
  isEdit,
}: Props) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.SETTINGS_LETTERS,
    I18nNamespaces.TRANSLATION
  )
  const { RequiredFields } = useRequiredFields()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const modalRef = useRef<ElementRef<'div'>>(null)
  const handleClose = (): void => {
    onClose(!show)
  }

  const { handleSubmit, handleChange, values, errors, isValid, setFieldValue } =
    useFormik({
      validateOnMount: true,
      initialValues: {
        name: selectedTemplate?.name ?? '',
        title: selectedTemplate?.title ?? '',
        body: selectedTemplate?.body ?? '',
      } as LetterTemplate,
      onSubmit: () => {
        setIsLoading(true)

        if (isEdit) {
          selectedTemplate?.id &&
            editLetterTemplate(selectedTemplate?.id, values)
              .then(() => {
                updateTemplates()
                showEndModal?.(true)
                handleClose()
              })
              .finally(() => {
                setIsLoading(false)
              })
        } else {
          createLetterTemplate(values)
            .then(() => {
              updateTemplates()
              showEndModal?.(true)
              handleClose()
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      },

      validationSchema: Yup.object().shape({
        name: Yup.string()
          .required(RequiredFields.base)
          .test('name-is-empty', RequiredFields.base, stringNotEmpty),
        title: Yup.string()
          .required(RequiredFields.base)
          .test('title-is-empty', RequiredFields.base, stringNotEmpty),
        body: Yup.string()
          .required(RequiredFields.base)
          .test('body-is-empty', RequiredFields.base, stringNotEmpty),
      }),
    })

  const checkForm = (): void => {
    if (isValid) {
      handleSubmit()
      return
    } else {
      handlerError(errors)
      return
    }
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal ref={modalRef} onClick={(e) => e.stopPropagation()}>
        {isLoading && <ModalLoader height={modalRef.current?.scrollHeight} />}

        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle style={{ textAlign: 'center' }}>
            {t(isEdit ? 'edit_title' : 'create_title')}
          </ST.ModalTitle>
          <ST.TopInputWrapper>
            <BaseInput
              placeholder={t('name_placeholder')}
              id={FormikFields.name}
              name={FormikFields.name}
              value={values.name}
              onChange={handleChange}
              style={{ width: '50%' }}
              label={t('name')}
              required
            />
            <BaseInput
              placeholder={t('topic_placeholder')}
              id={FormikFields.name}
              name={FormikFields.name}
              value={values.name}
              onChange={handleChange}
              style={{ width: '50%' }}
              label={t('topic')}
              required
            />
          </ST.TopInputWrapper>

          <TinyMce
            label={t('text')}
            initialValue={values.body}
            setValue={(value: string) => {
              setFieldValue('body', value)
            }}
            placeholder={t('text_placeholder')}
            variables={jsonObjectToItemsArray(t('variables_values'))}
            required
          />

          <ST.InputWrapper style={{ marginTop: '20px' }}>
            <BaseButton
              text={t(isEdit ? 'translation:save' : 'create_submit')}
              disabled={!isValid}
              onClick={checkForm}
            />
          </ST.InputWrapper>

          <ST.InputWrapper style={{ marginTop: '10px' }}>
            <BaseButton
              text={t('translation:cancel')}
              typeButton="secondary"
              onClick={handleClose}
            />
          </ST.InputWrapper>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default CreateLetterTemplate
