import React, { useCallback, useEffect, useMemo, useState } from 'react'
import BaseSelect, { IItem } from 'components/ui/BaseSelect'
import * as ST from './styled'
import debounce from 'lodash/debounce'
import { getEmployees } from 'api/employees'
import { parseDataToEmployees } from 'utils/parseData'
import { handlerError } from 'utils/handlerError'
import { ReviewApiTypes as T } from 'api/review/types'
import { UserTypes } from 'types/model/user'
import { USERS_FILTERS } from 'components/reviews/index'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import AutocompleteInput from 'components/ui/inputs/AutocompleteInput'
import { I18nNamespaces } from 'i18n/config'
import { ProfileNS } from 'i18n/types'
import { ReviewStatuses, ReviewType } from 'constants/reviewStatuses'

interface Props {
  userList: UserTypes.Model[]
  setValueFilter: (obj: T.IReviewsValuesFilter) => void
  valuesFilter: T.IReviewsValuesFilter
  userFromFilters: Nullable<IItem<number>>
  clearInput: boolean
}

const SelectsBlock: FC<Props> = ({
  userList,
  setValueFilter,
  valuesFilter,
  clearInput,
  userFromFilters,
}) => {
  const { t } = useTypedTranslation(
    I18nNamespaces.REVIEWS,
    I18nNamespaces.PROFILE
  )

  const [activeSelect, setActiveSelect] = useState<string | null>(null)
  const [selectedUser, setSelectedUser] =
    useState<Nullable<IItem<number>>>(userFromFilters)
  const [filterUserList, setFilterUserList] = useState<IItem<number>[]>([])

  const clearInputProps = clearInput ? { value: '' } : {}

  useEffect(() => {
    clearInput && setSelectedUser(null)
  }, [clearInput])

  const passValue = (type: string, currentValue: string | null): void => {
    setValueFilter({
      ...valuesFilter,
      [type as keyof T.IReviewsValuesFilter]: currentValue,
    })
  }

  // TODO RTK
  const handleInputChange = (value: string) => {
    getEmployees(1, USERS_FILTERS, value)
      .then((data) => {
        setFilterUserList(parseDataToEmployees(data.data))
      })
      .catch((e) => {
        handlerError(e)
      })
  }

  useEffect(() => {
    setFilterUserList(parseDataToEmployees(userList))
  }, [userList])

  const getItemsArray = useCallback(
    <T extends ReviewType | ReviewStatuses>(
      key: 'statuses' | 'types'
    ): IItem<T>[] => {
      const translateValue = t<ProfileNS['reviews']>('profile:reviews')?.[key]

      return translateValue
        ? Object.entries(translateValue).map(([k, value]) => ({
            value: +k as T,
            item: value,
          }))
        : []
    },
    [t]
  )

  const listTypes = useMemo<IItem<ReviewType>[]>(
    () => getItemsArray('types'),
    [getItemsArray]
  )

  const listStatuses = useMemo<IItem<ReviewStatuses>[]>(
    () => getItemsArray('statuses'),
    [getItemsArray]
  )

  return (
    <ST.SelectsBlock>
      <AutocompleteInput
        key={selectedUser?.value}
        options={filterUserList}
        value={selectedUser}
        placeholder={t('employee')}
        onInputChange={debounce(handleInputChange, 1000)}
        getOptionLabel={(option) => option?.item}
        containerStyle={{ maxWidth: 'calc(33% - 20px)', padding: 0 }}
        inputContainerStyle={{ paddingRight: 0 }}
        onChange={(value) => {
          passValue('user', value ? String(value.value) : null)
          setSelectedUser(value)
        }}
      />
      <BaseSelect
        {...clearInputProps}
        required
        value={
          listStatuses.find((s) => s.value === valuesFilter.status)?.item ?? ''
        }
        placeHolder={t('status')}
        style={{ maxWidth: 'calc(33% - 20px)', minWidth: 200 }}
        listItems={listStatuses}
        typeSelect={'status'}
        passValue={passValue}
        setActive={setActiveSelect}
        activeSelect={clearInput ? '' : activeSelect}
      />
      <BaseSelect
        {...clearInputProps}
        required
        value={
          listTypes.find((type) => type.value === valuesFilter.type)?.item ?? ''
        }
        placeHolder={t('type')}
        style={{ maxWidth: 'calc(33% - 20px)', minWidth: 200 }}
        listItems={listTypes}
        typeSelect={'type'}
        passValue={passValue}
        setActive={setActiveSelect}
        activeSelect={clearInput ? '' : activeSelect}
      />
    </ST.SelectsBlock>
  )
}

export default SelectsBlock
