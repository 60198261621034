import React from 'react'
import * as ST from './styled'
import { Link } from 'react-router-dom'
import AvatarImage from 'components/ui/image/Avatar'
import { ProfileNS } from 'i18n/types'

interface Props {
  translatedStatuses: ProfileNS['reviews']['user_statuses']
  photo?: string
  user?: {
    id: number
    email: string
    surname: string
    name: string
    patronymic: string
    statusName: string
    position: {
      name: string
    }
  }
}

const Employee: FC<Props> = ({ photo, user, translatedStatuses }) => (
  <ST.AuthorLine>
    <AvatarImage path={photo} />
    <Link to={`/user/${user?.id}`}>
      <ST.Name>
        {user?.surname} {user?.name}
      </ST.Name>
    </Link>
    <ST.Department>{user?.position?.name ?? ''}</ST.Department>
    <ST.Status>
      <ST.StatusCircle
        color={
          user?.statusName === translatedStatuses.dismissed ? 'red' : 'green'
        }
      ></ST.StatusCircle>
      <ST.StatusName>{user?.statusName ?? ''}</ST.StatusName>
    </ST.Status>
  </ST.AuthorLine>
)

export default Employee
