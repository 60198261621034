import { BenefitStatuses } from 'constants/benefitStatuses'

export enum BenefitOperationTypes {
  refill = 1,
  withdrawal = 2,
}

export namespace BenefitTypes {
  export interface Model {
    id: number
    type: string
    operationType: BenefitOperationTypes
    status: BenefitStatuses
  }
}
