import React from 'react'
import * as ST from './styled'
import { ReactComponent as Close } from 'assets/icons/cancel.svg'
import { HardwareTypes } from 'types/model/hardware'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { I18nNamespaces } from 'i18n/config'

interface Props {
  show: boolean
  title?: string
  onClose: (isShow: boolean) => void
  handleConfirm?: (id: number) => void
  hardwareType: HardwareTypes.HardwareType
}

const DeleteSection: FC<Props> = ({
  show,
  title,
  onClose,
  handleConfirm,
  hardwareType,
}) => {
  const { t } = useTypedTranslation(I18nNamespaces.SETTINGS_HARDWARE)

  const handleClose = (): void => {
    onClose(!show)
  }

  return show ? (
    <ST.ModalOverlay>
      <ST.Modal onClick={(e) => e.stopPropagation()}>
        <ST.ModalContent>
          <ST.Close onClick={handleClose}>
            <Close />
          </ST.Close>
          <ST.ModalTitle>{title ?? t('delete_title')}</ST.ModalTitle>

          <ST.Description>
            {t('delete_description', { name: hardwareType.name })}
          </ST.Description>

          <ST.ButtonsBlock>
            <ST.Button
              onClick={() => {
                handleConfirm?.(hardwareType.id)
                handleClose()
              }}
            >
              {t('delete_submit')}
            </ST.Button>
            <ST.Button onClick={handleClose} typeButton={'secondary'}>
              {t('cancel')}
            </ST.Button>
          </ST.ButtonsBlock>
        </ST.ModalContent>
      </ST.Modal>
    </ST.ModalOverlay>
  ) : null
}

export default DeleteSection
