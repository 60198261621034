import React, { useEffect, useState } from 'react'
import * as ST from './styled'
import { CSSProperties } from 'styled-components'

export interface Props
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  value?: string
  label?: string
  required?: boolean
  error?: string
  isValid?: boolean
  style?: CSSProperties
  textAreaContainerStyle?: CSSProperties
  maxCharacters?: number
}

const BaseTextarea: FC<Props> = ({
  value,
  id,
  name,
  placeholder,
  label,
  required,
  error,
  isValid,
  style,
  onChange,
  onBlur,
  maxCharacters,
  textAreaContainerStyle,
  ...others
}) => {
  const [currentValue, setCurrentValue] = useState<string>(value ?? '')
  const [charactersCount, setCharactersCount] = useState<number>(0)

  useEffect(() => {
    setCurrentValue(value ?? '')
  }, [value])

  useEffect(() => {
    if (maxCharacters) {
      setCurrentValue((prevState) => {
        const newValue = prevState?.substring(0, maxCharacters)
        setCharactersCount(newValue?.length)
        return newValue ?? ''
      })
    }
  }, [maxCharacters])

  return (
    <ST.Container style={style}>
      {label && (
        <ST.LabelContainer>
          <ST.Label htmlFor={id}>
            {label}
            {required && <ST.RequiredIndicator>*</ST.RequiredIndicator>}
          </ST.Label>
        </ST.LabelContainer>
      )}
      <ST.TextareaContainer
        hasCounter={!!maxCharacters}
        style={textAreaContainerStyle}
      >
        <ST.Textarea
          id={id}
          name={name}
          value={currentValue}
          isValid={isValid}
          placeholder={placeholder}
          maxLength={maxCharacters}
          onChange={(e) => {
            onChange?.(e)
            setCurrentValue(e.target.value)
          }}
          onInput={(event) => {
            const len = (event.target as HTMLTextAreaElement).value.length
            setCharactersCount(len)
          }}
          onBlur={onBlur}
          {...others}
        />
        {error && <ST.Error>{error}</ST.Error>}

        {!!maxCharacters && (
          <ST.Counter error={charactersCount === maxCharacters}>
            {charactersCount}/{maxCharacters}
          </ST.Counter>
        )}
      </ST.TextareaContainer>
    </ST.Container>
  )
}

export default BaseTextarea
