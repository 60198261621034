import React from 'react'
import * as ST from './styled'
import { CSSProperties } from 'styled-components'

export interface PropsLoader {
  width?: number
  height?: number
  border?: number
  marginTop?: CSSProperties['marginTop']
}

const Loader: FC<PropsLoader> = ({ border, ...props }) => (
  <ST.LoaderWrapper {...props}>
    <ST.Loader border={border} />
  </ST.LoaderWrapper>
)

export default Loader
