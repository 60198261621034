import { I18N_LOCALSTORAGE_KEY } from 'i18n/config'
import { I18N_LOCALSTORAGE_URL_KEY } from 'utils/url/updateAxiosBaseUrl'

export namespace LocalStorageUtils {
  const DEFAULT_EXCLUDED_KEYS = [
    I18N_LOCALSTORAGE_KEY,
    I18N_LOCALSTORAGE_URL_KEY,
  ]

  export const clear = (excludedKeys: string[] = []) => {
    const excludedKeysValue = [...DEFAULT_EXCLUDED_KEYS, ...excludedKeys]

    console.log('LocalStorage was cleared')

    Object.keys(localStorage).forEach((key) => {
      if (!excludedKeysValue.includes(key)) {
        localStorage.removeItem(key)
      }
    })
  }
}
