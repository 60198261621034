import React from 'react'
import * as ST from './styled'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { COLORS } from 'constants/colors'
import './styled.css'
import NumberFormat from 'react-number-format'
import { CSSProperties } from 'styled-components'
import { Moment } from 'moment'
import i18n from 'i18next'
import { I18nNamespaces } from 'i18n/config'
import { useTypedTranslation } from 'i18n/hooks/useTypedTranslation'
import { ComponentsNS } from 'i18n/types'
import enGB from 'date-fns/locale/en-GB'
import ruRU from 'date-fns/locale/ru'

type Value = string | Moment | Date | null

export type Props =
  | {
      setStartDate: SetState<Date>
      startDate: Date

      includeDates?: [Date, Date]
      excludeDates?: [Date, Date]
      minDate?: Date
      maxDate?: Date
      small?: boolean
      disabled?: boolean
      style?: CSSProperties
    }
  | {
      value: Value
      onChange: (value: Value) => void

      includeDates?: [Date, Date]
      excludeDates?: [Date, Date]
      minDate?: Date
      maxDate?: Date
      small?: boolean
      disabled?: boolean
      style?: CSSProperties
    }

registerLocale('en-GB', enGB)
registerLocale('ru-RU', ruRU)

// TODO moment.js
const CalendarInput = ({
  includeDates,
  excludeDates,
  minDate,
  maxDate,
  small,
  disabled,
  style,
  ...props
}: Props) => {
  const { t } = useTypedTranslation(I18nNamespaces.UI_COMPONENTS)
  const translate = t<ComponentsNS['CalendarInput']>('CalendarInput')
  const months = translate.months

  const handleChange = (date: Date): void => {
    const newDate = date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    const parts: string[] = newDate.split('/')
    'setStartDate' in props &&
      props.setStartDate?.(new Date(+parts[2], +parts[1] - 1, +parts[0]))
  }

  const locale = i18n.resolvedLanguage === 'ru' ? 'ru-RU' : 'en-GB'

  return (
    <ST.Wrapper style={style} disabled={!!disabled}>
      <DatePicker
        className={'header'}
        dateFormat="dd.MM.yyyy"
        placeholderText={translate.placeholder}
        disabled={disabled}
        customInput={
          <NumberFormat
            customInput={!small ? ST.WideInput : ST.Input}
            format="##.##.####"
          />
        }
        includeDates={includeDates}
        excludeDates={excludeDates}
        minDate={minDate}
        maxDate={maxDate}
        id={'dateBorn'}
        locale={locale}
        name={'dateBorn'}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          increaseYear,
          decreaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 5,
              marginBottom: 10,
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: `${COLORS.white}`,
            }}
          >
            <ST.ButtonContainer>
              <ST.ButtonBack
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              />
              <ST.HeaderText>{months[date.getMonth()]}</ST.HeaderText>
              <ST.ButtonForward
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
              />
            </ST.ButtonContainer>
            <ST.ButtonContainer>
              <ST.ButtonBack
                onClick={decreaseYear}
                disabled={prevMonthButtonDisabled}
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
              />
              <ST.HeaderText>{date.getFullYear()}</ST.HeaderText>
              <ST.ButtonForward
                onClick={increaseYear}
                disabled={nextMonthButtonDisabled}
                style={{
                  backgroundColor: `${COLORS.white}`,
                }}
              />
            </ST.ButtonContainer>
          </div>
        )}
        selected={
          'startDate' in props
            ? props.startDate
            : typeof props.value === 'string'
            ? new Date(props.value)
            : props.value instanceof Date
            ? props.value
            : props.value?.toDate()
        }
        onChange={(e: Date | null) => {
          if (e) {
            'onChange' in props && props.onChange(e)
            handleChange(e)
          }
        }}
      />
    </ST.Wrapper>
  )
}
export default CalendarInput
